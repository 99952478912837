import { React, useState, useEffect, useRef } from 'react';
import { Button, InputGroup, Checkbox, Tag, Label, Toaster, Dialog } from '@blueprintjs/core';
import { diff } from 'deep-object-diff';

const rest = require('../../components/Workspace/rest');

const items = [
    {
        path: 'ui',
        label: 'Интерфейс матча',
        weight: 10
    },
    {
        path: 'timing',
        label: 'Запуск и остановка',
        weight: 15
    },
    {
        path: 'possession',
        label: 'Владение мячом',
        weight: 15
    },
    {
        path: 'accurate',
        label: 'Точные передачи',
        weight: 20
    },
    {
        path: 'events',
        label: 'Фиксация событий',
        weight: 15
    },
    {
        path: 'edit',
        label: 'Правка событий',
        weight: 10
    },
    {
        path: 'exam',
        label: 'Тестовый матч',
        weight: 15
    }
]
const Agents = () => {

    const [sources, setSources] = useState([]);
    const [term, updateTerm] = useState('');
    const [agents, setAgents] = useState(sources);
    const [currentAgent, setCurrentAgent] = useState(null);
    const [cloneAgent, setCloneAgent] = useState(null);
    const [dif, updateDif] = useState(null);
    const toaster = useRef(null);
    const [visible, updateVisible] = useState(false);
    const [loading, updateLoading] = useState(false);

    useEffect(() => {
        rest('get', 'agents')
            .then(agents => setSources(agents));
    }, [])

    useEffect(() => {
        setAgents(sources);
    }, [sources])

    useEffect(() => {
        if (term.length > 0) {
            setAgents(sources.filter(item => item.name.toLowerCase().includes(term.toLowerCase())));
        } else {setAgents(sources)}
    }, [term, sources]);

    useEffect(() => {
        updateDif(diff(cloneAgent, currentAgent));
    }, [cloneAgent, currentAgent])

    const isEmpty = x => (x !== null && !Object.keys(x).length);

    const mapSkillsInDiff = (dif) => {
        let mapdif = {};
        mapdif.skills = [];
        for (let key in dif) {
            if (key === 'skills') {
                mapdif.skills = Object.values(dif.skills).filter(item => item);
            } else {
                mapdif[key] = dif[key];
            }
        }
        return mapdif;
    }

    const addAgent = () => {
        setCurrentAgent({skills:[]});
        setCloneAgent({skills:[]});
    }

    const deleteAgent = (id) => {
        updateLoading(true);
        rest('delete', 'agents', id, null)
            .then(del => {
                setCurrentAgent(null);
                setCloneAgent(null);
                updateTerm('');
                setSources(sources.filter(item => item._id !== id));
                toaster.current.show({intent: 'success', message: `${id} Агент удален! спасибо`});
            })
            .catch(err => {
                console.err(err);
                toaster.current.show({intent: 'danger', message: `при удалении Агент ${id} возникла ошибка!`});
            })
            .finally(() => {updateLoading(false); updateVisible(false)});
    }

    const onSaveAgent = (id, body) => {
        updateLoading(true);
        if (body.name) {
            if (id) {
                rest('put', 'agents', id, mapSkillsInDiff(dif))
                    .then(upd => {
                        if (upd.ok) {
                            console.log('body(dif)',body);
                            toaster.current.show({intent: 'success', message: `${id} агент обновлен! спасибо`});
                            setCurrentAgent(body);
                            setCloneAgent(body);
                            setSources([...sources.filter(item => item._id !== id), body]);
                        } else {toaster.current.show({intent: 'danger', message: `${id} агент  не обновлен!`})}
                    })
                    .catch(err => console.error(err))
                    .finally(updateLoading(false));
            }
            else {
                rest('post', 'agents', null, body)
                    .then(created => {
                        setCurrentAgent(created);
                        setCloneAgent(created);
                        setSources([...sources, created]);
                        toaster.current.show({intent: 'success', message: `${created._id} агент создан! спасибо`});
                    })
                    .catch(err => console.error(err))
                    .finally(updateLoading(false));
            }
        } else {
            toaster.current.show({intent: 'danger', message: `Проверьте ФИО агента и баланс`});
            updateLoading(false);
        }
    }

    const takeAgentFromBase = (agent) => {
        setCurrentAgent(agent);
        setCloneAgent(agent);
    }

    const backToForm = () => {
        setCurrentAgent(null);
        setAgents(sources);
        setCloneAgent(null);
        updateTerm('');
    }

    const handleField = (evt) => {
        let key = evt.target.name;
        if (evt.target.name.includes('skills.')) {
            let skill = (key.slice(7));
            if (currentAgent.skills.includes(skill)) {
                setCurrentAgent({
                    ...currentAgent,
                    skills: [
                        ...currentAgent.skills.filter(item => item !== skill)
                    ]
                });
            } else {
                setCurrentAgent({
                    ...currentAgent,
                    skills: [
                        ...currentAgent.skills,
                        skill
                    ]
                });
            }
        } else {
            setCurrentAgent({
                ...currentAgent,
                [key]: evt.target.value
            });
        }
    }

    const handleFilterChange = (evt) => {
        updateTerm(evt.target.value);
    }

    const showDialog = () => {
        updateVisible(true);
    }

    return (
        <div  style={{width: 340, padding: 0, margin:0, boxSizing:'border-box'}}>
            <div style={{width: "100%", padding: 0, margin:0, display: currentAgent ? 'none' : 'block'}}>
                <div style={{marginBottom: 5, alignItems: 'top', justifyContent:'space-between', display: 'flex'}}>
                    <Label style={{width:'60%'}}>
                    <InputGroup
                        leftIcon='search'
                        onChange={handleFilterChange}
                        placeholder="Введите имя"
                        value={term}
                        name='search'
                        intent='primary'
                        small={false}
                        fill={true}
                    /></Label>
                    <Button style={{width:'35%'}} text='Добавить агента' icon='add' disabled={false} onClick={() => addAgent()} intent='success' />
                </div>
                <div style={{maxHeight:150, overflowY:'scroll'}}>
                    {agents.map(agent =>
                            <Tag
                                style={{display:'block', width: '100%', marginBottom:10, fontSize: 14}}
                                key={agent._id}
                                icon={(<img alt='' src={agent.avatar || require('../../assets/color/badge.svg').default} style={{width: 22, height: 22}} />)}
                                interactive={true}
                                onClick={() => takeAgentFromBase(agent)}
                            >{agent.name}</Tag>
                    )}
                </div>
            </div>
            {currentAgent === null ? null :
                <div style={{display: currentAgent ? 'block' : 'none'}}>
                    <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between'}}>
                        <Label style={{width:'48%'}}>ФИО
                        <InputGroup
                            style={{width:'100%'}}
                            onChange={handleField}
                            placeholder="Введите ФИО"
                            value={currentAgent.name ?? ''}
                            name='name'
                            intent='primary'
                            small={false}
                            fill={true}
                            autoComplete='off'
                        /></Label>
                        <Label style={{width:'48%'}}>Логин
                        <InputGroup
                            onChange={handleField}
                            placeholder="Введите логин"
                            value={currentAgent.login ?? ''}
                            name='login'
                            intent='primary'
                            small={false}
                            fill={true}
                            autoComplete='off'
                        /></Label>
                        <Label style={{width:'48%'}}>Номер телефона
                        <InputGroup
                            onChange={handleField}
                            placeholder="введите номер"
                            value={currentAgent.contact ?? ''}
                            name='contact'
                            intent='primary'
                            small={false}
                            fill={true}
                            autoComplete='off'
                        /></Label>
                        <Label style={{width:'48%'}}>Баланс
                        <InputGroup
                            onChange={handleField}
                            placeholder="баланс"
                            value={currentAgent.balance ?? ''}
                            name='balance'
                            intent='primary'
                            small={false}
                            fill={true}
                            autoComplete='off'
                        /></Label>
                        <Label style={{width:'48%', display: currentAgent._id ? 'none' : 'block'}}>Пароль
                        <InputGroup
                            onChange={handleField}
                            placeholder="Пароль"
                            value={currentAgent.password ?? ''}
                            name='password'
                            intent='primary'
                            small={false}
                            fill={true}
                            autoComplete='off'
                        /></Label>
                        <div style={{width: '100%', display: 'flex', flexWrap:'wrap', marginBottom: 5, marginTop: 5, justifyContent:'space-between'}}>
                            {items.map(item => {
                                return (
                                    <Checkbox
                                        labelElement={item.label}
                                        checked={currentAgent.skills ? currentAgent.skills.includes(item.path) : false}
                                        onChange={handleField}
                                        name={`skills.${item.path}`}
                                        key={item.path}
                                        style={{width: '33%'}}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
                        <Button text={currentAgent._id ? 'Сохранить изменения' : 'Добавить агента'} icon='send-to' disabled={isEmpty(dif)}
                        onClick={() => onSaveAgent(currentAgent._id, currentAgent)} intent='success' style={{width:'33%', marginBottom: 10}} loading={loading}/>
                        <Button text='Назад к списку' icon='refresh' disabled={false} onClick={() => backToForm()} intent='primary' style={{width:'33%', marginBottom: 10}}/>
                        <Button text='удалить агента' icon='trash' disabled={currentAgent._id ? false : true} onClick={showDialog} intent='danger' style={{width:'33%', marginBottom: 10, visibility: currentAgent._id ? 'visible' : 'hidden'}} loading={loading}/>
                    </div>
                    <Dialog
                        icon='Remove'
                        isOpen={visible}
                        title="Удалить агента?"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={true}
                        onClose={() => {
                            updateVisible(!visible);
                        }}
                    >
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-around'}}>
                            <Button
                                text='Отменить'
                                intent='primary'
                                onClick={() => {
                                    updateVisible(!visible);
                                    updateLoading(false);
                                }}
                                style={{width:'45%'}}
                            />
                            <Button
                                text='Удалить'
                                intent='danger'
                                onClick={() => deleteAgent(currentAgent._id)}
                                style={{width:'45%'}}
                            />
                        </div>
                    </Dialog>
                </div>
            }
            <Toaster position='TOP_RIGHT' ref={toaster}></Toaster>
        </div>
    )
}

export default Agents
