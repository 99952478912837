import React, { useState, useEffect } from 'react'
import { Spinner, Tag, Button } from '@blueprintjs/core'
import { useHistory } from 'react-router-dom'
const rest = require('../Workspace/rest')

const mergeData = ({ clubs, squads, seasons, divisions, ...rest}) => {
    return {
        ...rest,
        states: Object.keys(rest.states).reduce((acc, sid) => {
            const squad = squads.find(s => s._id === sid)
            const club = squad ? clubs.find(c => c._id === squad.clubId) : null
            const season = squad ? seasons.find(s => s._id === squad.seasonId) : null
            const division = season ? divisions.find(d => d._id === season.divisionId) : null
            acc[sid] = {
                ...rest.states[sid],
                club: club,
                season: season,
                division: division
            }
            return acc
        }, {})
    }
}

const StatesItem = ({ data, playerId, sid, onHooked }) => {
    const [progress, setProgress] = useState(false)

    const completedHook = () => {
        setProgress(true)
        rest('put', 'hooks/confirmedplayer', playerId, {
            clubId: data.club._id,
            squadId: sid,
            leagueId: data.division.leagueId,
            clubName: data.club.name,
            divisionName: data.division.name,
            seasonName: data.season.name
        }).then(hooked => {
            if(hooked.success) {
                setProgress(false)
                onHooked(sid)
            } else {
                alert(hooked.message)
            }
        })
    }

    return data && data.club ? <div style={{marginTop: 20}}>
                <div>Клуб: {data.club.name} <Tag large={true} minimal={true} intent={data.club.remote.source === 'lfl' ? 'primary' : 'success'} style={{textTransform: 'uppercase'}}>{data.club.remote.source}</Tag></div>
                <div>{data.division.name} {data.season.name}</div>
                <div><Tag intent={data.suggestedTransfer ? 'danger' : 'warning'} minimal={true} large={true}>{data.suggestedTransfer ? 'Помечен оргом как трансфер' : 'Новый профиль'}</Tag></div>
                <Button disabled={progress} loading={progress} text='Заявка выполнена' minimal={true} intent='primary' onClick={() => completedHook()} />
            </div> : 'No data or club assigned...'
}

const Item = ({ playerId }) => {
    const [data, setData] = useState(null)
    const history = useHistory()

    useEffect(() => {
        setData(null)
        rest('get', 'players', playerId)
            .then(player => {
                setData(mergeData(player))
            })
    }, [playerId])

    const onHooked = sid => {
        history.push('/players')
    }

    return  data ? (
                <div>
                    <div>{data.name}</div>
                    <div>{data.surname}</div>
                    <div>{data.birthday}</div>
                    <div>{data.position}</div>
                    <div>
                        {Object.keys(data.states).map(sid => (
                            <StatesItem data={data.states[sid]} playerId={playerId} sid={sid} onHooked={onHooked} />
                        ))}
                    </div>
                </div>
            ) : <Spinner />
}

export default Item
