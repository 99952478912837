import React, { useState, useEffect } from 'react';
import { Button, Tag, HTMLSelect, Label } from '@blueprintjs/core';
import { DateInput } from "@blueprintjs//datetime";

import StatsForm from './StatsForm.js';

const rest = require('../../../components/Workspace/rest');
const moment = require('moment');

const matchShema = {
    date: moment().format('YY-MM-DD'),
    time: '14:00',
    homeId: '',
    awayId: ''
}

const statShema = {
    _id: '',
    matchId: '',
    broadcasterId: '',
    agentId: '',
    price: 0,
    bounty: 0,
    entity: {
        duration: 0,
        offsides: false,
        isProduction: false,
        live: false,
        periods: 2,
        etDuration: 0,
        pureTime: false,
        passesMode: "accuracy",
        freq: 4
    }
}
const Bind = () => {
    const [matchesList, setMatchesList] = useState([]);

    const [match, setMatch] = useState({...matchShema});
    const [stat, setStat] = useState({...statShema});

    const [leaguesList, setLeaguesList] = useState([]);
    const [leagueId, setLeagueId] = useState('');

    const [divisionsList, setDivisionsList] = useState([]);
    const [divisionId, setDivisionId] = useState('');

    const [dateMatch, setDateMatch] = useState(match.date)


    useEffect(() => {
        rest('get','leagues')
            .then(leagues => {
                setLeaguesList([{name: 'не выбрано', _id:''}, ...leagues]);
            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        if (leagueId) {
            rest('get','divisions')
                .then(divs => {
                    setDivisionsList([{name: 'не выбрано', _id:''}, ...divs.filter(div => div.leagueId === leagueId)]);
                })
                .catch(err => console.log(err));
        }
    }, [leagueId]);

    useEffect(() => {
        if (leagueId && dateMatch && !divisionId) {
            rest('get',`matches?filter[leagueId]=${leagueId}&filter[date]=${dateMatch}`)
                .then(matches => {
                    setMatchesList([...matches]);
                })
                .catch(err => console.log(err));
        }
        else if (leagueId && dateMatch && divisionId) {
            rest('get',`matches?filter[leagueId]=${leagueId}&filter[divisionId]=${divisionId}&filter[date]=${dateMatch}`)
                .then(matches => {
                    setMatchesList([...matches]);
                })
                .catch(err => console.log(err));
        }
    },[leagueId, divisionId, dateMatch]);

    const handleChange = (evt) => {
        if (evt.target.name === 'leagues') {
            setLeagueId(evt.target.value);
        } else {
           setDivisionId(evt.target.value);
        }
    }

    const handleDateChange = (date) => {
        let nDate = moment(date).format('YY-MM-DD');
        setDateMatch(nDate);
    }
    const takeMatch = (id) => {
        const current = matchesList.find(item => item._id === id)
        setMatch({...match, ...current});
        setStat({...stat, matchId: id});
    }
    const backToList = () => {
        setMatch({...matchShema});
        setStat({...statShema});
    }

    return (
        <div>
            {match._id ? null :
                <div>
                    <Label>Лига
                        {leaguesList.length ?
                            <HTMLSelect
                                options={leaguesList.map(item => ({value:item._id, label:item.name}))}
                                onChange={handleChange}
                                value={leagueId}
                                name='leagues'
                            />
                        : <div>'идет загрузка'</div>
                        }
                    </Label>
                    <Label  style={{visibility: leagueId ? 'visible' : 'hidden'}}>Дивизион
                        {divisionsList.length ?
                            <HTMLSelect
                                options={divisionsList.map(item => ({ value: item._id, label: item.name}))}
                                onChange={handleChange}
                                name='divisions'
                                value={divisionId}
                                style={{visibility: leagueId && divisionsList.length ? 'visible' : 'hidden'}}
                            />
                        : <div style={{visibility: leagueId && !divisionsList.length ? 'visible' : 'hidden'}}>'идет загрузка'</div>}
                    </Label>
                    <div style={{visibility: leagueId ? 'visible' : 'hidden', marginBottom: 10}}>
                        <DateInput
                            canClearSelection={true}
                            name='date'
                            formatDate={date => moment(date).format('YY-MM-DD')}
                            parseDate={str => new Date(str)}
                            onChange={handleDateChange}
                            value={moment(dateMatch, 'YY-MM-DD').toDate()}
                            placeholder={"выберите дату матча"}
                            highlightCurrentDay={true}
                        />
                    </div>
                    {matchesList.length > 0 ?
                        <div style={{maxHeight:150, overflowY:'scroll'}}>
                            {matchesList.map(item => {
                                return (
                                    <Tag
                                        style={{display:'block', width: '100%', marginBottom:10, fontSize: 14, padding:5}}
                                        key={item._id}
                                        icon={(<img alt='' src={item ? (item.homeclub ? item.homeclub.emblem : require('../../../assets/color/badge.svg')) : require('../../../assets/color/badge.svg')} style={{width: 22, height: 22}} />)}
                                        interactive={true}
                                        onClick={() => takeMatch(item._id)}
                                    >{item.date} {item.homeClub.name} X {item.awayClub.name}</Tag>
                                )
                            })}
                        </div>
                        : <div style={{visibility: leagueId ? 'visible' : 'hidden'}}>нет матчей</div>
                    }
                </div>
            }
            {match._id ?
                <div>
                    <StatsForm initialMatch={match} initialStat={stat} homeClubFromStat={match.homeClub ?? null} awayClubFromStat={match.awayClub ?? null}/>
                    <Button intent='primary' text='К списку' onClick={() => backToList()} />
                </div>
                : null
            }
        </div>
    )
}

export default Bind;
