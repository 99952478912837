import React, { useState, useEffect } from 'react'
import Squad from './Squad'
import Events from './Events'

const inners = {
    squad: Squad,
    evs: Events
}

const StepContent = props => {
    const key = props.path.replace(/(home|away)/g, '').toLowerCase()
    const Specified = inners[key]

    return  <div>
                <Specified {...props} />
            </div>
}

export default StepContent
