module.exports = {
    leagues: [
        [
            {
                type: 'text',
                key: 'name',
                label: 'Название'
            }
        ],
        [
            {
                type: 'url',
                key: 'emblem',
                label: 'Эмблема'
            }
        ]
    ],
    users: [
        [
            {
                type: 'text',
                key: 'first_name',
                label: 'Имя'
            },
            {
                type: 'text',
                key: 'last_name',
                label: 'Фамилия'
            }
        ],
        [
            {
                type: 'text',
                key: 'tid',
                label: 'Telegram ID'
            }
        ]
    ],
    divisions: [
        [
            {
                type: 'text',
                key: 'name',
                label: 'Название'
            }
        ]
    ],
    seasons: [
        [
            {
                type: 'text',
                key: 'name',
                label: 'Сезон'
            }
        ],
        [
            {
                type: 'radio',
                key: 'remote.source',
                label: 'Внешний источник',
                options: [
                    {value: 'lfl', label: 'LFL.RU'},
                    {value: 'goalstream', label: 'goalstream.org'},
                    {value: 'ole', label: 'olesports.ru'},
                    {value: 'joinsport', label: 'Join Sport'}
                ]
            },
            {
                type: 'text',
                key: 'remote.id',
                label: 'Внешний ID'
            }
        ]
    ],
    clubs: [
        [
            {
                type: 'text',
                key: 'name',
                label: 'Название'
            },
            {
                type: 'text',
                key: 'from.city',
                label: 'Город'
            },
            {
                type: 'text',
                key: 'emblem',
                label: 'Эмблема'
            }
        ]
    ],
    squadplayers: [
        [
            {
                type: 'text',
                key: 'name',
                label: 'Имя Фамилия'
            }
        ],
        [
            {
                type: 'number',
                key: 'num',
                label: 'Игровой номер'
            },
            {
                type: 'text',
                key: 'city',
                label: 'Город'
            }
        ],
        [
            {
                type: 'radio',
                key: 'remote.source',
                label: 'Внешний источник',
                options: [
                    {value: 'lfl', label: 'LFL.RU'},
                    {value: 'goalstream', label: 'goalstream.org'}
                ]
            },
            {
                type: 'text',
                key: 'remote.id',
                label: 'Внешний ID'
            }
        ]
    ],
    players: [
        [
            {
                type: 'text',
                key: 'name',
                label: 'Имя Фамилия'
            }
        ],
        [
            {
                type: 'number',
                key: 'num',
                label: 'Игровой номер'
            },
            {
                type: 'text',
                key: 'city',
                label: 'Город'
            }
        ],
        [
            {
                type: 'radio',
                key: 'remote.source',
                label: 'Внешний источник',
                options: [
                    {value: 'lfl', label: 'LFL.RU'},
                    {value: 'goalstream', label: 'goalstream.org'}
                ]
            },
            {
                type: 'text',
                key: 'remote.id',
                label: 'Внешний ID'
            }
        ],
        [
            {
                type: 'checkbox',
                label: 'Активна дисквалификация',
                key: 'disqualified'
            }
        ],
        [
            {
                type: 'checkbox',
                label: 'Не печатать',
                key: 'unprintable'
            }
        ]
    ]
}
