import React, { useState, useEffect } from 'react'
import { Card, Checkbox, Icon, Button, RadioGroup, Radio } from '@blueprintjs/core'
import Splash from '../Splash'
import moment from 'moment'

moment.locale('ru')

const rest = require('../../../components/Workspace/rest')

const PrintList = ({ setTitle, leagueId, tid, email }) => {
    const [selected, setSelected] = useState([])
    const [list, setList] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [channel, setChannel] = useState('telegram-archive')
    const [ready, setReady] = useState(false)

    useEffect(() => {
        setTitle({
            string: 'Доставка протоколов',
            onBack: 'resetMode'
        })

        rest('get', 'printable', leagueId)
            .then(data => {
                setList(data.sort((a, b) => {
                    const _a = moment(a._id, 'YY-MM-DD').unix()
                    const _b = moment(b._id, 'YY-MM-DD').unix()
                    return _a - _b
                }))
            })
    }, [])

    const toggleMatch = m => {
        const ex = selected.find(i => i._id === m._id)
        setSelected(ex ? selected.filter(s => s._id !== m._id) : selected.concat([m]))
    }

    const sendSheets = () => {
        rest('put', 'hooks/sendsheets', channel.replace('-archive', ''), {
            target: channel.includes('email') ? email : tid,
            matches: selected,
            archive: (channel && channel.includes('archive'))
        }).then(resp => {
            setReady(true)
            setTimeout(() => {
                window.close()
            }, 2000)
        })
    }

    return  <div className='screen list' style={{flexWrap: 'nowrap', paddingBottom: 80}}>
                {list ? list.map(day => (
                    <div className='list-segment'>
                        <div className='list-segment_title'>{moment(day._id, 'YY-MM-DD').format('D MMMM')}</div>
                        {day.data.map(m => (
                            <Card elevation={2} className='list-item' interactive={true} onClick={() => toggleMatch({_id: m._id, filename: `${m.homeClub.name} vs ${m.awayClub.name}_${m.date}_${m.time}.pdf`})}>
                                <Checkbox style={{marginBottom: 0}} large={true} checked={selected.find(i => i._id === m._id)} />
                                <div style={{fontSize: 16, padding: '8px 0', marginLeft: 6, whiteSpace: 'nowrap', overflow: 'hidden'}}>{m.time}  {m.homeClub.name} <Icon icon='cross' /> {m.awayClub.name}</div>
                            </Card>
                        ))}
                    </div>
                )) : null}
                <div className={'bottom-panel'+(confirm ? ' opened' : '')}>
                    <Button
                        text={selected.length > 0 ? confirm ? 'Отправить сейчас!' : `Доставить протоколы (${selected.length})` : 'Выберите матчи'}
                        intent={selected.length > 0 ? 'default' : 'none'}
                        large={true}
                        fill={true}
                        disabled={selected.length === 0}
                        onClick={() => !confirm ? setConfirm(true) : sendSheets()}
                    />

                    <RadioGroup selectedValue={channel} onChange={e => setChannel(e.target.value)} className='radio-group'>
                        <Radio label='Отправить в Telegram' value='telegram' large={true} />
                        <Radio label='В Telegram одним архивом' value='telegram-archive' large={true} />
                        {email ? <Radio label={'Отправить на '+email+' (Только архивом)'} value='email-archive' large={true} /> : null}
                    </RadioGroup>

                    <Button minimal={true} text='Отмена' icon='cross' onClick={() => setConfirm(false)} />
                </div>
                <Splash active={ready} status='success' />
            </div>
}

export default PrintList
