import { React, useState } from 'react'
import { Button, Switch } from '@blueprintjs/core'
import Form from '../Forms'

const User = ({ data, putChanges, resetNestedItem, leagueId }) => {
    const [formData, setFormData] = useState(null)
    const [subscriptions, setSubscriptions] = useState(data.subscriptions ? data.subscriptions[leagueId] : {})

    const subTypes = [
        {
            key: 'receivedSheets',
            label: 'Поступление протокола в обработку'
        },
        {
            key: 'moderatedSheets',
            label: 'Модерированные протоколы'
        }
    ]

    const setSub = (k, v) => {
        setSubscriptions({
            ...subscriptions,
            [k]: v
        })

        putChanges({[`subscriptions.${leagueId}.${k}`]: v})
    }

    return  <div>
                <Form
                    collection='users'
                    throwForm={data => setFormData(data)}
                    initial={data}
                />
                <div style={{paddingLeft: 12}}>
                    <Button intent='success' text='Сохранить изменения' onClick={() => putChanges(formData)} />
                </div>

                <div className='section'>
                    <div className='section-title'>Подписки на события:</div>
                    {/*subTypes.map(st => (
                        <Switch label={st.label} checked={subscriptions[st.key]} onChange={e => setSub(st.key, e.target.checked)} />
                    ))*/}
                </div>
            </div>
}

export default User
