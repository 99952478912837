import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Toaster, Position, Button } from '@blueprintjs/core'

import rest from '../../../components/Workspace/rest'
import Splash from '../Splash'
import Player from '../Screens/Player'
import Transfer from '../Screens/Transfer'

import mappers from './mappers'

import '../style.css'

const screens = {
    playerForm: Player,
    addPlayer: Player,
    transferMode: Transfer
}

const Action = () => {
    const [stored, setStored] = useState(null)
    const [data, setData] = useState(null)
    const toaster = useRef(null)

    const { actionId } = useParams()

    useEffect(() => {
        rest('get', 'actioninfo', actionId)
            .then(action => {
                if(action.error) {
                    toaster.current.show({intent: 'danger', message: action.message})
                    setTimeout(() => {
                        window.close()
                    }, 1000)
                } else {
                    setStored(action)
                }
            })
    }, [])

    useEffect(() => {
        if(stored && stored.type) {
            let req
            switch(stored.type) {
                case 'playerForm':
                    req = {method: 'get', col: 'players', id: stored.playerId}
                    break
                case 'addPlayer':
                    setData({})
                    break
                case 'transferMode':
                    req = {method: 'get', col: 'transfersources', id: stored.clubId}
                    break
            }

            if(req) {
                rest(req.method, req.col, req.id)
                    .then(response => {
                        if(!response.error) {
                            setData(mappers[stored.type] ? mappers[stored.type](response, stored) : response)
                        } else {
                            toaster.current.show({intent: 'danger', message: response.message})
                        }
                    })
            }
        }
    }, [stored])

    const Screen = stored && stored.type ? screens[stored.type] || null : null

    return  <div>
                <Splash active={!data} />
                <Toaster position={Position.TOP_CENTER} ref={toaster}></Toaster>
                {Screen && data ? <Screen data={data} context={stored} actionId={actionId} /> : null}
            </div>
}

export default Action
