import React, { useState, useRef, useEffect } from 'react'
import { Spinner, Toaster } from '@blueprintjs/core'
import InputMask from 'react-input-mask'
import axios from 'axios'
import { REST } from './env'

import './common.css'

const Auth = () => {
    const [val, setVal] = useState('')
    const [checking, setChecking] = useState(false)
    const toaster = useRef(null)
    const inp = useRef(null)

    const checkCode = () => {
        setChecking(true)
        try {
            axios.post(`${REST}access`, {code: val})
                .then(resp => {
                    setChecking(false)
                    if(resp.data) {
                        if(resp.data.error) {
                            toaster.current.show({intent: 'danger', message: resp.data.message})
                        } else {
                            localStorage.setItem('_am_accessId', JSON.stringify(resp.data))
                            setTimeout(() => {
                                window.location.replace('/moderate')
                            }, 1000)
                        }
                    } else {
                        toaster.current.show({intent: 'danger', message: 'Невозможно отправить запрос'})
                    }
                })
        } catch(e) {
            toaster.current.show({intent: 'danger', message: 'Ошибка доступа'})
        }
    }

    useEffect(() => {
        const unmasked = val.replace(/-/g, '')
        if(unmasked.length === 5) {
            setTimeout(() => {
                checkCode()
            }, 1000)
        }
    }, [val])

    return  <div className='auth'>
                <Toaster ref={toaster} />
                <div className='auth-inner'>
                    <h1>ваш код доступа</h1>
                    <InputMask
                        autoFocus
                        mask='99999'
                        maskChar='-'
                        disabled={checking}
                        value={val}
                        onChange={e => setVal(e.target.value)}
                    />
                </div>
                {checking ? (
                    <Spinner />
                ) : null}
            </div>
}

export default Auth
