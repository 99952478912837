import React from 'react'
import { Card } from '@blueprintjs/core'

import Orders from './Orders'
import Graphs from './Graphs'
import Content from './Content'
import Agents from './Agents'
import Settings from './Settings'
import Leagues from './Leagues'
import Clubs from './Clubs'

import './style.css'
import '../../common.css'

const Stats = () => {
    return  <div id='stats'>
                <div id='orders'>
                    <Card elevation={2}>
                        <Orders />
                    </Card>
                </div>
                <div id='graphs'>
                    <Card elevation={2}>
                        <Clubs />
                    </Card>
                </div>
                <div id='content'>
                    <Card elevation={2}>
                        <Content />
                    </Card>
                </div>
                <div id='agents'>
                    <Card elevation={2}>
                        <Agents />
                    </Card>
                </div>
                <div id='break'>
                    <Card elevation={2}>
                        <Settings />
                    </Card>
                </div>
                <div id='leagues'>
                    <Card elevation={2}>
                        <Leagues />
                    </Card>
                </div>
                <div id='clubs'>
                    <Card elevation={2}>
                        <Graphs />
                    </Card>
                </div>
            </div>
}

export default Stats
