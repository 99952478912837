import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Card, Tag, Icon } from '@blueprintjs/core'
import Item from './Item'
import qs from 'qs'
const rest = require('../Workspace/rest')

const Players = props => {
    const [filters, setFilters] = useState({
        onReview: true
    })

    const [players, setPlayers] = useState([])
    const history = useHistory()
    const { playerId } = useParams()

    useEffect(() => {
        const query = Object.keys(filters).reduce((acc, k) => {
            if(filters[k]) { acc.filter[k] = filters[k] }
            return acc
        }, {filter: {}})

        const queryString = decodeURIComponent(qs.stringify(query))

        rest('get', 'players'+(queryString.length > 0 ? '?'+queryString : ''))
            .then(players => setPlayers(players))
    }, [filters])

    return  <div className='space'>
                <div className='side'>
                </div>

                <div className='body'>
                    {players.map(data => (
                        <Card elevation={1} interactive={true} className='list-item' onClick={() => history.push('/players/'+data._id)}>
                            <div style={{width: 400}}>
                                <div>{data.name}</div>
                            </div>
                        </Card>
                    ))}
                </div>

                <div className='item'>
                    {playerId ? <Item playerId={playerId} /> : null}
                </div>
            </div>
}

export default Players
