import { io } from 'socket.io-client'
import React, { useState, useEffect, useRef } from 'react'
import { Tag, NonIdealState, Toaster, Icon, Position, Drawer, DrawerSize, Button, Card, Tabs, Tab, Spinner, Popover } from '@blueprintjs/core'
import CustomScrollbars from 'react-custom-scrollbars-2'
import { Helmet } from 'react-helmet'
import { useParams, useHistory } from 'react-router-dom'
import Item from './Item'
import ItemV2 from './v2'
import { HotKeys } from 'react-hotkeys'
import moment from 'moment'

import './style.css'

const rest = require('../Workspace/rest')

const keyMap = {
    FOCUS_FILTER: 'ctrl+q'
}

const INTERVAL = 5000
let access
const accessId = localStorage.getItem('_am_accessId')
if(!accessId && window.location.pathname !== '/' && !window.location.href.includes('app.')) {
    window.location.replace('/')
} else {
    access = JSON.parse(accessId)
}

const compareLists = (items, data) => {
    const itemsIds = items.map(m => m.matchday.matchId)
    const dataIds = data.map(m => m.matchday.matchId)
    const _new = data.filter(m => itemsIds.indexOf(m.matchday.matchId) === -1)
    const _del = items.filter(m => dataIds.indexOf(m.matchday.matchId) === -1).map(m => m.matchday.matchId)

    return {add: _new, del: _del}
}

const MatchListItem = React.memo(({ data, setDrawer, upcoming, toaster }) => {
    const [endorsing, setEndorsing] = useState(false)
    const history = useHistory()
    const refines = data.refinements && data.refinements[0] && data.refinements[0].list ? data.refinements[0].list.find(r => r.matchId === data._id) : null
    const unhandled = refines ? refines.items.filter(i => i.reply && !i.applied) : null

    const endorseMatch = () => {
        setEndorsing(true)
        rest('post', 'moderator', null, {
            moderator: access.tid,
            match: data._id
        }).then(resp => {
            setEndorsing(false)
            toaster.current.show({intent: resp.error ? 'danger' : 'success', message: resp.error ? resp.message : `Отлично, ${access.first_name}! Открываем интерфейс матча...`})
            if(!resp.error) {
                history.push('/moderate/'+data._id)
                setTimeout(() => {
                    setDrawer(false)
                }, 800)
            }
        })
    }

    return  <Card elevation={2} interactive={true} className='mod-snippet' onClick={() => {
                if(!upcoming) {
                    history.push('/moderate/'+data._id)
                    setTimeout(() => {
                        setDrawer(false)
                    }, 800)
                }
            }}>
                <div className='tags'>
                    {data.state && data.state.drafted ? <Tag intent='none'>черновик</Tag> : null}
                    {(!data.moderator || !data.moderator.globalAdmin) && access.tid && !upcoming ? <Button disabled={endorsing} loading={endorsing} onClick={() => endorseMatch()} intent='success' small={true} icon='endorsed' text='Забрать себе!' /> : null}
                    {data.moderator && data.moderator.tid ? <Tag intent={data.moderator.last_name.includes('Г') ? 'warning' : 'primary'}>{data.moderator.first_name} {data.moderator.last_name}</Tag> : null}
                    {unhandled && unhandled.length > 0 ? <Tag intent='danger'>{unhandled.length} уточнений</Tag> : null}
                </div>
                <div className='teams'>
                    <div>
                        <span>{data.home ? data.home.name : data.draft.matchday.home}</span>
                        <img src={data.home? data.home.emblem : null} />
                    </div>
                    <Icon icon='cross' />
                    <div>
                        <img src={data.away ? data.away.emblem : ''} />
                        <span>{data.away ? data.away.name : data.draft.matchday.away}</span>
                    </div>
                </div>
                <div className='info'>
                    <div>
                        <div>{data.league ? data.league.name : data.draft ? data.draft.matchday.league : ''}</div>
                        <div>{data.division ? data.division.name : ''}</div>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        {!upcoming ? <div>отпр: {data.sender && data.sender.tid ? data.sender.first_name+' '+data.sender.last_name : 'хз'}</div> : null}
                        {!upcoming ? <div>{moment(data.date, 'YY-MM-DD').format('D MMM')} {data.time}</div> : <div><b>{data.time}</b></div>}
                    </div>
                </div>
            </Card>
})

const Upcoming = React.memo(() => {
    const [list, setList] = useState(null)
    const [date, setDate] = useState(moment().format('YY-MM-DD'))

    useEffect(() => {
        setList(null)
        rest('get', `matches?filter[date]=${date}`)
            .then(ms => {
                setList(ms.filter(m => !m.draft && !m.protocol).sort((a, b) => {
                    return moment(a.time, 'HH:mm').unix() - moment(b.time, 'HH:mm').unix()
                }))
            })
    }, [date])

    const List = () => {
        return  list ?  (
            <CustomScrollbars
                autoHeight
                autoHeightMin='100vh'
                autoHide={true}
                autoHideTimeout={700}
                autoHideDuration={500}
            >
                {list.filter(m => m && m.draft).map(m => (<MatchListItem setDrawer={null} key={m._id} data={{...m, home: m.homeClub, away: m.awayClub}} upcoming={true} />))}
            </CustomScrollbars>
        ) : (
            <NonIdealState icon={(<Spinner />)} description='Загрузка...' />
        )
    }

    return  <Tabs selectedTabId={date} onChange={d => setDate(d)}>
                <Tab title='Сегодня' id={moment().format('YY-MM-DD')} panel={(<List />)} />
                <Tab title='Завтра' id={moment().add(1, 'days').format('YY-MM-DD')} panel={(<List />)} />
            </Tabs>
})

const Stats = React.memo(({ matchId }) => {
    const [data, setData] = useState(null)
    useEffect(() => {
        rest('get', 'modsummary')
            .then(summary => {
                setData(summary)
            })
    }, [matchId])

    return data ? (
        <div style={{position: 'fixed', bottom: 20, left: 60}}>
            <Popover
                content={(
                    <Card elevation={2} style={{padding: 14, borderRadius: 14}}>
                        {data.map(day => ([
                            <div style={{marginTop: 6}}>
                                <b>{day.label}</b>
                            </div>,
                            <div style={{display: 'flex', width: 320, justifyContent: 'space-between'}} className='stat-popover-cell'>
                                <div>Всего матчей: <b>{day.total}</b></div>
                                <div>Самый ранний: <b>{day.earliest}</b></div>
                                <div>Самый поздний: <b>{day.latest}</b></div>
                            </div>,
                            day.await ? (<div style={{display: 'flex', width: 320, marginTop: 4, justifyContent: 'space-between'}} className='stat-popover-cell'>
                                <div>Ожидаем: <b>{day.await}</b></div>
                                <div>В работе: <b>{day.inProgress}</b></div>
                                <div>Готово: <b>{day.completed}</b></div>
                            </div>) : null
                        ]))}
                    </Card>
                )}
            >
                <Button intent='primary' large={true} icon='chart' style={{borderRadius: '50%'}} minimal={true} />
            </Popover>
        </div>
    ) : null
})


const ModerateSheet = props => {
    const { matchId } = useParams()
    const [connected, setConnected] = useState(false)
    const [drawer, setDrawer] = useState(matchId ? false : true)
    const [items, setItems] = useState([])
    const [item, setItem] = useState(null)
    const [extendedStats, setExtendedStats] = useState(false)
    const [itemMeta, setItemMeta] = useState(null)
    const [sources, setSources] = useState(null)
    const [loading, setLoading] = useState(false)
    const [drawerTab, setDrawerTab] = useState('current')
    const [dates, setDates] = useState({
        min: moment().add(-20, 'days').format('YY-MM-DD'),
        max: moment().add(3, 'days').format('YY-MM-DD')
    })
    const [refsQty, setRefsQty] = useState(0)

    const toaster = useRef(null)
    const history = useHistory()

    const loadMatches = () => {
        setLoading(true)
        rest('get', `moderation?filter[mindate]=${dates.min}&filter[maxdate]=${dates.max}`)
            .then(list => {
                setItems(list.slice(0, 20))
                setLoading(false)
            })
    }

    useEffect(() => {
        loadMatches()
    }, [])

    useEffect(() => {
        if(items.length > 0) {
            const rq = items.reduce((acc, i) => {
                const rs = i.refinements && i.refinements[0] && i.refinements[0].list ? i.refinements[0].list.find(r => r.matchId === i._id) : null
                const ud = rs ? rs.items.filter(i => i.reply && !i.applied) : null
                acc += ud ? ud.length : 0
                return acc
            }, 0)

            setRefsQty(rq)
        }
    }, [items])

    useEffect(() => {
        if(!loading) {
            const interval = setInterval(() => { loadMatches() }, INTERVAL)
            return () => { clearInterval(interval) }
        }
    }, [loading])

    useEffect(() => {
        if(!matchId) {
            setItem(null)
            setItemMeta(null)
            setDrawer(true)
        } else {
            rest('get', 'matches', matchId)
                .then(data => {
                    if(!item || (item.matchday.matchId !== data._id)) {
                        setItem(null)
                        setTimeout(() => {
                            setItem(data.draft)
                            setExtendedStats(data.extendedStats || (data.season && data.season.extendedStats) || false)
                            setSources(data.sources)
                            const entity = items.find(i => i._id === data._id)
                            setItemMeta(entity ? {sender: entity.sender, moderator: entity.moderator, home: data.home, away: data.away, season: data.season} : {home: data.home, away: data.away, season: data.season})
                        }, 300)
                    }
                })
        }
    }, [matchId])

    return  <div className='workspace'>
                <Helmet>
                    <title>{refsQty > 0 ? 'Есть уточнения | ' : ''}Модерация матчей</title>
                    <link rel="icon" href={'/favicon'+(refsQty > 0 ? '_marked' : '')+'.ico'} />
                </Helmet>

                <Toaster position={Position.TOP} ref={toaster}></Toaster>

                <Stats matchId={matchId} />

                <Drawer
                    isOpen={drawer}
                    position={Position.LEFT}
                    size='300px'
                >
                    <Button large={true} icon='cross' onClick={() => setDrawer(false)} intent='danger' style={{borderRadius: '50%', width: 40, position: 'fixed', top: 10, left: 320}} minimal={true} />
                    <div className='workspace-list unpadded'>
                        <Tabs selectedTabId={drawerTab} onChange={t => setDrawerTab(t)}>
                            <Tab
                                title='Ожидаем'
                                id='upcoming'
                                panel={(
                                    <Upcoming />
                                )}
                            />
                            <Tab
                                title='В работе'
                                id='current'
                                panel={(
                                    <CustomScrollbars
                                        autoHeight
                                        autoHeightMin='100vh'
                                        autoHide={true}
                                        autoHideTimeout={700}
                                        autoHideDuration={500}
                                    >
                                        {items.length === 0 ? <NonIdealState icon='history' description='В ожидании матчей' /> : items.map(m => <MatchListItem toaster={toaster} setDrawer={setDrawer} key={m._id} data={m} />)}
                                    </CustomScrollbars>
                                )}
                            />
                        </Tabs>
                    </div>
                </Drawer>

                <div className={'workspace-drawer-trigger bp3-intent-'+(items.length === 0 ? 'success' : 'danger')}>
                    <Button
                        minimal={true}
                        large={true}
                        intent={items.length === 0 ? 'success' : 'danger'}
                        text={items.length === 0 ? 'Нет матчей в обработке' : 'Матчей ожидают обработки: '+items.length}
                        onClick={() => setDrawer(true)}
                    />
                </div>

                <HotKeys keyMap={keyMap}>
                    <div className='workspace-body'>
                        {item ? window.location.href.includes('work') || window.location.href.includes('localhost') ? (
                            <ItemV2
                                key={matchId}
                                matchId={matchId}
                                draft={item}
                                meta={itemMeta}
                                sources={sources}
                                extendedStats={extendedStats}
                            />
                        ) : (
                            <Item
                                key={matchId}
                                matchId={matchId}
                                _data={item}
                                toaster={toaster}
                                onSentData={() => history.push('/moderate')}
                            />
                        ) : null}
                    </div>
                </HotKeys>
            </div>
}

export default ModerateSheet
