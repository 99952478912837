const _ = {}

_.extractMatchStatus = input => {
    switch(true) {
        case !input.protocol:
            return {text: 'Ожидает протокола', intent: 'warning'}
            break
        case input.protocol && !input.data:
            return {text: 'В обработке системой', intent: 'danger'}
            break
        case input.data && input.interactions:
            return {text: 'На утверждении', intent: 'primary'}
            break
        case input.posted:
            return {text: 'Утверждён и доставлен', intent: 'success'}
            break
        default:
            return {text: 'Unknown status', intent: 'none'}
    }
}

_.testTargets = {
    league: '5fd0fa26091c897f70e30257',
    lflDivision: '60102f5b154ea15fe5544dcd',
    goalstreamDivision: '6012e6a9c9fb401da29e00ad',
    lflSeason: '60102f63154ea15fe5544dce',
    goalstreamSeason: '6012e6b5c9fb401da29e00ae',
    lflMatchday: '601136f795d3364b42a7ed70',
    goalstreamMatchday: '6012e840a3ec403a38f32116'
}

_.source = [
    [
        {
            type: 'date',
            key: 'date',
            label: 'Дата',
            width: 40
        },
        {
            type: 'time',
            key: 'time',
            label: 'Время',
            width: 35
        }
    ],
    [
        {
            type: 'select',
            key: 'homeId',
            label: 'Команда хозяев',
            refs: 'clubs',
            width: 40
        },
        {
            type: 'select',
            key: 'awayId',
            label: 'Команда гостей',
            refs: 'clubs',
            width: 40
        }
    ],
    [
        {
            type: 'text',
            key: 'engineId',
            label: 'ID на движке',
            width: 50
        }
    ]
]

module.exports = _
