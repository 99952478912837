import { React } from 'react'

import { Card } from '@blueprintjs/core'
import AssignScopes from './AssignScopes'
import AssignClubToSeason from './AssignClubToSeason'
import SeasonSquad from './SeasonSquad'
import Matchday from './Matchday'

const Dialog = ({type, ...rest}) => {
    const dialogs = {
        assignScopes: AssignScopes,
        assignClubToSeason: AssignClubToSeason,
        seasonSquadDialog: SeasonSquad,
        matchday: Matchday
    }

    const Specified = dialogs[type]
    return  <Card className='overlay-form' elevation={4}>
                {Specified ? <Specified {...rest} /> : null}
            </Card>
}

export default Dialog
