import React, { useState, useEffect } from 'react'
import { Card, ControlGroup, Checkbox } from '@blueprintjs/core'
const rest = require('../rest')

const rolesModel = {
    receiveModeratedSheets: [],
    receiveRenderedSummary: [],
    receiveStatusesOnly: [],
    receiveRefinements: []
}

const rolesLabels = {
    receiveModeratedSheets: 'Получать результат обработки на утверждение',
    receiveRenderedSummary: 'Получать графику отчёта о матче в личку',
    receiveStatusesOnly: 'Получать только статусы по движению матча',
    receiveRefinements: 'Получать уточнения по матчу'
}

const assignRoles = (list, roles) => {
    return !list ? [] : list.filter(s => s.roles && s.roles.find(r => ['admin', 'owner'].includes(r.role))).map(s => ({
        ...s,
        roles: Object.keys(roles).reduce((acc, role) => {
            acc[role] = roles[role].includes(s._id)
            return acc
        }, {})
    }))
}

const DivisionSettings = ({ data }) => {
    const [globalStaffs, setGlobalStaffs] = useState([])
    const [config, setConfig] = useState(data.config || {})

    useEffect(() => {
        rest('get', `staffs?filter[roles.leagueId]=${data.leagueId}`, null)
            .then(list => {
                setGlobalStaffs(assignRoles(list, data.roles || rolesModel))
            })
        setConfig(data.config || {})
    }, [data._id])

    const toggleRole = (staffId, k, v) => {
        rest('put', 'divisions', data._id, {[v ? 'push' : 'shift']: ['roles.'+k, staffId]})
            .then(upd => {
                setGlobalStaffs(globalStaffs.map(s => ({
                    ...s,
                    roles: s._id === staffId ? {
                        ...s.roles,
                        [k]: v
                    } : s.roles
                })))
            })
        //console.log(staffId ,k, v)
    }

    const patchConfig = (k, v) => {
        rest('put', 'divisions', data._id, {['config.'+k]: v})
            .then(upd => {
                setConfig({
                    ...config,
                    [k]: v
                })
            })
    }

    return  <div>
                <div>
                    <h3>Дистрибуция:</h3>
                    <h3>Пользователи и роли</h3>
                    {globalStaffs.map(s => (
                        <Card elevation={2} style={{marginBottom: 12}}>
                            <h4 style={{margin: 0}}>{s.first_name} {s.last_name}</h4>
                            <ControlGroup style={{marginTop: 12}} vertical={true}>
                                {Object.keys(rolesModel).map(key => (
                                    <Checkbox checked={s.roles[key]} label={rolesLabels[key]} onChange={() => toggleRole(s._id, key, !s.roles[key])} />
                                ))}
                            </ControlGroup>
                        </Card>
                    ))}
                    <h3>Настройки</h3>
                    <div>
                        <Checkbox label='Возвращать графику отправителю протокола' checked={config.respondMatchSummaryToSender} onChange={() => patchConfig('respondMatchSummaryToSender', !config.respondMatchSummaryToSender)} />
                    </div>
                </div>
            </div>
}

export default DivisionSettings
