import React from 'react'

const Splash = ({ active, status }) => {
    return  <div className={'splash'+(active ? ' _active' : '')}>
                <div className='brand'>
                    <img src={status && status !== 'brand' ? require('../../assets/states/'+status+'.gif').default : require('../../assets/brand.png').default} />
                </div>
            </div>
}

export default Splash
