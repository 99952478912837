const _ = {}

const reduceSquad = (squad=[]) => {
    let up = 1
    return squad.reduce((acc, col) => {
        for(let p of col) {
            const num = !isNaN(parseInt(p.num)) ? p.num : 200+up
            if(num > 200) { up++ }
            acc[num.toString()] = p
        }

        return acc
    }, {})
}

export const collectData = input => {
    const data = {
        events: {
            home: input.homeEvsResponse,
            away: input.awayEvsResponse,
        },
        scores: input.scores,
        homeSquad: reduceSquad(input.homeSquadResponse),
        awaySquad: reduceSquad(input.awaySquadResponse),
        mvp: input.mvp,
    }
    if (input.homeSchema || input.awaySchema) {
        data.homeSchema = input.homeSchema || null;
        data.awaySchema = input.awaySchema || null;
    }
    return data
}

export const splitSquad = input => {
    const arr = Object.keys(input).reduce((acc, num) => {
        acc.push(input[num])
        return acc
    }, [])

    const sorted = arr.sort((a, b) => {
        return a.name && b.name ? a.name.trim() > b.name.trim() ? 1 : b.name.trim() > a.name.trim() ? -1 : 0 : 0
    })

    const edge = Math.ceil(arr.length/3)
    const columned = sorted.reduce((acc, p, idx) => {
        if(idx === 0 || idx%edge === 0) { acc.push([]) }
        acc[acc.length-1].push(p)
        return acc
    }, [])

    return columned
}
