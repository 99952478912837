import { React, useState, useEffect } from 'react'

import { Tabs, Tab, Button, Spinner, Card, Icon, Overlay } from '@blueprintjs/core'
import Form from '../Forms'
import NewForm from '../NewForm'
import Season from './Season'
import DivisionSettings from './DivisionSettings'

const rest = require('../rest')

const Division = ({ resetNestedItem, data, putChanges }) => {
    const [tab, setTab] = useState('data')
    const [formData, setFormData] = useState(null)
    const [seasons, setSeasons] = useState(null)
    const [newForm, setNewForm] = useState(null)
    const [activeSeason, setActiveSeason] = useState(null)

    useEffect(() => {
        const fetchSeasons = () => {
            rest('get', 'seasons?filter[divisionId]='+data._id)
                .then(seasons => {
                    setSeasons(seasons.reverse())
                })
        }

        fetchSeasons()
    }, [])

    const createSeason = seasonData => {
        setNewForm(null)
        rest('post', 'seasons', null, {...seasonData, divisionId: data._id})
            .then(created => {
                if(created._id) {
                    setSeasons([created].concat(seasons))
                    setActiveSeason(created._id)
                    setTab('data')
                }
            })
    }

    return  <div>
                <Tabs selectedTabId={tab} onChange={t => {setTab(t)}} renderActiveTabPanelOnly={true}>
                    <Tab id='settings' title='Settings & Seasons' panel={
                        <div style={{display: 'flex', width: 'calc(100vw - 600px)'}}>
                            <div style={{width: '30%'}}>
                                <Form
                                    throwForm={data => setFormData(data)}
                                    collection='divisions'
                                    initial={data}
                                />
                                <div style={{paddingLeft: 12}}>
                                    <Button intent='success' text='Сохранить изменения' onClick={() => putChanges(formData)} />
                                </div>
                                <div className='panels-list'>
                                    <div className='panels-list_title'>Сезоны</div>
                                    {!seasons ? <Spinner /> : seasons.map(s => (
                                        <Card interactive={true} elevation={2} onClick={() => {setActiveSeason(s._id); setTab('data')}}>{s.name}</Card>
                                    )).concat([
                                        <Card interactive={true} elevation={2} onClick={() => setNewForm('seasons')}><Icon icon='add' /></Card>
                                    ])}
                                </div>
                            </div>

                            <div style={{witdh: '67%', marginLeft: '3%'}}>
                                <DivisionSettings
                                    data={data}
                                />
                            </div>
                        </div>
                    } />
                    <Tab id='data' title='Data' panel={
                        <Season
                            activeSeason={activeSeason}
                            leagueData={data.leagueData}
                            divisionRemote={data.remote}
                            resetActiveSeason={() => {setActiveSeason(null); setTab('settings')}}
                        />
                    } />
                </Tabs>

                <Overlay isOpen={newForm ? true : false} canOutsideClickClose={true} onClose={() => setNewForm(null)}>
                    <NewForm collection='seasons' postEntry={createSeason} />
                </Overlay>
            </div>
}

export default Division
