import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Card, NonIdealState, Spinner, Button, Tag } from '@blueprintjs/core'
import CustomScrollbars from 'react-custom-scrollbars-2'
import Refinement from './Refinement'

import { addTypes, sideTypes } from './utils'
import './dialog.css'

const INTERVAL = 5000
const rest = require('../Workspace/rest')

const Item = ({ data, handleReply }) => {
    const type = addTypes.find(t => t.type === data.type)

    return  type ? (
                <div className={'dialog-item'+(data.applied ? ' _applied' : '')}>
                    <div className='dialog-item_top'>{type.label}</div>
                    <div className='dialog-item_details'>
                        {data.side ? <div>{sideTypes.find(s => s.side === data.side).label}</div> : null}
                        {data.num ? <div>Номер игрока: <b>{data.num}</b></div> : null}
                        {data.minute ? <div>Минута события: <b>{data.minute}</b></div> : null}
                        {data.scores ? <div>Счёт в протоколе: <b>{data.scores.origin.home}:{data.scores.origin.away}</b>, Счёт по событиям: <b>{data.scores.fact.home}:{data.scores.fact.away}</b></div> : null}
                        {data.custom ? <div>{data.custom}</div> : null}
                    </div>
                    {data.reply ? (
                        <div className='dialog-item_reply'>
                            <div className='dialog-item_reply_actions'>
                                {!data.applied ? [
                                    <Button minimal={true} icon='endorsed' text='Принять' intent='success' onClick={() => handleReply(true)}/>,
                                    <Button minimal={true} icon='cross' text='Игнор' intent='warning' onClick={() => handleReply()}/>
                                ] : null}
                            </div>
                            <div className='dialog-item_reply_content'>
                                <div style={{fontSize: 13}}><b>Ответ организатора:</b></div>
                                <div>{data.reply}</div>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null
}

const MatchDialog = ({ toaster }) => {
    const [loading, setLoading] = useState(false)
    const [entry, setEntry] = useState(null)
    const [slidden, setSlidden] = useState(true)
    const { matchId } = useParams()

    const loadChat = () => {
        setLoading(true)
        rest('get', 'matchrefines', matchId)
            .then(refine => {
                const _entry = refine && refine.list ? refine.list.find(e => e.matchId === matchId) : null
                if(_entry) {
                    setEntry(_entry)
                }
                setTimeout(() => {
                    setLoading(false)
                }, 1500)
            })
    }

    useEffect(() => {
        loadChat()
    }, [])

    useEffect(() => {
        if(!loading) {
            const interval = setInterval(() => {
                loadChat()
            }, INTERVAL)

            return () => {
                clearInterval(interval)
            }
        }
    }, [loading])

    const handleReply = (idx, applied) => {
        setEntry({
            ...entry,
            items: entry.items.map((i, _idx) => ({
                ...i,
                applied: applied && (idx === _idx) ? true : i.applied,
                reply: !applied && (idx === _idx) ? null : i.reply
            }))
        })

        const body = applied ? {idx: idx, applied: true} : {idx: idx, reply: null}
        rest('put', 'matchrefines', entry.matchId, body)
    }

    const unhandled = entry && entry.items ? entry.items.filter(i => i.reply && !i.applied).length : 0
    const unreplied = entry && entry.items ? entry.items.filter(i => !i.reply).length : 0

    return  <div className={'match-dialog'+(slidden ? ' slidden' : '')} onClick={() => {
                if(slidden) { setSlidden(false) }
            }}>
                {!slidden ? (
                    <Button text='Свернуть' icon='chevron-down' minimal={true} style={{position: 'absolute', top: -40, borderRadius: 9}} onClick={() => setSlidden(true)} />
                ) : null}

                <Refinement matchId={matchId} toaster={toaster} />

                <Card elevation={2} style={{marginTop: 14}}>
                    <div className='title'>
                        Матч-чат
                        {unhandled ? (<Tag intent='danger'>{unhandled}</Tag>) : unreplied ? <Tag intent='warning' minimal={true}>ждём ответа</Tag> : null}
                        {loading ? (
                            <Spinner size={15} intent='primary' />
                        ) : null}
                    </div>
                    <div style={{height: 'calc(100vh - 450px)', boxSizing: 'border-box'}}>
                        <CustomScrollbars autoHeight autoHeightMax='calc(100vh - 450px)'>
                        {entry && entry.items && entry.items.length > 0 ? (
                            <div className='dialog-body'>
                                {entry.items.map((i, idx) => (
                                    <Item
                                        key={idx}
                                        data={i}
                                        handleReply={(applied=false) => handleReply(idx, applied)}
                                    />
                                ))}
                            </div>
                        ) : (
                            <NonIdealState
                                icon='history'
                                description='нет уточнений к матчу'
                            />
                        )}
                        </CustomScrollbars>
                    </div>
                </Card>
            </div>
}

export default MatchDialog
