import { React, useState, useEffect } from 'react'
import { Spinner, Card, Icon, Overlay, Classes, Popover, RadioGroup, Tag, Radio, Button, InputGroup, Tabs, Tab } from '@blueprintjs/core'
import Stage from './Stage'
import Dialog from '../Dialogs'
const rest = require('../rest')

const preserveArrays = data => {
    const arrs = ['clubs', 'matchdays']
    for(let a of arrs) {
        if(!data[a]) { data[a] = [] }
    }
    return data
}

const ParseClubPopover = ({ parseClub }) => {
    const [source, setSource] = useState(null)
    const [remoteId, setRemoteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const available = [
        {key: 'lfl', label: 'Сайт LFL-Россия'},
        {key: 'goalstream', label: 'Goalstream.org'},
        {key: 'ole', label: 'Olesports'},
        {key: 'joinsport', label: 'Join Sport'},
        {key: 'lflworld', label: 'Франшиза ЛФЛ'}
    ]

    const handle = () => {
        setLoading(true)
        parseClub(source, remoteId)
        setSource(null)
        setRemoteId(null)
    }

    return  <div className='popover-body'>
                <RadioGroup
                    label='Источник'
                    onChange={({target}) => setSource(target.value)}
                    selectedValue={source}
                >
                    {available.map(r => (
                        <Radio label={r.label} value={r.key} />
                    ))}
                </RadioGroup>
                <InputGroup
                    placeholder='REMOTE CLUB ID'
                    onChange={({target}) => setRemoteId(target.value)}
                />
                <Button loading={loading} onClick={() => handle()} fill={true} text={!loading ? 'Спарсить' : ''} disabled={!source || !remoteId || remoteId === ''} style={{marginTop: 4}} />
            </div>
}

const RemoteClubBtn = ({ club, season }) => {
    let text, link
    switch(club.remote.source) {
        case 'goalstream':
            link = `http://www.goalstream.org${club.remote.url}#/players?season=${season.id}`
            text = 'goalstream'
            break
        case 'lfl':
            link = `https://lfl.ru/club${club.remote.id}`
            text = 'lfl.ru'
            break
        case 'joinsport':
            link = `${season.domain}tournament/${season.id}/teams/application?team_id=${club.remote.id}`
            text = season.domain ? season.domain.split('/')[2] : 'joinsport'
            break
    }

    return <Button style={{marginTop: 5}} text={text} onClick={() => window.open(link)} small={true} icon='share' minimal={true} intent='primary' />
}

const PanelsList = ({ type, data, title, addAction, selectAction, parseClub, remote, parseOleSeason, groups }) => {
    const draftedClubs = type === 'clubs' && groups && groups.length > 0 ? groups.reduce((acc, g) => {
        acc = acc.concat(g.clubs)
        return acc
    }, []) : null

    const _data = draftedClubs ? data.filter(cl => !draftedClubs.includes(cl._id)) : Object.assign([], data)

    return  [
        <div className='panels-list_title'>{title}:</div>,
        <div className='panels-list season-clubs'>
                {_data.map(s => (
                    <Card interactive={true} elevation={2} onClick={() => selectAction(s._id)}>
                        <span>{s.name} {s.remote ? ' '+s.remote.id : ''}</span>
                        {s.remote ? <RemoteClubBtn club={s} season={remote} /> : null}
                    </Card>
                )).concat([
                    type !== 'clubs' ? <Card interactive={true} elevation={2} onClick={() => addAction()}><Icon intent='success' icon='add' /></Card> : null,
                    type === 'clubs' ?
                    <Popover
                        content={<ParseClubPopover parseClub={parseClub} />}
                    >
                        <Card interactive={true} elevation={2}>
                            <Icon icon='satellite' />
                            <Tag intent='success' minimal={true} style={{marginTop: 8}}>импортировать</Tag>
                        </Card>
                    </Popover> : null,
                    type === 'clubs' && remote && remote.source === 'ole' ?
                        <Card interactive={true} elevation={2} onClick={() => parseOleSeason()}>OLE Batch</Card>
                    : null
                ])}
            </div>
        ]
}

const Season = ({ activeSeason, resetActiveSeason, leagueData, divisionRemote }) => {
    const [data, setData] = useState(null)
    const [dialog, setDialog] = useState(null)
    const [activeClub, setActiveClub] = useState(null)
    const [activeMatchday, setActiveMatchday] = useState(null)
    const [newStage, setNewStage] = useState({name: '', type: 'groups'})
    const [activeStage, setActiveStage] = useState(null)

    useEffect(() => {
        const fetchAggregatedSeason = () => {
            rest('get', 'seasons', activeSeason+'?aggregate=true')
                .then(resp => {
                    setData(preserveArrays(resp))
                })
        }

        if(!activeSeason) {
            resetActiveSeason()
        } else {
            fetchAggregatedSeason()
        }
    }, [])

    const parseClub = (source, remoteId) => {
        rest('put', 'parsers', `${source}/club?seasonId=${activeSeason}&remoteClubId=${remoteId}&remoteSeasonId=${data.remote.id}`)
            .then(response => {
                if(response.club && response.club._id) {
                    setData({...data, clubs: Object.assign([], data.clubs).concat([response.club])})
                } else {
                    alert(JSON.stringify(response))
                }
            })
    }

    const createStage = () => {
        rest('post', 'stages', null, {
            ...newStage,
            seasonId: data._id
        }).then(created => {
            if(created._id) {
                setData({
                    ...data,
                    stages: data.stages.concat([created])
                })
            }
        })
    }

    const SplitToStages = () => {
        return  <div style={{width: 260, padding: 12}}>
                    <InputGroup style={{marginBottom: 10}} value={newStage.name} onChange={e => setNewStage({...newStage, name: e.target.value})} large={true} fill={true} />
                    <RadioGroup onChange={e => setNewStage({...newStage, type: e.target.value})} selectedValue={newStage.type} inline={true}>
                        <Radio value='groups' label='Группы' />
                        <Radio value='playoff' label='Плей-офф' />
                    </RadioGroup>
                    <Button text='Создать стадию' intent='success' fill={true} onClick={() => createStage()} />
                </div>
    }

    const lists = [
        {
            type: 'clubs',
            title: 'Участники',
            addAction: () => {
                setDialog('assignClubToSeason')
            },
            selectAction: id => {
                setActiveClub(id)
                setDialog('seasonSquadDialog')
            }
        },
        {
            type: 'matchdays',
            title: 'Игровые дни',
            addAction: () => {
                setDialog('matchday')
            },
            selectAction: id => {
                setActiveMatchday(id)
                setDialog('matchday')
            }
        }
    ]

    const draftedClubs = data && data.groups && data.groups.length > 0 ? data.groups.reduce((acc, g) => {
        acc = acc.concat(g.clubs)
        return acc
    }, []) : []

    const remoteSeasonBtn = () => {
        let link
        if(data.remote) {
            switch (data.remote.source) {
                case 'goalstream':
                    link = `http://www.goalstream.org/season/${data.remote.id}/`
                    break
                case 'joinsport':
                    link = `${leagueData.credentials.domain}tournament/${data.remote.id}`
                    break
                case 'lfl':
                    link = `https://lfl.ru/division${divisionRemote.id}`
                    break
            }
        }

        return  <Button intent='primary' text='Открыть сезон на источнике' onClick={() => window.open(link)} />
    }

    return  <div>
                {!data ? <Spinner /> : [
                    data.stages.length === 0 ? (
                        <div style={{display: 'flex'}}>
                            <Popover
                                content={(<SplitToStages />)}
                                position='bottom'
                            >
                                <Button text='Разбить сезон на стадии' style={{marginRight: 10}} />
                            </Popover>
                            {remoteSeasonBtn()}
                        </div>
                    ) : remoteSeasonBtn(),
                    lists.map(({type, title, addAction, selectAction}) => (
                        <PanelsList
                            type={type}
                            data={data[type]}
                            groups={data.groups}
                            title={title}
                            addAction={addAction}
                            selectAction={selectAction}
                            parseClub={parseClub}
                            remote={{...data.remote, domain: leagueData.credentials ? leagueData.credentials.domain : null}}
                        />)
                    ),
                    data.stages.length > 0 ?(
                        <div>
                            <Tabs selectedTabId={activeStage || data.stages[data.stages.length - 1]._id}>
                                {data.stages.map(stage => (
                                    <Tab
                                        id={stage._id}
                                        title={stage.name}
                                        panel={
                                            <Stage
                                                onClubSelected={lists[0].selectAction}
                                                draftedClubs={draftedClubs}
                                                data={stage}
                                                clubs={data.clubs}
                                                groups={stage.type === 'groups' ? data.groups.filter(g => g.stageId === stage._id) : null}
                                                onGroupCreated={group => setData({...data, groups: data.groups.concat([group])})}
                                                onGroupUpdated={group => setData({...data, groups: data.groups.map(g => g._id === group._id ? group : g)})}
                                            />
                                        }
                                    />
                                ))}
                            </Tabs>
                        </div>
                    ) : null
                ].filter(n => n)}

                <Overlay isOpen={dialog ? true : false} canOutsideClickClose={true} onClose={() => {setDialog(null); setActiveClub(null)}} className={Classes.OVERLAY_SCROLL_CONTAINER}>
                    <Dialog
                        type={dialog}
                        clubId={activeClub}
                        seasonId={activeSeason}
                        assignedClubToSeason={club => {
                            setDialog(null)
                            rest('put', 'seasons', activeSeason, {clubs: [club].concat(data.clubs).map(c => c._id)})
                            setData({
                                ...data,
                                clubs: [club].concat(data.clubs)
                            })
                        }}
                        activeMatchday={activeMatchday}
                        onCreatedMatchday={md => {
                            setActiveMatchday(md._id)
                            setData({
                                ...data,
                                matchdays: [md].concat(data.matchdays)
                            })
                        }}
                        participants={data ? data.clubs : []}
                        remoteSeasonId={data ? data.remote.id : null}
                        remoteSeasonSource={data ? data.remote.source : null}
                        remoteLeagueId={leagueData ? leagueData.remoteId : null}
                        leagueId={leagueData ? leagueData._id : null}
                    />
                </Overlay>
            </div>
}

export default Season
