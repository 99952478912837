import React, { useState, useEffect } from 'react'
import { Overlay, Card, RadioGroup, Radio, InputGroup, Checkbox, Button } from '@blueprintjs/core'
import { useHistory } from 'react-router-dom'
import Select, { createFilter } from 'react-select'
const rest = require('../Workspace/rest')

const ROLES = {
    league: [
        {value: 'owner', label: 'Владелец'},
        {value: 'admin', label: 'Администратор'},
        {value: 'media', label: 'Фото / Видео'},
        {value: 'referee', label: 'Арбитр'}
    ],
    club: [
        {value: 'coach', label: 'Тренер'},
        {value: 'admin', label: 'Администратор'},
        {value: 'finances', label: 'Финансы'}
    ]
}

const renderInvite = () => {
    return (Math.floor(Math.random() * 90000) + 10000).toString()
}

const Create = ({ active }) => {
    const [type, setType] = useState(null)
    const [targets, setTargets] = useState([])
    const [selected, setSelected] = useState(null)
    const [person, setPerson] = useState(null)
    const [roles, setRoles] = useState([])
    const [progress, setProgress] = useState(false)
    const [ready, setReady] = useState(null)

    const history = useHistory()

    useEffect(() => {
        rest('get', type+'s')
            .then(options => {
                setTargets(options)
                setRoles([])
                setSelected(null)
            })
    }, [type])

    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: 'any'
    }

    const createStaff = () => {
        const code = renderInvite()
        const body = {
            first_name: person.split(' ')[0],
            last_name: person.split(' ')[1],
            roles: roles.map(r => (
                {
                    [type+'Id']: selected,
                    role: r
                }
            )),
            invite: code,
            tid: null,
            created: new Date().getTime()
        }

        setProgress(true)
        rest('post', 'staffs', null, body)
            .then(created => {
                setReady(code)
            })
    }

    return  <Overlay isOpen={active} style={{position: 'relative'}} canOutsideClickClose={true} onClose={() => history.push('/chats')} hasBackdrop={true}>
                {!ready ? <Card elevation={3} className='bp3-overlay-content' style={{position: 'absolute', margin: '200px calc(50% - 300px)', width: 600}}>
                    <div style={{marginBottom: 10}}>Новый элемент Staffs</div>
                    <div style={{marginBottom: 10}}>
                        <InputGroup value={person} onChange={e => setPerson(e.target.value)} large={true} placeholder='Имя Фамилия'/>
                    </div>
                    <div>
                        <RadioGroup label='Выбери тип' selectedValue={type} onChange={e => setType(e.target.value)}>
                            <Radio label='Предаставитель лиги' value='league' />
                            <Radio label='Представитель клуба' value='club' />
                        </RadioGroup>
                    </div>

                    <div style={{marginTop: 12}}>
                        {type === 'league' ? targets.map(l => (
                            <Card
                                elevation={1}
                                style={{display: 'flex', padding: 12, alignItems: 'center', marginTop: 6, background: selected === l._id ? '#2196F3' : '#fff'}}
                                interactive={true}
                                onClick={() => setSelected(l._id)}
                            >
                                <img style={{width: 40, height: 40}} src={l.emblem} />
                                <div style={{marginLeft: 20}}>{l.name}</div>
                            </Card>
                        )) : type === 'club' ? <div style={{minHeight: 50}}>
                            <Select
                                options={targets.map(t => ({value: t._id, label: t.name+(t.from && t.from.city ? ` (${t.from.city})` : '')}))}
                                filterOption={createFilter(filterConfig)}
                                onChange={(item) => setSelected(item.value)}
                            />
                        </div> : null}
                    </div>

                    {type ? ([
                        <div style={{marginTop: 20}}>
                            {ROLES[type].map(r => (
                                <Checkbox
                                    label={r.label}
                                    value={r.value}
                                    inline={true}
                                    checked={roles.indexOf(r.value) > -1}
                                    onChange={e => e.target.checked ? setRoles(roles.concat([r.value])) : setRoles(roles.filter(_r => _r !== r.value))}
                                />
                            ))}
                        </div>,
                        <div style={{marginTop: 10}}>
                            <Button fill={true} text='Готово, сохранить' intent='primary' large={true} disabled={progress || !person || !selected || roles.length == 0} onClick={() => createStaff()} />
                        </div>]
                    ) : null}
                </Card> : <Card elevation={3} className='bp3-overlay-content' style={{position: 'absolute', margin: '200px calc(50% - 300px)', width: 600}}>
                    <div style={{textAlign: 'center'}}>Экземпляр успешно создан. Регистрационный код:</div>
                    <div style={{padding: 40, textAlign: 'center', fontSize: 60, fontWeight: 800}}>{ready}</div>
                </Card>}
            </Overlay>
}

export default Create
