import { React, useState, useEffect } from 'react'
import { Suggest } from '@blueprintjs/select'
import { Button } from '@blueprintjs/core'
import Form from '../Forms'

const rest = require('../rest')

const AssignClubToSeason = ({ assignedClubToSeason }) => {
    const [list, setList] = useState(null)
    const [formData, setFormData] = useState(false)

    useEffect(() => {
        const fetchList = async () => {
            const list = await rest('get', 'clubs')
            setList(list)
        }

        fetchList()
    }, [])

    const createClub = () => {
        rest('post', 'clubs', null, formData)
            .then(resp => {
                if(resp._id) {
                    assignedClubToSeason(resp)
                }
            })
    }

    return  <div className='dialog'>
                <div>
                    <div style={{padding: 12, fontWeight: 700}}>Добавить новый клуб</div>
                    <Form
                        collection='clubs'
                        throwForm={data => setFormData(data)}
                    />
                    <div className='overlay-form_actions'>
                        <Button text='Создать команду' intent='success' onClick={() => createClub()} />
                    </div>
                </div>
                <div className='dialog-body'>
                    {list ? <Suggest
                        fill={true}
                        items={list}
                        itemRenderer={i => {
                            return <div className='dialog-popover_item' onClick={() => assignedClubToSeason(i)}>{i.name}</div>
                        }}
                        popoverProps={{minimal: true}}
                        inputProps={{
                            large: true,
                            placeholder: '...или выбрать существующий'
                        }}
                    /> : 'Загрузка...'}
                </div>
            </div>
}

export default AssignClubToSeason
