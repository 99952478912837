module.exports = {
    assignClubs: (src, leagueId) => {
        const divisions = src.divisions.filter(d => d.leagueId === leagueId).map(d => d._id)
        const ids = src.seasons.filter(s => divisions.indexOf(s.divisionId) > -1).reduce((acc, s) => {
            acc = acc.concat(s.clubs)
            return acc
        }, [])

        return src.clubs.filter(c => ids.indexOf(c._id) > -1)
    }
}
