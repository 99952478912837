import React, { useState } from 'react'
import { Card, Button } from '@blueprintjs/core'

const PlayersList = ({ data, onBack, onSelected }) => {
    return  <div className='list-view'>
                <div className='title'><Button text='Назад' minimal={true} onClick={() => onBack()} icon='chevron-left' intent='primary' />Выберите игрока:</div>
                {data ? data.map(p => (
                    <Card elevation={2} onClick={() => onSelected(p._id)}>
                        <div className='list-item_img' style={{backgroundImage: `url(${p.image || require('../assets/color/player.svg').default})`}}></div>
                        <div className='list-item_main'>{p.name}</div>
                    </Card>
                )) : null}
            </div>
}

export default PlayersList
