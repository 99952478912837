import { React, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { NonIdealState, Spinner, Button, Overlay, Popover } from '@blueprintjs/core'
import Body from './Bodies'
import ListItem from './ListItem'
import NewForm from './NewForm'
import Dialog from './Dialogs'

import './style.css'

const rest = require('./rest')

const Workspace = ({ override, leagueData }) => {
    const [items, setItems] = useState(null)
    const [newForm, setNewForm] = useState(null)
    const [dialog, setDialog] = useState(null)
    const [nestedItem, setNestedItem] = useState(null)
    const [references, setReferences] = useState([])

    const locParams = useParams()
    const overrideParams = () => {
        return {collection: override, itemId: locParams.itemId}
    }

    const { collection, itemId } = override ? overrideParams() : locParams
    const history = useHistory()

    useEffect(() => {
        const fetchReferences = async () => {
            const refs = await rest('get', 'references')
            setReferences(refs.map(({ seasons, ...rest }) => {
                return {
                    ...rest,
                    divisions: rest.divisions.map(div => ({
                        ...div,
                        seasons: seasons.filter(s => s.divisionId === div._id),
                        leagueData: leagueData || {}
                    }))
                }
            }))
        }

        if(['users'].indexOf(locParams.collection) > -1) {
            fetchReferences()
        }
    }, [])

    useEffect(() => {
        const renderQuery = () => {
            if(!override) {
                return ''
            } else {
                switch(override) {
                    case 'users':
                        return `?scopes[${itemId}]=true`
                        break
                    case 'divisions':
                        return `?filter[leagueId]=${itemId}`
                        break
                }
            }
        }

        const fetchItems = async() => {
            const list = await rest('get', collection+renderQuery())
            setItems(list.map(i => !leagueData ? i : ({...i, leagueData: leagueData})))
        }

        fetchItems()
    }, [locParams])

    const postEntry = data => {
        const addRelations = data => {
            if(override) {
                switch(collection) {
                    case 'divisions':
                        return {...data, leagueId: itemId}
                        break
                    case 'users':
                        return {...data, scopes: {[itemId]: 'initial'}}
                        break
                    default:
                        return data
                }
            } else {
                return data
            }
        }

        if(data) {
            rest('post', collection, null, addRelations(data))
                .then(resp => {
                    if(resp && resp._id) {
                        setNewForm(null)
                        setItems([resp].concat(items))
                        if(!override) { history.push(`/${collection}/${resp._id}`) }
                    } else {
                        console.log(JSON.parse(resp))
                    }
                })
        }
    }

    const putChanges = data => {
        rest('put', collection, override ? nestedItem : itemId, data)
            .then(resp => {
                if(resp._id) {
                    setItems(items.map(i => i._id === (override ? nestedItem : itemId) ? resp : i))
                }
            })
    }

    const item = items && itemId ? items.find(i => i._id === (!override ? itemId : nestedItem)) || null : null
    if(itemId && !item && !override) {
        history.push('/'+collection)
    }

    const renderAddBtn = col => {
        const exceptions = {
            users: () => {
                return  <Popover
                            usePortal={false}
                            content={(
                                <div className='popover-body'>
                                    <Button fill={true} text='Создать нового пользователя' onClick={() => setNewForm('users')} />
                                    <Button style={{marginTop: 6}} fill={true} text='Добавить привелегии' onClick={() => setDialog('assignScopes')} />
                                </div>
                            )}
                            className='workspace-list_add'
                        >
                            <Button icon='add' intent='primary' large={true} outline={true}  />
                        </Popover>
            }
        }

        const Specified = exceptions[col] || null
        return Specified ? <Specified /> : <Button icon='add' intent='primary' large={true} outline={true} className='workspace-list_add' onClick={() => setNewForm(collection)} />
    }

    return  <div className={'workspace'+(override ? ' nested' : '')}>
                <div className='workspace-list'>
                    {items ? items.map(i => (
                        <ListItem
                            key={i._id}
                            type={collection}
                            data={i}
                            override={override}
                            nestedItem={nestedItem}
                            paramsItemId={itemId}
                            setNestedItem={id => setNestedItem(id)}
                        />
                    )) : <NonIdealState description='загрузка' action={<Spinner />} />}
                    {renderAddBtn(collection)}
                </div>

                <div className='workspace-body'>
                    {item ? <Body key={item._id} data={item} references={references} leagueId={locParams.itemId} type={collection} putChanges={putChanges} resetNestedItem={() => setNestedItem(null)}/> : null}
                </div>

                <Overlay isOpen={newForm ? true : false} canOutsideClickClose={true} onClose={() => setNewForm(null)}>
                    <NewForm collection={collection} postEntry={postEntry} />
                </Overlay>

                <Overlay isOpen={dialog ? true : false} canOutsideClickClose={true} onClose={() => setDialog(null)}>
                    <Dialog
                        type={dialog}
                        addScopedUser={u => {
                            setDialog(null)
                            setNestedItem(u._id)
                            setItems([u].concat(Object.assign([], items)))
                        }}
                    />
                </Overlay>
            </div>
}

export default Workspace
