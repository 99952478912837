import { React, useState } from 'react'
import { Tabs, Tab, Button } from '@blueprintjs/core'
import Workspace from '../../Workspace'
import Form from '../Forms'

const League = ({ data, putChanges, resetNestedItem }) => {
    const [formData, setFormData] = useState(null)
    const [tab, setTab] = useState('divisions')

    return  <div>
                <Tabs selectedTabId={tab} onChange={t => {setTab(t); resetNestedItem()}} renderActiveTabPanelOnly={true}>
                    <Tab id='settings' title='General' panel={[
                        <Form collection='leagues' initial={data} throwForm={data => setFormData(data)} />,
                        <div>
                            <Button intent='success' text='Сохранить изменения' onClick={() => putChanges(formData)} />
                        </div>
                    ]} />
                    <Tab id='users' title='Users & Roles' panel={
                        <Workspace override='users' />
                    }/>
                    <Tab id='divisions' title='Divisions' panel={
                        <Workspace override='divisions' leagueData={data} />
                    }/>
                </Tabs>
            </div>
}

export default League
