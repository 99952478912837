import React, { useState, useEffect } from 'react'
import { Card, Button, NonIdealState, InputGroup, Switch, Tag } from '@blueprintjs/core'
import axios from 'axios'

const { REST } = require('../../../env')

const _roles = [
    {value: 'owner', label: 'Владелец'},
    {value: 'admin', label: 'Администратор'},
    {value: 'media', label: 'Фото / Видео'},
    {value: 'referee', label: 'Арбитр'}
]

const clubRoles = [
    {value: 'coach', label: 'Тренер'},
    {value: 'admin', label: 'Администратор'},
    {value: 'finances', label: 'Финансы'}
]

const ListFooter = ({ confirm, cancel }) => {
    return  <div className='list-footer'>
                {confirm ? <Button {...confirm} fill={true} /> : null}
            </div>
}

const StaffList = ({ data, createPerson, inviteCode, resetInviteCode, clubId, leagueId, setTitle }) => {
    const [form, setForm] = useState(null)
    const [progress, setProgress] = useState(false)

    useEffect(() => {
        if(setTitle) {
            setTitle({
                string: 'Управление сотрудниками',
                onBack: 'resetMode'
            })
        }
    }, [])

    const addCtaSubj = clubId ? 'представителя' : 'сотрудника'
    const roles = clubId ? clubRoles : _roles

    const confirm = {
        intent: form || inviteCode ? 'success' : 'primary',
        text: form ? 'Сохранить '+addCtaSubj : inviteCode ? 'Ок, всё понятно!' : 'Добавить '+addCtaSubj
    }

    if(form) {
        confirm.onClick = () => {
            createPerson(form)
            setForm(null)
        }
    } else if (inviteCode) {
        confirm.onClick = () => resetInviteCode()
    } else {
        confirm.onClick = () => setForm({first_name: '', last_name: '', roles: []})
    }

    const isLeagueSubj = (leagueId && !clubId)

    const downloadClubManual = () => {
        const created = data.length > 0 ? data[data.length-1] : null
        if(created) {
            setProgress(true)
            axios.put(`${REST}utils`, {
                action: 'downloadClubManual',
                staffsId: created._id,
                leagueId: leagueId
            }, {responseType: 'arraybuffer', headers: {'Accept': 'application/pdf'}})
                .then(response => {
                    if(!response.error) {
                        const blob = new Blob([response.data], {type: 'application/pdf'})
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `Amateum_Start_${created.first_name}_${created.last_name}.pdf`
                        link.click()
                        setProgress(false)
                    }
                })
        }
    }

    return  <div className='screen list'>
                {data.map(person => (
                    <Card className='list-item' elevation={2}>
                        <div className='list-item_avatar'>
                            <div>{person.first_name.charAt(0)+(person.last_name ? person.last_name.charAt(0) : '')}</div>
                        </div>
                        <div className='list-item_body'>
                            <div>
                                <div>{person.first_name+' '+(person.last_name || '')}</div>
                                <div>{person.roles
                                    .filter(r => isLeagueSubj ? leagueId === r.leagueId : r)
                                    .map(r => (<Tag style={{marginRight: 4}} minimal={true} intent='primary'>{r.role}</Tag>))
                                }</div>
                            </div>

                            {person.invite ? (
                                <div style={{textAlign: 'right'}}>
                                    <Tag minimal={true} intent='danger'>в процессе</Tag>
                                    <div>{person.invite}</div>
                                </div>
                            ) : null}
                        </div>
                    </Card>
                ))}
                {form ? (
                    <div className='panel-modal'>
                        <div className='title'>Новый сотрудник</div>
                        <InputGroup placeholder='Имя' name='first_name' large={true} value={form.first_name} onChange={e => setForm({...form, first_name: e.target.value})} />
                        <InputGroup placeholder='Фамилия' name='last_name' large={true} value={form.last_name} onChange={e => setForm({...form, last_name: e.target.value})} />
                        <div className='switches-group'>
                            {roles.map(r => (
                                <Switch
                                    onChange={({target}) => setForm({...form, roles: target.checked ? form.roles.concat([target.name]) : form.roles.filter(_r => _r !== r.value)})}
                                    checked={form.roles.indexOf(r.value) > -1}
                                    name={r.value}
                                    label={r.label}
                                    large={true}
                                />
                            ))}
                        </div>
                    </div>
                ) : inviteCode ? (
                    <div className='panel-modal'>
                        <div className='title'>{clubId ? 'Представитель' : 'Сотрудник'} успешно создан. Передайте ему этот код. Он понадобится при первом подключении к @amateum_bot:</div>
                        <div className='code'>{inviteCode}</div>
                        {clubId ? (
                            <div style={{margin: '30px 0'}}>
                                <div style={{textAlign: 'center', marginBottom: 18}}>или</div>
                                <Button large={true} loading={progress} text='Скачайте персональную инструкцию для представителя' intent='primary' fill={true} icon='download' onClick={() => downloadClubManual()}/>
                            </div>
                        ) : null}
                    </div>
                ) : null}
                <ListFooter
                    confirm={confirm}
                />
            </div>
}

export default StaffList
