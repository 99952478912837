import React, { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { Card, Button, Tooltip, Position } from '@blueprintjs/core'
import ReactCrop from 'react-image-crop'
import ModerContext from './moderContext'
import '../../../../node_modules/react-image-crop/dist/ReactCrop.css'

const spaces = 'https://files.amateum.com/sheets/'
const { REST } = require('../../../env')

const Cropper = ({ matchId, path, landscape, setLandscape }) => {
    const [crop, setCrop] = useState({ unit: '%', width: landscape ? 25 : 100, height: landscape ? 30 : 25 , x: landscape ? 75 : 0})
    const [completedCrop, setCompletedCrop] = useState(null)
    const imgRef = useRef(null)
    const previewRef = useRef(null)

    const { setCtx } = useContext(ModerContext)

    const onLoad = useCallback((img) => {
        imgRef.current = img
    }, [])

    const setCropped = () => {
        const decoded = previewRef.current.toDataURL('image/jpeg', 1.0)
        setCtx(path+'Source', decoded)
    }

    useEffect(() => {
        if (!completedCrop || !previewRef.current || !imgRef.current) {
            return
        }

        const image = imgRef.current
        const canvas = previewRef.current
        const crop = completedCrop

        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const ctx = canvas.getContext('2d')
        const pixelRatio = window.devicePixelRatio

        canvas.width = crop.width * pixelRatio * scaleX
        canvas.height = crop.height * pixelRatio * scaleY

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        ctx.imageSmoothingQuality = 'high'

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        )
    }, [completedCrop])

    return  <Card elevation={2} className={'cropper-wrap '+(path.includes('Evs') ? 'evs' : 'sq')} >
                {completedCrop && completedCrop.x && completedCrop.y ? (
                    <div
                        className='cropper-action'
                        style={{left: completedCrop.x + 30, top: completedCrop.y - 40}}
                        onClick={() => setCropped()}
                    >
                        <img src={require('./bot.png').default} />
                        <span>Распознать</span>
                    </div>
                ) : null}
                <ReactCrop
                    key={path+'_crop'}
                    src={landscape ? `${REST}spinner/${matchId}/m90` : `${spaces}${matchId}_origin.jpg`}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                    crossorigin='anonymous'
                />
                <Button className='rotate-btn' icon='reset' minimal={true} large={true} intent='primary' onClick={() => setLandscape(!landscape)} />
                <div>
                    <canvas
                        ref={previewRef}
                        style={{
                            display: 'none',
                            width: Math.round(completedCrop?.width ?? 0),
                            height: Math.round(completedCrop?.height ?? 0)
                        }}
                    />
                </div>

            </Card>
}

export default Cropper
