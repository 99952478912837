import React, { useState, useEffect, useRef } from 'react';

import StatsForm from './StatsForm.js';

const moment = require('moment');

const initialMatch = {
    date: moment().format('YY-MM-DD'),
    time: '14:00',
    homeId: '',
    awayId: ''
}

const initialStat = {
    _id: '',
    matchId: '',
    broadcasterId: '',
    agentId: '',
    price: 0,
    bounty: 0,
    entity: {
        duration: 0,
        offsides: false,
        isProduction: false,
        live: false,
        periods: 2,
        etDuration: 0,
        pureTime: false,
        passesMode: "accuracy",
        freq: 4
    }
}

const Create = ({ bind }) => {
    return <StatsForm initialMatch={initialMatch} initialStat={initialStat}/>
}

export default Create
