import React, { useState, useEffect, useRef } from 'react'
import { io } from 'socket.io-client'
import { useParams, useHistory } from 'react-router-dom'
import List from './List'
import Chat from './Chat'
import Manage from './Manage'
import Create from './Create'
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import {
  MainContainer,
  ChatContainer,
  Sidebar,
  MessageList,
  Message,
  MessageInput,
} from '@chatscope/chat-ui-kit-react'

import './style.css'

const { SOCK } = require('../../env')
let socket

const monitStatus = cb => {
    socket.on('chats', data => {
        cb('chats', data)
    })
}

const compare = (input, data) => {
    return {
        cur: input.reduce((acc, p) => {
            acc += p.data.length
            return acc
        }, 0),
        old: data.reduce((acc, p) => {
            acc += p.data.length
            return acc
        }, 0)
    }
}

const Chats = props => {
    const [data, setData] = useState([])
    const { chatId } = useParams()
    const holder = useRef([])

    useEffect(() => {
        socket = io(SOCK+'/chats')
    }, [])

    useEffect(() => {
        const handleSocketMessage = (target, input) => {
            switch(target) {
                case 'chats':
                    const { cur, old } = compare(input, holder.current)
                    if(cur !== old) {
                        holder.current = input
                        setData(input.map(p => {
                            const relation = p.leagues.concat(p.clubs).slice(0, 1)[0]
                            return p.person ? {
                                ...p,
                                person: {
                                    ...p.person,
                                    avatar: relation && relation.emblem ? relation.emblem : require('../../assets/color/pennant.svg').default
                                }
                            } : {
                                ...p,
                                avatar: relation && relation.emblem ? relation.emblem : require('../../assets/color/pennant.svg').default
                            }
                        }))
                    }
                    break
            }
        }

        monitStatus(handleSocketMessage)
    }, [chatId])

    return  <div style={{position: 'relative', height: '100vh'}}>
                <MainContainer>
                    <Sidebar position='left'>
                        <List list={data} />
                    </Sidebar>
                    {chatId && chatId !== 'new' ? <Chat data={data.find(c => c._id.toString() === chatId.toString())} /> : null}
                    {chatId && chatId !== 'new' ? <Manage data={data.find(c => c._id.toString() === chatId.toString())} /> : null}
                </MainContainer>
                <Create active={(chatId && chatId === 'new')} />
            </div>
}

export default Chats
