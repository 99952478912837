import React, { useState, useEffect, useRef } from 'react'
import { Button, TextArea, FileInput, ControlGroup, Toaster, Position } from '@blueprintjs/core'
import Picker from 'emoji-picker-react'
const rest = require('../Workspace/rest')

const Sender = props => {
    const [staffs, setStaffs] = useState(null)
    const [list, setList] = useState(null)
    const [message, setMessage] = useState(null)
    const [attach, setAttach] = useState(null)
    const toaster = useRef(null)

    useEffect(() => {
        rest('get', 'staffs')
            .then(list => {
                setStaffs(list.filter(s => !s.invite))
            })
    }, [])

    const filterList = segment => {
        switch(segment) {
            case 'leagues_admins':
                setList(Object.assign([], staffs).filter(s => s.roles.find(r => r.leagueId && ['owner', 'admin'].includes(r.role))))
                break
            case 'clubs_admins':
                setList(Object.assign([], staffs).filter(s => s.roles.find(r => r.clubId && ['coach', 'admin'].includes(r.role))))
                break
            default: setList(null)
        }
    }

    const getImgData = () => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.readAsDataURL(attach)
        })
    }

    const doSend = async () => {
        const imgData = attach ? await getImgData() : null
        rest('post', 'sender', null, {
            message: message,
            image: imgData,
            ext: attach ? attach.type.includes('png') ? 'png' : 'jpg' : null,
            receivers: list.map(l => ({
                tid: l.tid,
                name: l.first_name
            }))
        }).then(sent => {
            toaster.current.show({
                intent: sent.success ? 'success' : 'danger',
                message: sent.success ? 'Сообщения отправлены' : 'Что-то пошло не так: '+JSON.stringify(sent)
            })

            if(sent.success) {
                setAttach(null)
                setMessage(null)
                setList(null)
            }
        })
    }

    return staffs ? (
            <div style={{display: 'flex'}}>
                <Toaster position={Position.TOP_LEFT} ref={toaster}></Toaster>

                <div style={{width: 450, borderRight: '1px solid #eee', padding: 20}}>
                    <TextArea
                        value={message}
                        style={{width: '100%', height: 340}}
                        large={true}
                        onChange={e => setMessage(e.target.value)}
                    />
                    <ControlGroup fill={true}>
                        <FileInput
                            text={attach ? attach.name : 'Attach Graphics'}
                            buttonText='Browse'
                            fill={false}
                            onInputChange={e => setAttach(e.target.files[0] || null)}
                        />
                    </ControlGroup>
                    <Button intent='default' large={true} fill={true} text='Отправить' disabled={!list || !message} style={{marginTop: 20}} onClick={() => doSend()} />
                    <Picker onEmojiClick={(e, obj) => setMessage((message || '')+' '+obj.emoji)} />
                </div>
                <div style={{width: 300, display: 'flex', flexDirection: 'column'}}>
                    <Button minimal={true} text='Администраторы турниров' onClick={() => filterList('leagues_admins')} />
                    <Button minimal={true} text='Администраторы команд' onClick={() => filterList('clubs_admins')} />
                    <Button minimal={true} text='Сбросить фильтр' onClick={() => filterList(null)} />
                    {list ? (
                        <div>
                            {list.map(s => (
                                <div>{s.first_name} {s.last_name}</div>
                            ))}
                        </div>
                    ) : 'Выбери сегмент'}
                </div>
            </div>
        ) : 'Loading...'
}

export default Sender
