import React, { useState } from 'react'

import { Card, Button } from '@blueprintjs/core'

import ClubsList from '../../../UI/ClubsList'
import PlayersList from '../../../UI/PlayersList'

const rest = require('../../../components/Workspace/rest')

const Confirm = ({ data, sourceClub, targetClub, onCancel, onConfirm }) => {
    return  <div style={{padding: 12, height: '100vh'}}>
                <Card elevation={2} style={{display: 'flex', height: '100%', flexDirection: 'column'}} className='action-view'>
                    <div className='header'>
                        <div className='action-name'>трансфер игрока:</div>
                        <div className='action-subject'>{data.name}</div>
                    </div>
                    <div className='transfer-view'>
                        <div>
                            <div style={{marginBottom: 10, fontWeight: 500}}>{sourceClub.name}</div>
                            <div>
                                <img src={sourceClub.emblem ? sourceClub.emblem.replace(/img_c_\d+x\d+/, 'img_c_250x250') : require('../../../assets/color/pennant.svg').default} />
                            </div>
                        </div>
                        <div>
                            <img className='player-img' src={data.image ? data.image.replace(/img_c_\d+x\d+/, 'img_c_250x250') : require('../../../assets/color/player.svg').default} />
                        </div>
                        <div>
                            <div>
                                <img src={targetClub.emblem ? targetClub.emblem.replace(/img_c_\d+x\d+/, 'img_c_250x250') : require('../../../assets/color/pennant.svg').default} />
                            </div>
                            <div style={{marginTop: 10, fontWeight: 500}}>{targetClub.name}</div>
                        </div>
                    </div>
                    <div className='action-controls'>
                        <Button intent='default' fill={true} large={true} text='Отправить запрос' onClick={() => onConfirm()} />
                        <Button style={{marginTop: 8}} fill={true} minimal={true} text='Отмена' onClick={() => onCancel()} />
                    </div>
                </Card>
            </div>
}

const Transfer = ({ data, context }) => {
    const [club, setClub] = useState(null)
    const [player, setPlayer] = useState(null)

    const clubItem = data && club ? data.list.find(c => c._id === club) : null

    const createRequest = () => {
        const body = {
            type: 'transfer',
            data: {
                subject: player,
                source: club,
                target: context.clubId,
                author: context.tid,
                season: clubItem.squad.season._id,
                targetSeason: data.self.squad.season._id
            }
        }

        rest('post', 'interactions', null, body)
            .then(resp => {
                setTimeout(() => {
                    setClub(null)
                    setPlayer(null)
                    window.close()
                }, 1000)
            })
    }

    return  <div>{data ? clubItem ? player ? (
                <Confirm
                    data={clubItem.squad.players.find(p => p._id === player)}
                    sourceClub={clubItem}
                    targetClub={data.self}
                    onCancel={() => setPlayer(null)}
                    onConfirm={createRequest}
                />
            ) : (
                <PlayersList
                    data={clubItem.squad.players}
                    onBack={() => setClub(null)}
                    onSelected={pid => setPlayer(pid)}
                />
            ) : (
                <ClubsList
                    onBack={() => window.close()}
                    data={data.list}
                    onSelected={cid => setClub(cid)}
                />
            ) : null}</div>
}

export default Transfer
