import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import {
    ChatContainer,
    ConversationHeader,
    AvatarGroup,
    Avatar,
    MessageList,
    Message,
    MessageInput
} from '@chatscope/chat-ui-kit-react'

const rest = require('../Workspace/rest')

const Chat = ({ data }) => {
    const [input, setInput] = useState(null)
    const history = useHistory()

    const sendMessage = () => {
        rest('put', 'bot', null, {
            action: 'sendPlainMessage',
            target: data._id,
            body: input
        }).then(sent => {
            setInput(null)
        })
    }

    const messages = data ? Object.assign([], data.data) : []

    return  data ? <ChatContainer>
                <ConversationHeader>
                    <ConversationHeader.Back onClick={() => history.push('/chats')} />
                    <Avatar src={data.person ? data.person.avatar : null} />
                    <ConversationHeader.Content userName={data.peson ? data.person.first_name : null} />
                </ConversationHeader>
                <MessageList>
                    {messages.reverse().map(m => (
                        <Message
                            type={m.content && m.content.includes('https') ? 'image' : 'text'}
                            model={{
                                direction: !m.self ? 'incoming' : 'outgoing',
                                payload: m.content && m.content.includes('https') ? {src: m.content, width: 220} : m.content
                            }}
                        >
                            <Avatar src={m.self ? require('../../assets/brand.png').default : data.person ? data.person.avatar : null}/>
                            <Message.Footer sentTime={moment(m.at).format('DD MMM, HH:mm')} />
                        </Message>
                    ))}
                </MessageList>
                <MessageInput value={input} onChange={v => setInput(v)} onSend={() => sendMessage()} />
            </ChatContainer> : null
}

export default Chat
