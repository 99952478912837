import { React } from 'react'
import { useHistory } from 'react-router-dom'
import { Tag, Card } from '@blueprintjs/core'
import 'moment/locale/ru'
const moment = require('moment')
moment.locale('ru')

const icons = {
    association: require('../../assets/icons/association.png').default,
    user: require('../../assets/icons/user.png').default,
    trophy: require('../../assets/icons/trophy.png').default
}

const ImportedTag = ({ pe }) => {
    let intent, text
    switch(true) {
        case !pe || !pe[0]:
            intent = 'none'
            text = 'матчи не запланированы'
            break
        case moment(pe[0].date, 'YY-MM-DD').unix() < moment().startOf('day').unix():
            intent = 'warning'
            text = 'матчи прошли'
            break
        case moment(pe[0].date, 'YY-MM-DD').unix() > moment().add(3, 'days').unix():
            intent = 'success'
            text = 'выгружен до '+moment(pe[0].date, 'YY-MM-DD').format('D MMMM')
            break
        case moment(pe[0].date, 'YY-MM-DD').unix() > moment().startOf('day').add(1, 'days').unix():
            intent = 'primary'
            text = 'выгружен до '+moment(pe[0].date, 'YY-MM-DD').format('D MMMM')
            break
        default:
            intent = 'warning'
            text = 'выгружен до '+moment(pe[0].date, 'YY-MM-DD').format('D MMMM')
    }

    return <Tag minimal={true} intent={intent}>{text}</Tag>
}

const templates = {
    staffs: ({ data }) => {
        return [
            <div className='icon'><img src={data.avatar && data.avatar.length > 0 ? data.avatar : icons.user} /></div>,
            <div>{data.first_name+' '+data.last_name}</div>
        ]
    },
    leagues: ({ data }) => {
        return [
            <div className='icon'><img src={data.emblem && data.emblem.length > 0 ? data.emblem : icons.association} /></div>,
            <div>
                <div>{data.name}</div>
                <ImportedTag pe={data.plannedEdge} />
            </div>
        ]
    },
    users: ({ data }) => {
        return [
            <div className='icon'><img src={data.avatar && data.avatar.length > 0 ? data.avatar : icons.user} /></div>,
            <div>{data.first_name+' '+data.last_name}</div>
        ]
    },
    divisions: ({ data }) => {
        return [
            <div className='icon'><img src={data.emblem && data.emblem.length > 0 ? data.emblem : icons.trophy} /></div>,
            <div>
                <div>{data.name}</div>
                <ImportedTag pe={data.plannedEdge} />
            </div>
        ]
    },
    passed: item => {
        const { data } = item.data
        return  data.matchday ? [
                    <div><img src={data.matchday.emblem} /></div>,
                    <div className='match-snippet_info'>
                        <div className='match-snippet_teams'>{data.matchday.teams}</div>
                        <div className='match-snippet_tags'>
                            <Tag>{data.matchday.league}</Tag>
                            <Tag intent='primary'>{data.matchday.division}</Tag>
                        </div>
                    </div>
                ] : []
    }
}

const ListItem = ({ type, data, setNestedItem, override, nestedItem, paramsItemId }) => {
    const history = useHistory()

    const Specified = templates[type]
    return  <Card interactive={true} elevation={2} className={`workspace-list_item${data._id === (override ? nestedItem : paramsItemId) ? ' active' : ''}`} onClick={() => !override ? history.push(`/${type}/${data._id}`) : setNestedItem(data._id)}>
                {Specified ? <Specified data={data} /> : null}
            </Card>
}

export default ListItem
