import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Toaster, Position, Button } from '@blueprintjs/core'

import rest from '../../components/Workspace/rest'
import Splash from './Splash'
import { LeagueSelect, ModeSelect } from './Screens'
import Clubs from './Screens/Clubs'
import StaffList from './Screens/StaffList'
import PrintList from './Screens/PrintList'
import Matches from './Screens/Matches'
import Graphic from './Screens/Graphic'

import './style.css'

const { assignClubs } = require('./utils')

const renderInvite = () => {
    return (Math.floor(Math.random() * 90000) + 10000).toString()
}

const Panel = () => {
    const [data, setData] = useState({})
    const [progress, setProgress] = useState(false)
    const [inviteCode, setInviteCode] = useState(null)
    const [title, setTitle] = useState(null)
    const toaster = useRef(null)
    const { token } = useParams()

    useEffect(() => {
        rest('get', 'panel', token)
            .then(response => {
                if(response.error) {
                    toaster.current.show({intent: 'danger', message: response.message})
                    setTimeout(() => {
                        window.close()
                    }, 2000)
                } else {
                    setData({
                        ...data,
                        source: response,
                        leagueId: response.leagues.length < 2 ? response.leagues[0] ? response.leagues[0]._id : null : null,
                        mode: null
                    })
                }
            })
    }, [token])

    useEffect(() => {
        if(data.source && data.source.leagues.length > 1) {
            setTitle(data.leagueId ? {
                title: 'Панель управления',
                onBack: () => setData({...data, leagueId: null})
            } : null)
        }

        if(!data.leagueId) {
            setData({...data, mode: null, leagueId: null})
        }
    }, [data.leagueId])

    const renderInner = ({ source, leagueId, mode}) => {
        if(!leagueId && source) {
            return <LeagueSelect data={source.leagues} setLeague={l => setData({...data, leagueId: l})} />
        } else if(leagueId && !mode) {
            return <ModeSelect data={source} setMode={m => setData({...data, mode: m})} />
        } else {
            switch(mode) {
                case 'graphics':
                    return  <Graphic
                                leagueId={leagueId}
                                setTitle={setTitle}
                                tid={data && data.source ? data.source.tid : null}
                                toaster={toaster}
                            />
                    break
                case 'matches':
                    return  <Matches
                                leagueId={leagueId}
                                setTitle={setTitle}
                            />
                    break
                case 'printable':
                    return  <PrintList
                                setTitle={setTitle}
                                leagueId={leagueId}
                                tid={source.tid}
                                email={source.email || null}
                            />
                    break
                case 'clubs':
                    return  <Clubs
                                data={assignClubs(source, leagueId)}
                                setTitle={setTitle}
                                leagueId={leagueId}
                            />
                    break
                case 'staff':
                    return  <StaffList
                                leagueId={leagueId}
                                inviteCode={inviteCode}
                                resetInviteCode={() => setInviteCode(null)}
                                setTitle={setTitle}
                                data={source.staff.filter(s => s && s.tid !== source.tid && s.roles.find(r => r.leagueId === leagueId))}
                                createPerson={person => {
                                    setProgress(true)
                                    rest('post', 'staffs', null, {
                                        first_name: person.first_name,
                                        last_name: person.last_name,
                                        roles: person.roles.map(r => ({leagueId: leagueId, role: r})),
                                        invite: renderInvite()
                                    }).then(created => {
                                        setProgress(false)
                                        setData({...data, source: {...source, staff: [created].concat(data.staff)}})
                                        setInviteCode(created.invite)
                                    })
                                }}
                            />
                default:
                    return null
            }
        }
    }

    return  <div className='panel'>
                <div className='head'>
                    {title ? [<Button
                        minimal={true}
                        text='Назад'
                        icon='chevron-left'
                        intent='primary'
                        onClick={() => {
                            switch(true) {
                                case title.onBack === 'resetMode':
                                    setData({...data, mode: null})
                                    setTitle(data.source.leagues.length > 1 ? {
                                        ...title,
                                        onBack: () => setData({...data, leagueId: null})
                                    } : null)
                                    break
                                default:
                                    title.onBack()
                            }
                        }}
                    />,
                    <div className='title'>{data.mode ? title.string : 'Панель управления'}</div>
                ] : ([
                    <Button intent='danger' minimal={true} text='Закрыть' icon='delete' onClick={() => window.close()}/>,
                    <div className='title'>{data.leagueId ? 'Панель управления' : 'Выберите лигу'}</div>
                ]
                )}
                </div>
                <Toaster position={Position.TOP_CENTER} ref={toaster}></Toaster>
                <Splash active={!data.source || progress} />
                {renderInner(data)}
            </div>
}

export default Panel
