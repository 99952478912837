import React, { useState } from 'react'

import { Tabs, Tab } from '@blueprintjs/core'

import Create from './Create'
import List from './List'
import Bind from './Bind'

const Content = () => {
    const [tab, setTab] = useState('create')

    return  <div>
                <h3>Управление матчами</h3>
                <Tabs selectedTabId={tab} renderActiveTabPanelOnly={true} onChange={t => setTab(t)}>
                    <Tab id='list' title='Список матчей' panel={<List />} />
                    <Tab id='create' title='Создать новый' panel={<Create />}/>
                    <Tab id='bind' title='Связать' panel={<Bind />}/>
                </Tabs>
            </div>
}

export default Content
