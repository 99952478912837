import { React, useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Select } from '@blueprintjs/select'
import { NonIdealState, Card, InputGroup, Popover, Button, MenuItem, Toaster, Position, FileInput, ControlGroup, Tag, Drawer } from '@blueprintjs/core'
import { DatePicker, TimePicker } from '@blueprintjs/datetime'
import StandDrawer from './StandDrawer'
import MatchCloneMenu from './MatchCloneMenu'
import moment from 'moment'
import axios from 'axios'
import { diff } from 'deep-object-diff'

import './matchBody.css'
const rest = require('../Workspace/rest')
const { REST } = require('../../env')
const ENGINE = 'https://engine.amateum.com/v1/'
moment.locale('ru')

const { extractMatchStatus, testTargets, source } = require('./utils')

const ItemBody = ({ data, onUpdatedItem, onDeletedItem }) => {
    const [form, setForm] = useState(null)
    const [refs, setRefs] = useState({})
    const [attach, setAttach] = useState(null)
    const [saving, setSaving] = useState(false)
    const [marked, setMarked] = useState(false)
    const [remoteSeason, setRemoteSeason] = useState(null)
    const [standDrawer, setStandDrawer] = useState(false)
    const toaster = useRef(null)
    const { matchId } = useParams()
    const history = useHistory()

    useEffect(() => {
        setForm(data)
        if(data) {
            rest('get', 'seasons', data.seasonId)
                .then(seas => {
                    setRefs({...refs, clubs: seas.clubs})
                    setRemoteSeason(seas.remote)
                })
        }
    }, [matchId, data])

    const updRoster = t => {
        const remoteId = ['goalstream', 'lflworld'].includes(form[t+'Club'].remote.source) ? form[t+'Club'].remote.url.split('/')[2] : form[t+'Club'].remote.id
        return new Promise((resolve, reject) => {
            rest('put', 'parsers', `${form[t+'Club'].remote.source}/club?remoteClubId=${remoteId}&remoteSeasonId=${remoteSeason.id}&seasonId=${form.seasonId}`)
                .then(roster => {
                    if(!roster.error) {
                        resolve(true)
                    }
                })
        })
    }

    const refreshRosters = () => {
        setSaving(true)

        const que = ['home', 'away'].map(t => updRoster(t))
        Promise.all(que)
            .then(ready => {
                setSaving(false)
                toaster.current.show({intent: 'success', message: 'Ростеры актуализированы по внешнему источнику'})
            })
    }

    const downloadSheet = () => {
        setSaving(true)
        axios.put(`${REST}utils`, {action: 'downloadMatchSheet', matchId: form._id}, {responseType: 'arraybuffer', headers: {'Accept': 'application/pdf'}})
            .then(response => {
                if(!response.error) {
                    const blob = new Blob([response.data], {type: 'application/pdf'})
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = `${form.homeClub.name} vs ${form.awayClub.name}_${form.date}_${form.time}.pdf`
                    link.click()
                    setSaving(false)
                }
            })
    }

    const saveItem = _diff => {
        setSaving(true)
        rest('put', 'matches', form._id, _diff)
            .then(upd => {
                if(upd._id) {
                    setSaving(false)
                    toaster.current.show({intent: 'success', message: 'Матч успешно сохранён'})
                    onUpdatedItem({
                        ...upd,
                        homeClub: refs.clubs.find(el => el._id === upd.homeId),
                        awayClub: refs.clubs.find(el => el._id === upd.awayId)
                    })
                }
            })
    }

    const renderField = f => {
        switch(f.type) {
            case 'select':
                return (
                    <Select
                        items={refs[f.refs] || []}
                        itemRenderer={item => <MenuItem text={item.name} onClick={() => setForm({...form, [f.key]: item._id})} />}
                        filterable={false}
                    >
                        <Button
                            text={refs[f.refs] && form ? refs[f.refs].find(el => el._id === form[f.key]) ? refs[f.refs].find(el => el._id === form[f.key]).name : '------' : '------'}
                            fill={true}
                        />
                    </Select>
                )
            case 'time':
                return (
                    <Popover
                        content={<TimePicker value={moment(form[f.key], 'HH:mm').toDate()} onChange={v => setForm({...form, [f.key]: moment(v).format('HH:mm')})}/>}
                    >
                        <Button icon='history' text={form[f.key] == '' ? 'Время' : form[f.key]} fill={true} />
                    </Popover>
                )
            case 'date':
                return (
                    <Popover
                        content={<DatePicker value={moment(form[f.key], 'YY-MM-DD').toDate()} onChange={v => setForm({...form, [f.key]: moment(v).format('YY-MM-DD')})} />}
                    >
                        <Button text={moment(form[f.key], 'YY-MM-DD').format('DD MMMM YY')} fill={true} />
                    </Popover>
                )
            case 'text':
                return  <InputGroup value={form[f.key] || ''} onChange={e => setForm({...form, [f.key]: e.target.value})} />
        }
    }

    const uploadAttach = () => {
        setSaving(true)
        const reader = new FileReader()
        reader.onload = () => {
            rest('put', 'uploader', null, {matchId: form._id, ext: attach.type.includes('png') ? 'png' : 'jpg', data: reader.result})
                .then(upl => {
                    setSaving(false)
                    const nf = {...form, protocol: true}
                    setForm(nf)
                    onUpdatedItem(nf)
                    setAttach(null)
                    toaster.current.show({intent: 'success', message: 'Протокол загружен. Матч форсирован в обработку'})
                })
        }
        reader.readAsDataURL(attach)
    }

    const diffObj = diff(data, form)
    const actualDiff = (!diffObj || diffObj._id) ? {} : diffObj

    const makeTest = (move=false) => {
        setSaving(true)
        const src = form.remoteId.includes('/') ? 'goalstream' : 'lfl'
        const {_id, homeClub, awayClub, division, season, ...other} = form
        const body = {
            ...other,
            dryRun: true,
            seasonId: testTargets[src+'Season'],
            divisionId: testTargets[src+'Division'],
            matchdayId: testTargets[src+'Matchday'],
            leagueId: testTargets.league,
            movementSubject: move ? _id : null,
            oldSeason: form.seasonId
        }

        rest('post', 'matches', null, body)
            .then(response => {
                onUpdatedItem({...body, _id: _id, homeClub: homeClub, awayClub: awayClub}, !move)
                toaster.current.show({intent: 'primary', message: move ? 'Матч успешно перенесён в тестовый турнир' : 'Матч успешно продублирован в тестовый турнир'})
                setSaving(false)
            })
    }

    const handleDeletion = () => {
        if(!marked) {
            setMarked(true)
        } else {
            setSaving(true)
            rest('delete', 'matches', form._id)
                .then(del => {
                    toaster.current.show({intent: 'warning', message: 'Матч успешно удалён'})
                    setSaving(false)
                    onDeletedItem(form._id)
                    setMarked(false)
                    history.push('/matches')
                })
        }
    }

    const extractedStatus = form ? extractMatchStatus(form) : {}

    const resetMatch = (full=false) => {
        const nf = {
            ...form,
            protocol: full ? null : form.protocol,
            data: null,
            draft: full ? null : form.draft,
            interactions: null,
            posted: false
        }

        rest('put', 'matches', form._id, nf)
            .then(upd => {
                toaster.current.show({intent: 'primary', message: 'Состояние матча сброшено'})
                setForm(nf)
                onUpdatedItem(nf)
            })
    }

    const distributeMvps = async () => {
        setSaving(true)
        rest('put', 'utils', null, {
            action: 'renderMvps',
            subjectId: data._id
        }).then(rendered => {
            toaster.current.show({intent: 'success', message: 'MVP отправлены в телегу!'})
            setSaving(false)
        })
    }

    const distributeSummary = async (scorers=false) => {
        setSaving(true)
        const attachMode = () => {
            if(!data.seasonGroups || data.seasonGroups.length === 0) {
                return ['season', data.seasonId]
            } else {
                const group = data.seasonGroups.find(g => g.clubs && g.clubs.includes(data.homeId) && g.clubs.includes(data.awayId))
                if(group) {
                    return ['group', group._id]
                } else {
                    return ['season', data.seasonId]
                }
            }
        }
        const [mode, subjectId] = attachMode()
        rest('put', 'utils', null, {
            action: scorers ? 'distributeScorers' : 'distributeSummarySharing',
            mode: scorers ? null : mode,
            subjectId: scorers ? data.seasonId : subjectId
        }).then(posted => {
            toaster.current.show({intent: 'success', message: 'Саммари размещено!'})
            setSaving(false)
        })
    }

    const distributeMatch = (mode, forceId=null) => {
        const distSystem = (next=false) => {
            setSaving(true)
            rest('post', 'posters', (forceId || data._id)+(data.dryRun ? '?dryRun=true' : ''))
                .then(posted => {
                    rest('put', 'matches', data._id, {posted: true})
                    toaster.current.show({intent: !posted.error ? 'success' : 'danger', message: !posted.error ? 'Размещено в инфо-системе' : 'Ошибка размещения в инфо системе'})
                    setSaving(false)
                    if(next) {
                        distSocials()
                    }
                })
        }

        const distSocials = () => {
            setSaving(true)
            rest('put', 'delivery', null, {
                matchId: data._id,
                date: data.date,
                divisionId: data.divisionId
            }).then(ready => {
                toaster.current.show({intent: !ready.error ? 'success' : 'danger', message: !ready.error ? 'Размещено в каналах' : 'Ошибка размещения в каналах'})
                setSaving(false)
            })
        }

        const clearContainers = () => {
            rest('put', 'bot', null, {
                action: 'updateContainerPerMatch',
                target: data._id
            }).then(ok => {
                console.log('Containers actualized')
            })
        }

        switch(mode) {
            case 'full':
                distSystem(true)
                break
            case 'system':
                distSystem(false)
                break
            case 'socials':
                distSocials()
                break
        }
    }

    const cloneToEngine = () => {
        setSaving(true)
        axios.post(`${ENGINE}unscoped/cloneMatch`, {
            legacyId: data._id,
            cloneId: data.engineId
        }).then(cloned => {
            console.log(cloned.data)
            setSaving(false)
            toaster.current.show({intent: 'success', message: 'Матч клонирован в движок!'})
        })
    }

    const updateAffectedContainers = () => {
        setSaving(true)
        rest('put', 'bot', null, {
            action: 'updateContainerPerMatch',
            target: form._id
        }).then(upd => {
            setSaving(false)
            toaster.current.show({intent: 'success', message: 'Обновлены!'})
        })
    }

    return  form ? (
        <div className='match-body'>
            <Drawer
                size='90%'
                isOpen={standDrawer}
            >
                <Button icon='cross' intent='primary' large={true} minimal={true} text='Закрыть' onClick={() => setStandDrawer(false)} style={{position: 'absolute', right: 20, top: 20}} />
                {standDrawer ? <StandDrawer /> : null}
            </Drawer>

            <Button fill={true} intent='primary' large={true} style={{marginBottom: 16}} text='Открыть матч в модерашке' onClick={() => {window.open(`https://work.amateum.com/moderate/${matchId}`)}} />

            {source.map(row => (
                <div className='match-body_row'>
                    {row.map(f => (
                        <div className='filter-box' style={{width: f.width+'%'}}>
                            <label>{f.label}</label>
                            <Card elevation={1}>{renderField(f)}</Card>
                        </div>
                    ))}
                </div>
            ))}
            {form.season && form.season.cloneOf ? (
                <MatchCloneMenu
                    clonedSeason={form.season.cloneOf}
                    value={form.cloneOf}
                    onValueSelected={id => setForm({
                        ...form,
                        cloneOf: id
                    })}
                />
            ) : null}
            <div>
                <Button disabled={Object.keys(actualDiff).length === 0 || saving} text='Сохранить изменения' intent='primary' onClick={() => saveItem(actualDiff)} />
            </div>

            <Toaster position={Position.TOP_RIGHT} ref={toaster}></Toaster>

            <div className='filter-box actions' style={{marginTop: 20}}>
                <label>Состояние:</label>
                <Tag intent={extractedStatus.intent}>{extractedStatus.text}</Tag>
                {extractedStatus.intent !== 'warning' ? <Button style={{marginLeft: 4}} text='Сбросить к ожиданию' onClick={() => resetMatch(true)} /> : null}
                {extractedStatus.intent !== 'warning' && extractedStatus.intent !== 'danger' ? <Button  style={{marginLeft: 4}} onClick={() => resetMatch()} text='Сбросить к обработке' /> : null}
                {form.protocol && form.protocol.includes('origin') ? <Button intent='warning' onClick={() => window.open(form.protocol)} text='Оригинал принятого протокола' style={{marginTop: 10}} icon='download' /> : null}
            </div>

            <div className='filter-box actions' style={{marginTop: 20}}>
                <label>Дистрибуция:</label>
                <Button disabled={saving} text='Полный цикл' icon='send-to-graph' style={{marginRight: 6}} onClick={() => distributeMatch('full')} />
                <Button disabled={saving} text='Только сети' icon='social-media' style={{marginRight: 6}} onClick={() => distributeMatch('socials')} />
                <Button disabled={saving} text='Только инфо-система' style={{marginRight: 6}} icon='flows' onClick={() => distributeMatch('system')} />
                <Button disabled={saving} text='Саммари дивизиона' style={{marginRight: 6, marginTop: 6}} icon='property' onClick={() => distributeSummary()} />
                <Button disabled={saving} text='Бомбардиры' style={{marginRight: 6, marginTop: 6}} icon='star' onClick={() => distributeSummary(true)} />
                {form.data && form.data.mvp && Object.keys(form.data.mvp).length > 0 ? <Button disabled={saving} text='MVP матча' style={{marginRight: 6, marginTop: 6}} icon='clean' onClick={() => distributeMvps()} /> : null}
                {data && data.engineId && data.engineId.length ? (
                    <Button disabled={saving} text='Клон в движок' style={{marginRight: 6, marginTop: 6}} icon='gear' onClick={() => cloneToEngine()} />
                ) : null}
            </div>

            <div className='filter-box actions' style={{marginTop: 20}}>
                <label>Операции:</label>
                <div>
                    <Button text='Обновить контейнеры у причастных' icon='flame' disabled={saving} onClick={() => updateAffectedContainers()} />
                </div>
                <div>
                    <Button text='Обновить ростеры команд' icon='refresh' disabled={saving} onClick={() => refreshRosters()} />
                </div>
                <div>
                    <Button text='Скачать PDF-протокол' icon='download' disabled={saving} onClick={() => downloadSheet()} intent='primary' />
                </div>
                <div>
                    <ControlGroup fill={true}>
                        <FileInput
                            text={attach ? attach.name : 'Ручная загрузка протокола'}
                            buttonText='Browse'
                            fill={false}
                            onInputChange={e => setAttach(e.target.files[0] || null)}
                        />
                        <Button intent='none' text='Отправить' disabled={!attach} large={false} onClick={() => uploadAttach()} />
                    </ControlGroup>
                </div>
                <div>
                    <Button icon='duplicate' text='Дублировать в тестовый турнир' disabled={saving} onClick={() => makeTest()} />
                    <Button style={{marginLeft: 4}} icon='send-to' text='Перенести в тестовый турнир' disabled={saving} onClick={() => makeTest(true)}/>
                </div>
                <div>
                    <Button intent={marked ? 'danger' : 'warning'} disabled={saving} text={marked ? 'Это действие нельзя отменить! Да, удалить матч!' : 'Удалить матч'} icon={marked ? 'delete' : 'remove'} onClick={() => handleDeletion()}/>
                </div>
                <div>
                    <label>Инструменты:</label>
                    <Button text='Debug-стенд распознавания' icon='code-block' onClick={() => setStandDrawer(true)} />
                    {form.clones && form.clones[0] ? (
                        <div style={{marginTop: 5}}>
                            <Button
                                intent='warning'
                                text='Дистрибуция клона'
                                minimal={true}
                                onClick={() => distributeMatch('system', form.clones[0]._id)}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    ) : (
        <NonIdealState icon='search' description='выберите матч' />
    )
}

export default ItemBody
