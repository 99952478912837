import { React, useState, useEffect, useRef } from 'react'
import { HotKeys } from 'react-hotkeys'

import { ControlGroup, InputGroup, Button, NumericInput, Switch, Tag, Popover, Checkbox, Toaster, Position } from '@blueprintjs/core'
import Form from '../Workspace/Forms'
import MatchDialog from './MatchDialog'
const spaces = 'https://amateum.fra1.digitaloceanspaces.com/sheets/'
const rest = require('../Workspace/rest')

const ScoreForm = ({ data, patchScores }) => {
    if(!data.full) { data.full = {} }
    if(!data.options) { data.options = {} }
    if(!data.shootout) { data.shootout = {} }

    return  <div className='bp3-card bp3-elevation-2 score-form row'>
                <div>
                    <div style={{marginBottom: 4}}><b>Счёт первого тайма</b></div>
                    <ControlGroup fill={false} className='score-group'>
                        <NumericInput large={true} buttonPosition={Position.LEFT} max={30} min={0} value={data.half.home} onValueChange={v => patchScores('half', 'home', v)} />
                        <NumericInput large={true} max={30} min={0} value={data.half.away} onValueChange={v => patchScores('half', 'away', v)} />
                    </ControlGroup>
                </div>
                <div>
                    <div style={{marginBottom: 4}}><b>Счёт матча</b></div>
                    <ControlGroup fill={false} className='score-group'>
                        <NumericInput buttonPosition={Position.LEFT} large={true} max={120} min={0} value={data.full.home} onValueChange={v => patchScores('full', 'home', v)} />
                        <NumericInput large={true} max={120} min={0} value={data.full.away} onValueChange={v => patchScores('full', 'away', v)} />
                    </ControlGroup>
                </div>
                <div style={{paddingTop: 16, paddingRight: 10}}>
                    <Checkbox style={{marginBottom: 4}} label='Технический' checked={data.options.tech} onChange={() => patchScores('options', 'tech', data.options.tech ? false : true)} />
                    <Checkbox style={{marginBottom: 4}} label='В овертайме' checked={data.options.extraTime} onChange={() => patchScores('options', 'extraTime', data.options.extraTime ? false : true)} />
                    <Checkbox label='Серия пенальти' checked={data.options.shootout} onChange={() => patchScores('options', 'shootout', data.options.shootout ? false : true)} />
                    {data.options.shootout ? ([
                        <div>Счёт в серии пенальти</div>,
                        <ControlGroup fill={false} className='score-group'>
                            <NumericInput  buttonPosition={Position.LEFT} large={true} max={120} min={0} value={data.shootout.home} onValueChange={v => patchScores('shootout', 'home', v)} />
                            <NumericInput large={true} max={120} min={0} value={data.shootout.away} onValueChange={v => patchScores('shootout', 'away', v)} />
                        </ControlGroup>
                    ]) : null}
                </div>
            </div>
}

const filterReference = (data, ref) => {
    return ref.filter(i => !data[i._id] && !i.unlinked)
}

const AddPlayer = ({ toaster, squadId, clubId, seasonId }) => {
    const [data, setData] = useState({name: null, number: null})
    const [progress, setProgress] = useState(false)
    const [done, setDone] = useState(false)

    const submitPlayer = () => {
        setProgress(true)

        const body = {
            ...data,
            clubId: clubId,
            squadId: squadId,
            seasonId: seasonId
        }

        rest('post', 'players', null, body)
            .then(resp => {
                setProgress(false)
                setDone(true)
                toaster.show({intent: 'primary', message: 'Заявка отправлена, ожидаем ответа'})
            })
    }

    return  !done ? <div style={{padding: 10, background: '#fff', width: 300}}>
                <InputGroup placeholder='Фамилия Имя' large={true} value={data.name} name='name' onChange={e => setData({...data, [e.target.name]: e.target.value})} />
                <InputGroup placeholder='Игровой номер' style={{marginTop: 10}} large={true} value={data.number} name='number' onChange={e => setData({...data, [e.target.name]: e.target.value})} />
                <Button text='Отправить запрос' fill={true} style={{marginTop: 10}} intent='success' disabled={!data.name || !data.number} onClick={() => submitPlayer()} loading={progress} />
            </div> : <div>Отправлено</div>
}

const SquadList = ({ data, side, patchPlayer, referenceSquad, addMissing, batchMute, squadId, clubId, seasonId, query, remoteRoster, refreshRemoteRoster }) => {
    const [formData, setFormData] = useState(null)
    const toaster = useRef(null)

    const createPlayer = () => {
        rest('post', 'players', null, formData)
            .then(player => {
                if(player._id) {
                    rest('put', 'squads', squadId, {push: ['players', {num: formData.num, _id: player._id}]})
                        .then(pushed => {
                            addMissing([player])
                        })
                }
            })
    }

    const filteredReference = referenceSquad ? filterReference(data, referenceSquad) : null
    const invalidNumbers = Object.keys(data).filter(p => (!data[p].num || data[p].num === '' || parseInt(data[p].num) > 99) && !data[p].absent)

    const callRefreshRemoteRoster = async () => {
        const refr = await refreshRemoteRoster(side)
        if(refr) {
            toaster.current.show({intent: 'success', message: 'Ростер обновлён'})
        }
    }

    const properQty = data ? Object.keys(data).map(k => data[k]).filter(p => !p.absent && p.num && parseInt(p.num) < 100).length : 0

    return  <div className='squad-team'>
                <Toaster ref={toaster} />
                {/*<Popover
                    content={(
                        <AddPlayer
                            toaster={toaster.current}
                            squadId={squadId}
                            clubId={clubId}
                            seasonId={seasonId}
                        />
                    )}
                >
                    <Button text='Запрос на дозаявку админу' fill={true} />
                </Popover>
                <Popover
                    content={(
                        <div style={{padding: 10, paddingBottom: 0}}>
                            <Form
                                collection='squadplayers'
                                throwForm={data => setFormData(data)}
                            />
                            <div className='overlay-form_actions'>
                                <Button text='Добавить игрока' intent='success' onClick={() => createPlayer()} />
                            </div>
                        </div>
                    )}
                >
                    <Button fill={true} text='Добавить игрока в заявку' />
                </Popover>*/}
                <ControlGroup>
                    <Button fill={true} text={'Активно игроков: '+properQty} minimal={true} intent='success' />
                    <Button fill={true} text='Погасить неверных' disabled={invalidNumbers.length === 0} onClick={() => batchMute(invalidNumbers, side)} />
                </ControlGroup>
                <ControlGroup>
                    <Button
                        text='Ист. ростера'
                        fill={true}
                        icon='database'
                        disabled={!remoteRoster}
                        onClick={() => window.open(remoteRoster)}
                    />
                    <Button
                        text='Обн. ростер'
                        fill={true}
                        icon='refresh'
                        onClick={() => callRefreshRemoteRoster()}
                    />
                </ControlGroup>
                {filteredReference && filteredReference.length > 0 ? <Button onClick={() => addMissing(filteredReference)} fill={true} text='Добавить из заявки на сезон'><Tag intent='warning' style={{marginLeft: 4}}>{filteredReference.length}</Tag></Button> : null}
                {Object.keys(data).map(_id => !query || (query && data[_id].name.toLowerCase().includes(query.toLowerCase())) ? (
                    <div key={_id} className={`squad-team_item${data[_id].duplicated ? ' duplicated' : ''}`}>
                        <Switch checked={!data[_id].absent} onChange={() => patchPlayer(side, _id, {absent: !data[_id].absent})} />
                        <NumericInput max={300} intent={data[_id].num > 99 ? 'danger' : 'none'} min={1} value={data[_id].num && data[_id].num !== 'undefined' ? data[_id].num : ''} disabled={data[_id].absent} onValueChange={v => patchPlayer(side, _id, {num: v.toString()})} />
                        <div className={`squad-team_item_name${data[_id].absent ? ' muted' : ''}`}>{data[_id].name}</div>
                    </div>
                ) : null)}
            </div>
}

const EventsForm = ({ data, nums, side, setEvents }) => {
    const types = [
        {key: 'goal', enableAssist: true, label: 'Голы'},
        {key: 'yc', label: 'Предупреждения'},
        {key: 'rc', label: 'Удаления'}
    ]

    const numIntent = n => {
        return !n || nums.indexOf(n.toString()) === -1 ? 'danger' : 'none'
    }

    return data ? types.map(t => ([<label>{t.label}: <Button minimal={true} intent='success' text='Add' icon='add' onClick={() => setEvents(side, 'add', t.key)} /></label>].concat(data[t.key] ? data[t.key].map((e, idx) => (
        <div className='event-form_item'>
            <ControlGroup
                label='минута'
            >
                <NumericInput max={120} min={1} value={e.minute} leftIcon='stopwatch' onValueChange={v => setEvents(side, 'put', t.key, idx, {minute: v})} style={{marginRight: 50}}/>
                <NumericInput max={120} min={1} value={e.number} leftIcon='person' intent={numIntent(e.number)} onValueChange={v => setEvents(side, 'put', t.key, idx, {number: v})} />
                {t.enableAssist ? <NumericInput max={120} min={1} value={e.assist > 0 ? e.assist : ''} leftIcon='follower' intent={numIntent(e.assist)} onValueChange={v => setEvents(side, 'put', t.key, idx, {assist: v})}  /> : null}
            </ControlGroup>

            <Button
                intent='danger'
                minimal={true}
                icon='cross'
                onClick={() => setEvents(side, 'del', t.key, idx)}
            />

            <div style={{width: '100%', padding: '0px 6px 0px 155px', position: 'relative'}}>
                {t.key === 'goal' ? <Checkbox checked={e.owngoal} onChange={() => setEvents(side, 'put', t.key, idx, {owngoal: !e.owngoal})} intent='danger' label='АГ' style={{fontSize: 11, display: 'inline-block', color: '#f00', position: 'absolute', left: 0, top: 5}} /> : null}
                {t.key === 'goal' ? <Checkbox checked={e.penalty} onChange={() => setEvents(side, 'put', t.key, idx, {penalty: !e.penalty})} intent='danger' label='ПЕН' style={{fontSize: 11, display: 'inline-block', position: 'absolute', left: 47, top: 5}} /> : null}
                {t.key === 'goal' ? <Checkbox checked={e.missedPenalty} onChange={() => setEvents(side, 'put', t.key, idx, {missedPenalty: !e.missedPenalty})} intent='danger' label='НП' style={{fontSize: 11, display: 'inline-block', position: 'absolute', left: 105, top: 5}} /> : null}
                {e.number ? e.player ? <Tag intent='primary'>{e.player.name}</Tag> : <Tag intent='danger'>не записан игрок</Tag> : null}
                {e.assist ? e.assistant ? <Tag intent='primary'>{e.assistant.name}</Tag> : <Tag intent='danger'>нет ассистента</Tag> : null}
            </div>
        </div>
    )) : []))) : null
}

export const Squads = ({ scores, squads, patchPlayer, patchScores, matchId, unshiftSquad, zoomImg, batchMute, actions }) => {
    const [referSquads, setReferSquads] = useState(null)
    const [squadsIds, setSquadsIds] = useState(null)
    const [query, setQuery] = useState('')
    const [matchMeta, setMatchMeta] = useState({})
    const [remoteRosters, setRemoteRosters] = useState({home: null, away: null})
    const filterField = useRef(null)

    const retrieveProtocolData = () => {
        rest('get', 'protocoldata', matchId)
            .then(data => {
                setMatchMeta({
                    homeId: data.homeId,
                    awayId: data.awayId,
                    seasonId: data.seasonId,
                    homeRemote: data.home.remote,
                    awayRemote: data.away.remote,
                    seasonRemote: data.season.remote
                })
                setReferSquads({home: data.homeSquad.playersData, away: data.awaySquad.playersData})
                setSquadsIds({
                    home: data.homeSquad._id,
                    away: data.awaySquad._id
                })

                if(data.season.remote && data.season.remote.source) {
                    let home, away
                    switch(data.season.remote.source) {
                        case 'goalstream':
                            home = `http://www.goalstream.org/${data.home.remote.url}#/players?season=${data.season.remote.id}`
                            away = `http://www.goalstream.org/${data.away.remote.url}#/players?season=${data.season.remote.id}`
                            break
                    }

                    if(home && away) {
                        setRemoteRosters({home: home, away: away})
                    }
                }
            })
    }

    const refreshRemoteRoster = (side) => {
        return new Promise((resolve, reject) => {
            rest('put', 'parsers', `goalstream/club?remoteClubId=${matchMeta[side+'Remote'].url.replace('/club/', '')}&remoteSeasonId=${matchMeta.seasonRemote.id}&seasonId=${matchMeta.seasonId}`)
                .then(resp => {
                    resolve(true)
                    retrieveProtocolData()
                })
        })
    }

    useEffect(() => {
        retrieveProtocolData()
    }, [])

    const focusFilter = () => {
        console.log('HOTKEY PRESSED!')
        filterField.current.focus()
    }

    const keysHandlers = {
        FOCUS_FILTER: focusFilter
    }

    return  <HotKeys handlers={keysHandlers}>
                <div className='origin'>
                    <img src={`${spaces}${matchId}_body.jpg`} onClick={() => zoomImg()} />
                </div>
                <div className='controls'>
                    {actions}
                    <ScoreForm data={scores} patchScores={patchScores} />

                    <div className='bp3-card bp3-elevation-2 player-suggest'>
                        <ControlGroup>
                            <InputGroup placeholder='Поиск в составах' inputRef={filterField} fill={true} value={query} onChange={({target}) => setQuery(target.value)} />
                            <Button icon='delete' text='Очистить' onClick={() => setQuery('')} />
                        </ControlGroup>
                    </div>

                    <div className='squads'>
                        {['home', 'away'].map(t => (
                            <SquadList
                                referenceSquad={referSquads ? referSquads[t] : null}
                                squadId={squadsIds ? squadsIds[t] : null}
                                remoteRoster={remoteRosters[t]}
                                patchPlayer={patchPlayer}
                                side={t}
                                data={squads[t]}
                                query={query}
                                addMissing={arr => unshiftSquad(arr, t)}
                                batchMute={batchMute}
                                clubId={matchMeta[t+'Id']}
                                seasonId={matchMeta.seasonId}
                                refreshRemoteRoster={refreshRemoteRoster}
                            />
                        ))}
                    </div>
                </div>
            </HotKeys>
}

export const Events = ({ scores, events, squads, setEvents, patchScores, matchId, zoomImg, actions, mvp, setMvp, toaster }) => {
    const homeNums = Object.keys(squads.home).filter(k => !squads.home[k].absent).map(k => squads.home[k].num)
    const awayNums = Object.keys(squads.away).filter(k => !squads.away[k].absent).map(k => squads.away[k].num)

    return  <div>
                <div className='origin'>
                    <img src={`${spaces}${matchId}_events.jpg`} onClick={() => zoomImg()} />
                </div>

                <div className='controls'>
                    {actions}
                    <ScoreForm data={scores} patchScores={patchScores} />
                    <div style={{display: 'flex'}}>
                        <div>
                            <div className='events-form'>
                                <label>Хозяева:
                                    <ControlGroup>
                                        <label style={{marginRight: 10, paddingTop: 5}}>MVP</label>
                                        <NumericInput value={mvp.home || null} onValueChange={v => setMvp('home', v)} intent={!mvp.home ? 'warning' : homeNums.indexOf(mvp.home.toString()) === -1 ? 'danger' : 'success'} />
                                    </ControlGroup>
                                </label>
                                <EventsForm
                                    side='home'
                                    setEvents={setEvents}
                                    data={events.home}
                                    nums={homeNums}
                                />
                            </div>
                            <div className='events-form'>
                                <label>Гости:
                                    <ControlGroup>
                                        <label style={{marginRight: 10, paddingTop: 5}}>MVP</label>
                                        <NumericInput value={mvp.away || null} onValueChange={v => setMvp('away', v)} intent={!mvp.away ? 'warning' : awayNums.indexOf(mvp.away.toString()) === -1 ? 'danger' : 'success'} />
                                    </ControlGroup>
                                </label>
                                <EventsForm
                                    side='away'
                                    setEvents={setEvents}
                                    data={events.away}
                                    nums={awayNums}
                                />
                            </div>
                        </div>
                        <div>
                            <MatchDialog toaster={toaster} />
                        </div>
                    </div>
                </div>
            </div>
}
