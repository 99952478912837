import { collectData } from './utils'

export const checkData = (input, meta) => {
    const errors = []
    const essentials = ["homeSquadResponse", "awaySquadResponse", "homeEvsResponse", "awayEvsResponse"]
    let post
    let valid
    const expectedScores = checkExpectedScores(input.scores, input.homeEvsResponse, input.awayEvsResponse)
    if(expectedScores) {
        errors.push(expectedScores)
    }

    const homeDuplicated = checkSquadDuplicated(input.homeSquadResponse)
    if(homeDuplicated) {
        errors.push({type: 'err', msg: 'Дублируются номера в составе хозяев: '+homeDuplicated.join(', ')})
    }

    const awayDuplicated = checkSquadDuplicated(input.awaySquadResponse)
    if(awayDuplicated) {
        errors.push({type: 'err', msg: 'Дублируются номера в составе гостей: '+awayDuplicated.join(', ')})
    }

    const unattachedHome = checkUnattached(input.homeSquadResponse, input.homeEvsResponse)
    if(unattachedHome) {
        errors.push({type: 'err', msg: 'В событиях хозяев есть непривязанные к составу номера: '+unattachedHome.join(', ')})
    }

    const unattachedAway = checkUnattached(input.awaySquadResponse, input.awayEvsResponse)
    if(unattachedAway) {
        errors.push({type: 'err', msg: 'В событиях гостей есть непривязанные к составу номера: '+unattachedAway.join(', ')})
    }

    const duplicatedAssistsHome = checkDuplicatedAssists(input.homeEvsResponse)
    if(duplicatedAssistsHome) {
        errors.push({type: 'err', msg: 'У команды хозяев отмечены голы с одним игроком в качестве автора и ассистента. Минуты: '+duplicatedAssistsHome.join(', ')})
    }

    const duplicatedAssistsAway = checkDuplicatedAssists(input.awayEvsResponse)
    if(duplicatedAssistsAway) {
        errors.push({type: 'err', msg: 'У команды гостей отмечены голы с одним игроком в качестве автора и ассистента. Минуты: '+duplicatedAssistsAway.join(', ')})
    }

    const homeLateMins = checkLateMins(input.homeEvsResponse)
    if(homeLateMins) {
        errors.push({type: 'warning', msg: 'Обрати внимание на минуты событий хозяев: '+homeLateMins.join(', ')})
    }

    const awayLateMins = checkLateMins(input.awayEvsResponse)
    if(awayLateMins) {
        errors.push({type: 'warning', msg: 'Обрати внимание на минуты событий гостей: '+awayLateMins.join(', ')})
    }

    const homeSameLastnames = checkSameLastnames(input.homeSquadResponse)
    if(homeSameLastnames) {
        errors.push({type: 'warning', msg: 'Обрати внимание! В составе хозяев есть однофамильцы: '+homeSameLastnames.join(', ')})
    }

    const awaySameLastnames = checkSameLastnames(input.awaySquadResponse)
    if(awaySameLastnames) {
        errors.push({type: 'warning', msg: 'Обрати внимание! В составе гостей есть однофамильцы: '+awaySameLastnames.join(', ')})
    }

    const homeDuplicatedYellows = checkDuplicatedYellows(input.homeEvsResponse)
    if(homeDuplicatedYellows) {
        errors.push({type: 'err', msg: 'У команды хозяев более одной ЖК на одного игрока: '+homeDuplicatedYellows.join(', ')})
    }

    const awayDuplicatedYellows = checkDuplicatedYellows(input.awayEvsResponse)
    if(awayDuplicatedYellows) {
        errors.push({type: 'err', msg: 'У команды гостей более одной ЖК на одного игрока: '+awayDuplicatedYellows.join(', ')})
    }

    const critical = errors.filter(e => e.type === 'err')
    if(critical.length === 0 && essentials.filter(k => !input[k]).length === 0) {
        post = collectData(input)
    }

    if (input.homeSchema && input.awaySchema) {
        if (input.hasOwnProperty('homeSchemaValid') || input.hasOwnProperty('awaySchemaValid')) {
            if (input.homeSchemaValid === false || input.awaySchemaValid === false) {
                valid = false
            } else {
                valid = true
            }
        } else {
            if (checkSchema(input.homeSchema, input.homeSquadResponse, (meta && meta.season && meta.season.schemaQty) ? meta.season.schemaQty : null) && checkSchema(input.awaySchema, input.awaySquadResponse, (meta && meta.season && meta.season.schemaQty) ? meta.season.schemaQty : null)) {
                valid = true
            } else {
                valid = false
            }
        }
    } else {
        valid = true
    }
    return {errors: errors.length ? errors : null, post: post || null, valid: valid}
}

const checkDuplicatedYellows = (evs={}) => {
    if(evs) {
        if(!evs.yc) {
            return null
        } else {
            const reduced = evs.yc.reduce((acc, e) => {
                if(e.player && e.player.name) {
                    if(!acc[e.player.name]) { acc[e.player.name] = 0 }
                    acc[e.player.name]++
                }
                return acc
            }, {})

            const filtered = Object.entries(reduced).filter(e => e[1] > 1).map(e => e[0])
            return filtered.length ? filtered : null
        }
    } else {
        return null
    }
}

const checkSameLastnames = (squad=[]) => {
    const reduced = squad ? squad.reduce((acc, col) => {
        for(let p of col) {
            const ln = p.name.split(' ')[0]
            if(!acc[ln]) { acc[ln] = 0 }
            acc[ln]++
        }
        return acc
    }, {}) : {}

    const filtered = Object.entries(reduced).filter(e => e[1] > 1).map(e => e[0])
    return filtered.length ? filtered : null
}

const checkLateMins = (evs={}) => {
    const reduced = evs ? Object.keys(evs).reduce((acc, type) => {
        for(let evt of evs[type]) {
            if(evt.minute && !isNaN(parseInt(evt.minute)) && parseInt(evt.minute) > 60) {
                acc.push(evt.minute)
            }
        }

        return acc
    }, []) : []

    return reduced.length ? reduced : null
}

const checkDuplicatedAssists = (evs={}) => {
    if(evs && evs.goal) {
        const filtered = evs.goal.filter(e => e.number && e.assist && e.number.toString() === e.assist.toString()).map(e => e.minute)
        return filtered.length ? filtered : null
    } else {
        return null
    }
}

const checkUnattached = (squad=[], events={}) => {
    const reduced = events && Object.keys(events).length ? Object.keys(events).reduce((acc, type) => {
        if(events[type]) {
            for(let evt of events[type]) {
                if(evt.number && !evt.player && !acc.includes(evt.number.toString())) { acc.push(evt.number.toString()) }
                if(evt.assist && !evt.assistant && !acc.includes(evt.assist.toString())) { acc.push(evt.assist.toString()) }
            }
        }

        return acc
    }, []) : []

    return reduced.length ? reduced : null
}

const checkSquadDuplicated = (squad=[]) => {
    const reduced = squad ? squad.reduce((acc, col) => {
        for(let p of col) {
            if(p.num) {
                if(!acc[p.num.toString()]) { acc[p.num.toString()] = 0 }
                acc[p.num.toString()]++
            }
        }

        return acc
    }, {}) : {}

    const duplicated = Object.entries(reduced).filter(e => e[1] > 1).map(e => e[0])
    return duplicated.length ? duplicated : null
}

const checkExpectedScores = (scores, home, away) => {
    if(!scores.full || typeof(scores.full.home) === 'undefined' || typeof(scores.full.away) === 'undefined' || scores.full.home.toString() === '' || scores.full.away.toString() === '') {
        return {type: 'err', msg: 'Не указан результат матча'}
    } else {
        if(!home || !away || !home.goal || !away.goal) {
            return null
        } else {
            const actualHome = (home.goal.filter(g => !g.owngoal && !g.missedPenalty).length + away.goal.filter(g => g.owngoal).length).toString()
            const actualAway = (away.goal.filter(g => !g.owngoal && !g.missedPenalty).length + home.goal.filter(g => g.owngoal).length).toString()

            if(scores.full.home.toString() !== actualHome || scores.full.away.toString() !== actualAway) {
                return {type: 'err', msg: `Указанный результат ${scores.full.home}:${scores.full.away} не соответствует событиям - ${actualHome}:${actualAway}`}
            } else {
                return null
            }
        }
    }
}

const checkSchema = (schema, squad, full=8) => {
    console.log('checkSchema',schema, squad);
    const getAllNums = lines => {
        //console.log('getAllNums', lines)
        const nums = [];
        for (let key in lines) {
            for (let k =0; k<lines[key].length; k++) {
                if (lines[key][k]['num'] && !lines[key][k]['absent']) {
                    nums.push(lines[key][k]['num'])
                }
            }
        }
        return nums.sort((a,b) => a.num > b.num ? -1 : 1);
    }

    const notdupls = [];
    const overflow = [];

    const squadNums = getAllNums(schema);
    const allSquadNums = getAllNums(squad);

    console.log('checkSchema',squadNums, allSquadNums);
    squadNums.forEach((item, i) => {
        if (notdupls.includes(item.toString())) {
            return false
        } else {
            notdupls.push(item.toString());
        }
        if (i >= full) {
            overflow.push(item);
            return false
        }
        if (!allSquadNums.includes(item.toString())) {
            return false
        }
    });
    return true
}
