import React, { useState, useRef, useEffect } from 'react'
import { Button, Card, Tag, NonIdealState } from '@blueprintjs/core'
import Splash from '../Splash'
import MatchItem from './MatchItem'
import { io } from 'socket.io-client'

const moment = require('moment')

const { SOCK } = require('../../../env')
let socket

const monitStatus = cb => {
    socket.on('list', data => {
        cb(data)
    })
}

const splitter = list => {
    return list.reduce((acc, m) => {
        //if(!m.protocol) { acc.await.push(m) }
        if(m.draft && !m.data) { acc.processing.push(m) }
        switch (true) {
            case m.date === moment().format('YY-MM-DD'):
                acc.today.push(m)
                break
            case m.date === moment().add(-1, 'days').format('YY-MM-DD'):
                acc.yesterday.push(m)
                break
            case m.date === moment().add(1, 'days').startOf('day').format('YY-MM-DD'):
                acc.tomorrow.push(m)
                break
            case moment(m.date, 'YY-MM-DD').unix() < moment().add(-1, 'days').startOf('day').unix():
                acc.older.push(m)
                break
        }
        return acc
    }, {
        processing: [],
        today: [],
        yesterday: [],
        tomorrow: [],
        older: []
    })
}

const contentKeys = [
    {label: 'Завтра', value: 'tomorrow'},
    {label: 'Сегодня', value: 'today'},
    {label: 'Процессинг', value: 'processing'},
    {label: 'Вчера', value: 'yesterday'},
    {label: '7 дней', value: 'older'}
]

const priorities = ['processing', 'today', 'yesterday', 'tomorrow', 'older']

const Matches = ({ setTitle, leagueId }) => {
    const [content, setContent] = useState({})
    const [itemId, setItemId] = useState(null)
    const [active, setActive] = useState('processing')
    const tooltop = useRef(null)

    const initialized = useRef(false)
    const contentHolder = useRef({})

    useEffect(() => {
        socket = io(SOCK+'/matches', {query: {leagueId: leagueId}})

        if(setTitle) {
            setTitle({
                string: 'Матчи',
                onBack: 'resetMode'
            })
        }

        const handleSocketMessage = data => {
            const splitted = splitter(data)

            if(JSON.stringify(splitted) !== contentHolder.current) {
                contentHolder.current = JSON.stringify(splitted)
                const relevant = priorities.find(t => splitted[t].length > 0)
                if(!initialized.current) {
                    initialized.current = true
                    if(relevant) { setActive(relevant) }
                }
                setContent(splitted)
            }
        }

        monitStatus(handleSocketMessage)
    }, [])

    useEffect(() => {
        if(setTitle) {
            setTitle({
                string: 'Матчи',
                onBack: itemId ? () => setItemId(null) : 'resetMode'
            })
        }
    }, [itemId])

    const tooltopTranslate = () => {
        if(tooltop && tooltop.current && tooltop.current.clientWidth > window.outerWidth) {
            const abs = tooltop.current.clientWidth - window.outerWidth
            const mid = contentKeys.length/2
            const idx = contentKeys.findIndex(b => b.value === active)
            return idx < Math.floor(mid) ? abs/2 : idx > Math.ceil(mid) ? 0 - abs/2 : 0
        } else {
            return 0
        }
    }

    const timeSort = arr => {
        return arr.sort((a, b) => {
            return moment(b.date+' '+b.time, 'YY-MM-DD HH:mm').unix() - moment(a.date+' '+a.time, 'YY-MM-DD HH:mm').unix()
        })
    }

    return  <div className='screen screen-column aligned-top'>
                {!itemId ? <div className='tooltop' ref={tooltop} style={{transform: `translateX(${tooltopTranslate()}px)`}}>
                    {contentKeys.map(btn => (
                        <Button intent={btn.value === active ? 'primary' : 'none'} text={btn.label} onClick={() => setActive(btn.value)}  />
                    ))}
                </div> : null}
                <div className={'matches'+(itemId ? ' item' : '')}>
                    {itemId ? <MatchItem _id={itemId} /> : (content[active] && content[active].length > 0) ? timeSort(content[active]).map(m => (
                        <Card elevation={2} className='match-card' interactive={true} onClick={() => setItemId(m._id)}>
                            <div className='row'>
                                <div className='status'>
                                    <span>{m.time}{active === 'older' ? moment(m.date, 'YY-MM-DD').format(' (D.MM)') : ''}</span>
                                </div>
                                <div className='club'>
                                    <div style={{backgroundImage: `url(${m.home.emblem})`}}></div>
                                    <span>{m.home.name}</span>
                                </div>
                                <div className='score'>
                                    <div>{m.score ? m.score.home : '×	'}</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='status'>
                                    <Tag minimal={true} intent={!m.protocol ? 'none' : m.hasData ? m.posted ? 'success' : 'primary' : 'warning'}>{!m.protocol ? 'ждём' : m.hasData ? m.posted ? 'размещён' : 'обработка' : 'модерация'}</Tag>
                                </div>
                                <div className='club'>
                                    <div style={{backgroundImage: `url(${m.away.emblem})`}}></div>
                                    <span>{m.away.name}</span>
                                </div>
                                <div className='score'>
                                    <div>{m.score ? m.score.away : '×'}</div>
                                </div>
                            </div>
                        </Card>
                    )) : (
                        <NonIdealState
                            style={{height: 'calc(100vh - 95px);'}}
                            description='нет матчей в этой категории'
                            icon='history'
                        />
                    )}
                </div>
                <Splash active={!initialized.current} />
            </div>
}

export default Matches
