import { React } from 'react'
import Item from '../../ModerateSheet/Item'
const { REST } = require('../../../env')
const axios = require('axios')

const Passed = ({ data }) => {
    return  <div>
                <Item
                    _data={data.data}
                    onSubmitted={data => {
                        axios.put(`${REST}bot`, {
                            action: 'sendMatch',
                            target: data.matchday.matchId
                        })

                        setTimeout(() => {
                            window.location.replace('/passed')
                        }, 1500)
                    }}
                />
            </div>
}

export default Passed
