import { React, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Button, Tag, Icon } from '@blueprintjs/core'
import axios from 'axios'
import './matchBody.css'
const rest = require('../Workspace/rest')
const { REST } = require('../../env')

const DownloadProtocols = ({ checked, matches, clearChecks}) => {
    const [saving, setSaving] = useState(false);

    const downloadSheets = (matches) => {
        setSaving(true);
        matches.forEach((match) => {
            axios.put(`${REST}utils`, {action: 'downloadMatchSheet', matchId: match._id}, {responseType: 'arraybuffer', headers: {'Accept': 'application/pdf'}})
                .then(response => {
                    if(!response.error) {
                        const blob = new Blob([response.data], {type: 'application/pdf'})
                        const link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = `${match.homeClub.name} vs ${match.awayClub.name}_${match.date}_${match.time}.pdf`
                        link.click()
                        setSaving(false)
                    }
                })
        });
    }
    if (checked.length) {
        return (
            <div style={{marginRight: 5, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <div style={{marginBottom: 5}}>
                    <Button text='Скачать выбранные PDF-протоколы' icon='download' disabled={saving} onClick={() => downloadSheets(matches)} intent='primary' />
                </div>
                <div>
                    <Button text='Сбросить выбранные' disabled={saving} onClick={clearChecks} intent='default' />
                </div>
            </div>
        );
    }
    return (<></>)
}

export default DownloadProtocols;
