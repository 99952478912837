import { React } from 'react'
import League from './League'
import User from './User'
import Division from './Division'
import Passed from './Passed'

const content = {
    leagues: League,
    users: User,
    divisions: Division,
    passed: Passed
}

const Body = ({type, ...rest}) => {
    const Specified = content[type]
    return Specified ? <Specified {...rest} /> : null
}

export default Body
