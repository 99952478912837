import React, { useState, useEffect, useRef } from 'react'

import { Button, InputGroup, HTMLSelect, Checkbox, Popover, Menu, MenuItem, Label, Toaster, ControlGroup } from '@blueprintjs/core';
import { DateInput, TimePicker } from "@blueprintjs//datetime";
import { diff } from 'deep-object-diff';
const rest = require('../../../components/Workspace/rest');
const dot = require('dot-object');
const moment = require('moment');

const StatsForm = ({ initialMatch, initialStat, homeClubFromStat=null, awayClubFromStat=null, patchStatList=null}) => {

    const [formMatch, setFormMatch] = useState({...initialMatch});
    const [cloneMatch, setCloneMatch] = useState({...initialMatch});
    const [formStat, setFormStat] = useState({...initialStat});
    const [cloneStat, setCloneStat] = useState({...initialStat});
    const [statId, setStatId] = useState(initialStat ? initialStat._id : '');
    const [difMatch, updateDifMatch] = useState(null);
    const [difStat, updateDifStat] = useState(null);
    const [termHomeClub, setTermHomeClub] = useState(homeClubFromStat ? homeClubFromStat.name : '');
    const [termAwayClub, setTermAwayClub] = useState(awayClubFromStat ? awayClubFromStat.name : '');
    const [homeClub, setHomeClub] = useState(homeClubFromStat);
    const [awayClub, setAwayClub] = useState(awayClubFromStat);
    const [filtersClubs, setFiltersClubs] = useState([]);
    const [broadcasters, setBroadcasters] = useState([]);
    const [agents, setAgents] = useState([]);
    const toaster = useRef(null);

    useEffect(() => {
        setFormMatch({...initialMatch});
        setCloneMatch({...initialMatch});
        setFormStat({...initialStat});
        setCloneStat({...initialStat});
        setStatId(initialStat ? initialStat._id : '');
        setTermHomeClub(homeClubFromStat ? homeClubFromStat.name : '');
        setTermAwayClub(awayClubFromStat ? awayClubFromStat.name : '');
        setHomeClub(homeClubFromStat);
        setAwayClub(awayClubFromStat);
    }, [initialMatch, initialStat, homeClubFromStat, awayClubFromStat]);

    useEffect(() => {
        if (termHomeClub.length > 1) {
            rest('get',`clubs`)
            .then(clubs => setFiltersClubs(clubs.filter(club => club.name.toLowerCase().includes(termHomeClub.toLowerCase()))));
        } else { setFiltersClubs([])}
    }, [termHomeClub]);

    useEffect(() => {
        if (termAwayClub.length > 1) {
            rest('get',`clubs`)
            .then(clubs => setFiltersClubs(clubs.filter(club => club.name.toLowerCase().includes(termAwayClub.toLowerCase()))));
        } else { setFiltersClubs([])}
    }, [termAwayClub]);

    useEffect(() => {
        rest('get', 'agents')
            .then(items => setAgents([{name:'не выбрано', _id: ''}, ...items]));
    }, []);

    useEffect(() => {
        rest('get', 'broadcasters')
            .then(broads => setBroadcasters([{name:'не выбрано', _id: ''}, ...broads]));
    }, []);

    useEffect(() => {
        updateDifMatch(diff(cloneMatch, formMatch));
    }, [cloneMatch, formMatch])

    useEffect(() => {
        updateDifStat(diff(cloneStat, formStat));
    }, [cloneStat, formStat])

    const handleFilterChange = (evt) => {
        switch (evt.target.name) {
            case 'homeClub':
                setTermHomeClub(evt.target.value);
                setHomeClub(null);
                break;
            case 'awayClub':
                setTermAwayClub(evt.target.value);
                setAwayClub(null);
                break;
            default: console.log(evt.target);
        }
    }
    const handleDateChange = (date) => {
        let nDate = moment(date).format('YY-MM-DD');
        setFormMatch({...formMatch, date:nDate});
    }

    const handleTimeChange = (date) => {
        let nTime = moment(date).format('HH:mm');
        setFormMatch({...formMatch, time:nTime});
    }

    const isEmpty = obj => (obj !== null && !Object.keys(obj).length);

    const takeHomeClub = (club) => {
        setHomeClub(club);
        setTermHomeClub(club.name);
        setFiltersClubs([]);
        setFormMatch({...formMatch, homeId:club._id});
    }
    const takeAwayClub = (club) => {
        setAwayClub(club);
        setTermAwayClub(club.name);
        setFiltersClubs([]);
        setFormMatch({...formMatch, awayId:club._id});
    }

    const changeMatchAndStat = (match, stat) => {
        let agent = null, broadcaster=null;

        if (!isEmpty(match)) {
            rest('put', 'matches', formStat.matchId, match)
                .then(upd => {
                    if (upd._id) {
                        setFormMatch({...formMatch, ...match});
                        setCloneMatch({...formMatch, ...match});
                        toaster.current.show({intent: 'success', message: `матч ${upd._id} обновлен! спасибо`});
                    } else {
                        console.log('callback without ID')
                    }
                })
                .catch(err => {
                    console.log(err);
                    toaster.current.show({intent: 'danger', message: `запись ${formStat.matchId} не обновлена!`})
                });
        }
        if (!isEmpty(stat)) {

            if (!stat.broadcasterId) {
                delete stat.broadcasterId;
            }
            if (!stat.agentId) {
                delete stat.agentId;
            }

            if (stat.price) stat.price= +stat.price;
            if (stat.bounty) stat.bounty = +stat.bounty;

            if (stat.agentId) {
                agents.forEach((item) => {
                    if (item._id === stat.agentId) {
                        agent = {...item};
                    }
                });
            }

            if (stat.broadcasterId) {
                broadcasters.forEach((item) => {
                    if (item._id === stat.broadcasterId) {
                        broadcaster = {...item};
                    }
                });
            }

            rest('put', 'stats', statId, dot.dot(stat))
                .then(upd => {
                    if (upd._id) {
                        setFormStat({
                            ...formStat,
                            ...stat,
                            entity: {
                                ...formStat.entity,
                                ...stat.entity,
                            }
                        });
                        setCloneStat({
                            ...formStat,
                            ...stat,
                            entity: {
                                ...formStat.entity,
                                ...stat.entity,
                            }
                        });
                        toaster.current.show({intent: 'success', message: `стата ${statId} обновлена! спасибо`});
                    } else {
                        console.log('callback without ID')
                    }
                })
                .catch(err => {
                    console.log(err);
                    toaster.current.show({intent: 'danger', message: `запись ${statId} не обновлена!`})
                });
        }
        if (patchStatList) {
            patchStatList(statId, match, stat, agent, broadcaster);
        } else {console.log('binding')}
    }

    const createStat = (stat) => {
        if (!stat.broadcasterId) {
            delete stat.broadcasterId;
        }
        if (!stat.agentId) {
            delete stat.agentId;
        }
        if (!stat._id) {
            delete stat._id;
        }
        stat.price = +stat.price;
        stat.bounty = +stat.bounty;

        if (formMatch.homeId && formMatch.awayId) {
            if (stat.matchId) {
                rest('post', 'stats', null, stat)
                    .then(stat => {
                        setFormStat({...formStat, _id:stat._id});
                        setCloneStat({...formStat, _id:stat._id});
                        setStatId(stat._id);
                        toaster.current.show({intent: 'success', message: `создана запись ${stat._id}! спасибо`});
                    })
                    .catch(err => {
                        toaster.current.show({intent: 'danger', message: `запись ${formStat.matchId} не обновлена!`});
                        console.log(err);
                    });
            } else {
                rest('post', 'matchentries', null, formMatch)
                    .then(match => {
                        if (match._id) {
                            rest('post', 'stats', null, {...stat, matchId:match._id})
                                .then(stat => {
                                    setFormStat({...formStat, matchId:match._id, _id:stat._id});
                                    setStatId(stat._id);
                                    toaster.current.show({intent: 'success', message: `создана запись ${stat._id}! спасибо`});
                                })
                                .catch(err => {
                                    toaster.current.show({intent: 'danger', message: `запись ${formStat.matchId} не обновлена!`});
                                    console.log(err);
                                });
                        } else {
                            console.log('call back with out match ID');
                        }
                    })
                    .catch(err => console.log(err));
            }
        } else {
            toaster.current.show({intent: 'danger', message: `Введите верные значения`});
        }
    }

    const resetForms = () => {
        setHomeClub(null);
        setTermHomeClub('');
        setFiltersClubs([]);
        setFormMatch({...formMatch, ...initialMatch});
        setAwayClub(null);
        setTermAwayClub('');
        setFormStat({...formStat, ...initialStat});
    }

    const handleField = (evt) => {
        let key = evt.target.name;
        if (key.includes('entity.')) {
            let kkey = key.slice(7);
            if (evt.target.type === 'checkbox') {
                setFormStat({
                    ...formStat,
                    'entity':{
                        ...formStat.entity,
                        [kkey]: !formStat.entity[kkey]
                    }
                })
            } else {
                setFormStat({
                    ...formStat,
                    'entity':{
                        ...formStat.entity,
                        [kkey]: +evt.target.value
                    }
                })
            }
        } else {
            setFormStat({...formStat, [key]: evt.target.value});
        }
    }

    return <>
        <div className='incard'>
            <ControlGroup>
                <Popover
                    isOpen={!homeClub && termHomeClub}
                    position='bottom'
                    targetTagName='div'
                    content={
                        <Menu style={{maxHeight: 100, overflowY:'scroll'}}>
                            {filtersClubs.map(club =>
                                <MenuItem
                                    key={club._id}
                                    icon={<img alt='' src={club.emblem || require('../../../assets/color/badge.svg').default} style={{width: 22, height: 22}} />}
                                    text={club.name}
                                    label={club.from ? club.from.city : null}
                                    onClick={() => takeHomeClub(club)}
                                />
                            )}
                        </Menu>
                    }
                >
                    <InputGroup
                        name='homeClub'
                        onChange={handleFilterChange}
                        value={termHomeClub}
                        placeholder='home club'
                        leftIcon='search'
                        intent='primary'
                        fill={true}
                        small={false}
                        autoComplete='off'
                    />
                </Popover>
                <Button icon='cross' />
                <Popover
                    isOpen={!awayClub && termAwayClub}
                    position='bottom'
                    targetTagName='div'
                    content={
                        <Menu style={{maxHeight:100, overflowY:'scroll'}}>
                            {filtersClubs.map(club =>
                                <MenuItem
                                    key={club._id}
                                    icon={<img alt='' src={club.emblem || require('../../../assets/color/badge.svg').default} style={{width: 22, height: 22}} />}
                                    text={club.name}
                                    label={club.from ? club.from.city : null}
                                    onClick={() => takeAwayClub(club)}
                                />
                            )}
                        </Menu>
                    }
                >
                    <InputGroup
                        name='awayClub'
                        onChange={handleFilterChange}
                        value={termAwayClub}
                        placeholder='away club'
                        leftIcon='search'
                        intent='primary'
                        fill={true}
                        small={false}
                        autoComplete='off'
                    />
                </Popover>
            </ControlGroup>
            <div style={{width:215, marginTop:20, marginBottom: 20, display: 'flex', justifyContent:'space-between'}}>
                <DateInput
                    canClearSelection={true}
                    name='date'
                    formatDate={date => moment(date).format('YY-MM-DD')}
                    parseDate={str => new Date(str)}
                    onChange={handleDateChange}
                    value={moment(formMatch.date, 'YY-MM-DD').toDate()}
                    placeholder={"выберите дату матча"}
                    highlightCurrentDay={true}
                />
                <TimePicker
                    onChange={handleTimeChange}
                    value={moment(formMatch.time, 'HH:mm').toDate()}
                />
            </div>
        </div>
        <div>
            <Label style={{width: '45%', marginRight: 10, flexGrow: 0}}>BroadCaster
            <HTMLSelect
                options={
                    broadcasters.map(item => ({value: `${item._id}`, label:`${item.name}`}))
                }
                onChange={handleField}
                value={formStat.broadcasterId}
                name='broadcasterId'
                fill={true}
            />
            </Label>
            <Label style={{width: '45%', marginRight: 10, flexGrow: 0}}>Агент
            <HTMLSelect
                options={agents.map(item => ({value: `${item._id}`, label:`${item.name}`}))}
                onChange={handleField}
                value={formStat.agentId}
                name='agentId'
                fill={true}
            />
            </Label>
            <div style={{display: 'flex', flexWrap:'wrap',  justifyContent:'space-between', width: '100%', marginTop: 10, marginBottom: 5}}>
                <Label style={{width:'33%'}}>Стоимость
                <InputGroup
                    onChange={handleField}
                    placeholder="price"
                    value={formStat.price}
                    name='price'
                    intent='primary'
                    small={false}
                    fill={true}
                    autoComplete='off'
                /></Label>
                <Label style={{width:'33%'}}>Вознаграждение
                <InputGroup
                    onChange={handleField}
                    placeholder="bounty"
                    value={formStat.bounty}
                    name='bounty'
                    intent='primary'
                    small={false}
                    fill={true}
                    autoComplete='off'
                /></Label>
                <Label style={{width:'33%'}}>Длительность
                <InputGroup
                    onChange={handleField}
                    placeholder="duration"
                    value={formStat.entity.duration}
                    name='entity.duration'
                    intent='primary'
                    small={false}
                    fill={true}
                    autoComplete='off'
                /></Label>
                <Checkbox
                    labelElement='offsides'
                    checked={formStat.entity.offsides}
                    onChange={handleField}
                    name='entity.offsides'
                    style={{width: '33%'}}
                />
                <Checkbox
                    labelElement='isProduction'
                    checked={formStat.entity.isProduction}
                    onChange={handleField}
                    name='entity.isProduction'
                    style={{width: '33%'}}
                />
                <Checkbox
                    labelElement='live'
                    checked={formStat.entity.live}
                    onChange={handleField}
                    name='entity.live'
                    style={{width: '33%'}}
                />
            </div>
        </div>
        {statId ? <Button intent='primary' text='Изменить' disabled={isEmpty(difMatch) && isEmpty(difStat)} onClick={() => changeMatchAndStat(difMatch, difStat)} />
        : <Button intent='success' text='Создать стату' onClick={() => createStat(formStat)} />
        }

        <Button intent='default' text='Сбросить формы' onClick={() => resetForms()} />
        <Toaster position='TOP_RIGHT' ref={toaster}></Toaster>
    </>
}

export default StatsForm;
