export const addTypes = [
    {type: 'missedNumWithEvent', label: 'Игрок с событием отсутствует в составе'},
    {type: 'missedPlayer', label: 'Игрок отсутствует в заявке'},
    {type: 'duplicatedNumber', label: 'В составе дублируется номер'},
    {type: 'scoreMissmatch', label: 'Счёт матча не соответствует событиям'},
    {type: 'unreadableGoalAuthor', label: 'Нечитаемый номер автора гола'},
    {type: 'unreadableAssist', label: 'Нечитаемый ассистент'},
    {type: 'custom', label: 'Произвольно'}
]

export const sideTypes = [
    {side: 'home', label: 'Команда хозяев'},
    {side: 'away', label: 'Команда гостей'}
]
