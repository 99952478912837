import { React, useState } from 'react'
import { Card, Button } from '@blueprintjs/core'
import Form from './Forms'

const NewForm = ({ collection, postEntry }) => {
    const [formData, setFormData] = useState(null)

    return  <Card className='overlay-form' elevation={4}>
                <Form collection={collection} throwForm={data => setFormData(data)}/>
                <div className='overlay-form_actions'>
                    <Button text='Создать элемент' intent='success' onClick={() => postEntry(formData)}/>
                </div>
            </Card>
}

export default NewForm
