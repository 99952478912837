import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { NonIdealState, Spinner, Button, Tabs, Tab, Checkbox, InputGroup } from '@blueprintjs/core'
import CustomScrollbars from 'react-custom-scrollbars-2'
import ReactJson from 'searchable-react-json-view'

const rest = require('../Workspace/rest')
const { REST } = require('../../env')

const StandDrawer = props => {
    const [data, setData] = useState(null)
    const [typeFilter, setTypeFilter] = useState(['PARSE ATTACHED PERSON'])
    const [jsonQuery, setJsonQuery] = useState('')
    const [leftTab, setLeftTab] = useState('origin_body')
    const [progress, setProgress] = useState(false)
    const { matchId } = useParams()

    useEffect(() => {
        if(matchId) {
            rest('get', 'snapshots', `?filter[matchId]=${matchId}`)
                .then(resp => {
                    setData(resp)
                })
        }
    }, [matchId])

    const capture = async () => {
        setData(null)
        setProgress(true)
        const snapshot = await rest('post', 'snapshots', null, {matchId: matchId})
        if(snapshot && snapshot._id) {
            setData([snapshot])
        }
        setProgress(false)
    }

    const deleteSnapshot = () => {
        const snapshotId = data[0]._id
        setData(null)
        rest('delete', 'snapshots', snapshotId)
            .then(del => {
                console.log('deleted')
            })
    }

    const boxes = data && data[0] && data[0].workerLog ? data[0].workerLog.reduce((acc, i) => {
        if(!acc.includes(i.type)) {
            acc.push(i.type)
        }

        return acc
    }, []) : []

    const OriginBody = () => {
        return  <div>
                    <img style={{width: '90%'}} src={`https://amateum.fra1.digitaloceanspaces.com/sheets/${matchId}_body.jpg`} />
                </div>
    }

    const OriginEvs = () => {
        return  <div>
                    <img style={{width: 400}} src={`https://amateum.fra1.digitaloceanspaces.com/sheets/${matchId}_events.jpg`} />
                </div>
    }

    return !data || !data[0] ? (
        <NonIdealState
            icon='code-block'
            children={data===null || progress ? (
                <Spinner />
            ) : (
                <Button
                    text='Для матча ещё не создан слепок. Создать?'
                    icon='add'
                    intent='primary'
                    minimal={true}
                    large={true}
                    onClick={() => capture()}
                    disabled={progress}
                />
            )}
        />
    ) : (
        <div style={{width: '100%', display: 'flex'}}>
            <div style={{width: '50%', height: '100vh'}}>
                <CustomScrollbars
                    autoHeight
                    autoHeightMin='100vh'
                >
                    <Tabs selectedTabId={leftTab} onChange={t => setLeftTab(t)} renderActivePanelOnly={true}>
                        <Tab id='origin_body' title='Составы (Origin)' panel={<OriginBody />} />
                        <Tab id='origin_evs' title='События (Origin)' panel={<OriginEvs />} />
                        <Tab id='output_home' title='Output Хозяева' panel={<ReactJson collapsed={true} src={data[0].output.homeSquad} />} />
                        <Tab id='output_away' title='Output Гости' panel={<ReactJson collapsed={true} src={data[0].output.awaySquad} />} />
                        <Tab id='output_events' title='События' panel={<ReactJson collapsed={false} src={data[0].output.events} />} />
                    </Tabs>
                </CustomScrollbars>
            </div>

            <div style={{width: '50%', height: '100vh'}}>
                <CustomScrollbars
                    autoHeight
                    autoHeightMin='100vh'
                >
                    <div style={{display: 'flex', padding: '10px 0'}}>
                        <Button minimal={true} icon='refresh' text='Обновить output' intent='primary' onClick={() => capture()} />
                        <Button minimal={true} icon='cancel' text='Удалить слепок' intent='danger' onClick={() => deleteSnapshot()} />
                    </div>

                    <div style={{display: 'flex', height: 80, alignItems: 'center', paddingRight: 100, flexWrap: 'wrap'}}>
                        {boxes.map(k => (
                            <Checkbox
                                checked={typeFilter.includes(k)}
                                label={k}
                                style={{marginRight: 20}}
                                onChange={() => setTypeFilter(typeFilter.includes(k) ? typeFilter.filter(_k => _k !== k) : typeFilter.concat(k))}
                            />
                        ))}
                    </div>

                    <div style={{margin: '20px 200px 20px 10px'}}>
                        <InputGroup value={jsonQuery} onChange={e => setJsonQuery(e.target.value)} />
                    </div>

                    <ReactJson
                        src={typeFilter.length > 0 ? data[0].workerLog.filter(l => typeFilter.includes(l.type)) : data[0].workerLog}
                        highlightSearch={jsonQuery}
                        groupArraysAfterLength={1000}
                        collapsed={false}
                    />
                </CustomScrollbars>
            </div>
        </div>
    )
}

export default StandDrawer
