import React, { useState } from 'react'

import { Card, Button } from '@blueprintjs/core'

const ClubsList = ({ data, onSelected, onBack }) => {
    return  <div className='list-view'>
                <div className='title'><Button text='Назад' minimal={true} onClick={() => onBack()} icon='chevron-left' intent='primary' />Трансфер из команды:</div>
                {data ? data.map((c => (
                    <Card elevation={2} onClick={() => onSelected(c._id)}>
                        <div className='list-item_img' style={{backgroundImage: `url(${c.emblem})`}}></div>
                        <div className='list-item_main'>{c.name}</div>
                    </Card>
                ))) : null}
            </div>
}

export default ClubsList
