import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Radio, RadioGroup, Card, Checkbox, InputGroup } from '@blueprintjs/core'
import Select, { createFilter } from 'react-select'
import {
  Sidebar,
  ExpansionPanel
} from '@chatscope/chat-ui-kit-react'

import { Button } from '@blueprintjs/core'
const rest = require('../Workspace/rest')

const ROLES = {
    league: [
        {value: 'owner', label: 'Владелец'},
        {value: 'admin', label: 'Администратор'},
        {value: 'media', label: 'Фото / Видео'},
        {value: 'referee', label: 'Арбитр'}
    ],
    club: [
        {value: 'coach', label: 'Тренер'},
        {value: 'admin', label: 'Администратор'},
        {value: 'finances', label: 'Финансы'}
    ]
}

const ManageIntem = ({ type, data }) => {
    return  <div>
            </div>
}

const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'any'
}

const Manage = ({ data }) => {
    const [initRoleMode, setInitRoleMode] = useState(false)
    const [initRole, setInitRole] = useState({
        type: null,
        person: null,
        options: [],
        roles: [],
        selected: null,
        progress: false
    })
    const history = useHistory()

    useEffect(() => {
        if(initRole.type) {
            rest('get', initRole.type+'s')
                .then(options => {
                    setInitRole({
                        ...initRole,
                        options: options,
                        roles: [],
                        selected: null,
                        progress: false
                    })
            })
        }
    }, [initRole.type])

    const senderMeta = data && data.data.filter(m => m.meta)[0] ? data.data.filter(m => m.meta)[0].meta : null

    const createStaff = () => {
        if(senderMeta || (data && data.person)) {
            setInitRole({...initRole, progress: true})

            const body = !data.person._id ? {
                created: new Date().getTime(),
                first_name: initRole.person.split(' ')[0],
                last_name: initRole.person.split(' ')[1],
                roles: initRole.roles.map(r => (
                    {
                        [initRole.type+'Id']: initRole.selected,
                        role: r
                    }
                )),
                invite: null,
                tid: senderMeta.id,
            } : {
                first_name: data.person.first_name,
                tid: data.person.tid,
                roles: initRole.roles.map(r => (
                    {
                        [initRole.type+'Id']: initRole.selected,
                        role: r
                    }
                ))
            }

            const method = !data.person._id ? 'post' : 'put'

            rest(method, 'staffs', data.person._id, body)
                .then(created => {
                    if(created._id || created.success) {
                        history.push('/chats')
                    } else {
                        alert(JSON.stringify(created))
                    }
                })
        }
    }

    return  <Sidebar position='right'>
                {initRoleMode ? (
                    <div style={{padding: 12}}>
                        {!data.person._id ? <div style={{marginBottom: 12, wordBreak: 'break-all'}}>{JSON.stringify(senderMeta)}</div> : null}

                        {!data.person._id ? <InputGroup value={initRole ? initRole.person : null} onChange={e => setInitRole({...initRole, person: e.target.value})} large={true} placeholder='Имя Фамилия' /> : null}

                        {!data.person._id ? <div style={{marginTop: 10}}>
                            <RadioGroup label='Выбери тип' selectedValue={initRole.type} onChange={e => setInitRole({...initRole, type: e.target.value})}>
                                <Radio label='Предаставитель лиги' value='league' />
                                <Radio label='Представитель клуба' value='club' />
                            </RadioGroup>
                        </div> : null}

                        <div style={{marginTop: 12}}>
                            {initRole.type === 'league' ? initRole.options.map(l => (
                                <Card
                                    elevation={1}
                                    style={{display: 'flex', padding: 12, alignItems: 'center', marginTop: 6, background: initRole.selected === l._id ? '#2196F3' : '#fff'}}
                                    interactive={true}
                                    onClick={() => setInitRole({...initRole, selected: l._id})}
                                >
                                    <img style={{width: 40, height: 40}} src={l.emblem} />
                                    <div style={{marginLeft: 20}}>{l.name}</div>
                                </Card>
                            )) : initRole.type === 'club' ? <div style={{minHeight: 50}}>
                                <Select
                                    options={initRole.options.map(t => ({value: t._id, label: t.name+(t.from && t.from.city ? ` (${t.from.city})` : '')}))}
                                    filterOption={createFilter(filterConfig)}
                                    onChange={(item) => setInitRole({...initRole, selected: item.value})}
                                />
                            </div> : null}
                        </div>

                        <div style={{marginTop: 20}}>
                            {initRole.type ? ROLES[initRole.type].map(r => (
                                <Checkbox
                                    label={r.label}
                                    value={r.value}
                                    inline={true}
                                    checked={initRole.roles.indexOf(r.value) > -1}
                                    onChange={e =>  setInitRole({...initRole, roles: e.target.checked ? initRole.roles.concat([r.value]) : initRole.roles.filter(_r => _r !== r.value)})}
                                />
                            )) : null}
                        </div>

                        <Button fill={true} text='Готово, сохранить' intent='primary' large={true} disabled={initRole.progress || (!data.person._id && !initRole.person) || !initRole.selected || initRole.roles.length == 0} onClick={() => createStaff()} />
                    </div>
                ) : null}

                {!data.person._id && !initRoleMode ? (
                    <Button style={{margin: 10}} fill={true} text='Привязать роль' intent='primary' onClick={() => setInitRoleMode(true)} />
                ) : null}


                {data ? data.leagues.map(rel => (
                    <ExpansionPanel title={rel.name}>
                        <ManageIntem
                            type='league'
                            data={null}
                        />
                    </ExpansionPanel>
                )) : null}

                {data.person._id && !initRoleMode ? <Button
                    minimal={true}
                    intent='primary'
                    icon='add'
                    text='Добавить в лигу'
                    large={true} fill={true} style={{marginTop: 10}}
                    onClick={() => {
                        setInitRoleMode(true)
                        setInitRole({...initRole, type: 'league'})
                    }}
                /> : null}

                {data ? data.clubs.map(rel => (
                    <ExpansionPanel title={rel.name}>
                        <ManageIntem
                            type='club'
                            data={null}
                        />
                    </ExpansionPanel>
                )) : null}

                {data.person._id && !initRoleMode ? <Button
                    minimal={true}
                    intent='primary'
                    icon='add'
                    text='Добавить в клуб'
                    large={true}
                    fill={true}
                    style={{marginTop: 10}}
                    onClick={() => {
                        setInitRoleMode(true)
                        setInitRole({...initRole, type: 'club'})
                    }}
                /> : null}
            </Sidebar>
}

export default Manage
