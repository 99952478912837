import { React, useState, useEffect } from 'react'
import { Tab, Tabs, Button, Alert, Overlay, Classes, Drawer } from '@blueprintjs/core'

import './style.css'

import { Scores, Squads, Events } from './content'

const spaces = 'https://amateum.fra1.digitaloceanspaces.com/sheets/'
const moment = require('moment')
const rest = require('../Workspace/rest')

const alerts = {
    scoreMissmatch: {
        text: 'Финальный счёт матча не соответствет количеству голов в событиях!'
    },
    undefinedPlayers: {
        text: 'В событиях есть игроки не внесённые в состав',
    },
    overMins: {
        text: 'В отчёте есть странные значения минут:'
    }
}

const transformSquads = data => {
    const transformToIds = obj => {
        return Object.keys(obj).reduce((acc, n) => {
            const { _id, ...rest } = obj[n]
            acc[_id] = {...rest, num: n.toString()}
            return acc
        }, {})
    }

    return {
        ...data,
        homeSquad: transformToIds(data.homeSquad),
        awaySquad: transformToIds(data.awaySquad),
        mvp: data.mvp || {
            home: null,
            away: null
        }
    }
}

const transformToNums = obj => {
    return Object.keys(obj).reduce((acc, id) => {
        const {num, ...rest} = obj[id]
        acc[num] = {...rest, _id: id}
        return acc
    }, {})
}

const sortEvents = events => {
    return Object.keys(events).reduce((acc, side) => {
        acc[side] = Object.keys(events[side]).reduce((obj, type) => {
            obj[type] = events[side][type].sort((a, b) => {
                let am = parseInt(a.minute)
                let bm = parseInt(b.minute)
                return am - bm
            })
            return obj
        }, {})
        return acc
    }, {})
}

const ModerateItem = ({ _data, toaster, matchId, onSentData }) => {
    const [active, setActive] = useState('squads')
    const [data, setData] = useState(transformSquads(_data))
    const [alert, setAlert] = useState(null)
    const [lightBox, setLightBox] = useState(null)
    const [confirmedOverMins, setConfirmedOverMins] = useState(false)
    const [locked, setLocked] = useState(false)

    const { scores, homeSquad, awaySquad, events, mvp } = data

    useEffect(() => {
        if(_data._id && _data._id !== data._id) {
            setData(transformSquads(_data))
        }
    }, [_data])

    const sendData = (data, isDraft) => {
        setLocked(true)
        rest('put', 'moderate', matchId, {
            data: data,
            isDraft: isDraft
        }).then(resp => {
            setLocked(false)
            if(resp.error) {
                toaster.current.show({intent: 'danger', message: resp.message, timeout: 3000})
            } else {
                toaster.current.show({intent: isDraft ? 'primary' : 'success', message: isDraft ? 'Черновик матча успешно сохранён!' : 'Данные матча отправлены на утверждение!'})
                if(!isDraft) {
                    setTimeout(() => {
                        onSentData()
                    }, 2000)
                }
            }
        })
    }

    const validateMatch = (isDraft=false, isForced=false) => {
        let scoreMissmatch = (!data.scores.options || !data.scores.options.tech) ? ['home', 'away'].map((t, idx) => {
            const op = idx === 0 ? 'away' : 'home'
            if(!data.events[t]) { data.events[t] = {} }
            if(!data.events[op]) { data.events[op] = {} }
            if(!data.events[t].goal) { data.events[t].goal = []}
            if(!data.events[op].goal) { data.events[op].goal = []}
            console.log(data.events)
            return ((data.events[t].goal.filter(g => !g.owngoal && !g.missedPenalty).length + data.events[op].goal.filter(g => g.owngoal).length) === parseInt(data.scores.full[t]))
        }).filter(st => !st) : []

        const overMins = data.events ? ['home', 'away'].reduce((acc, t) => {
            if(data.events[t]) {
                for(let type of ['goal', 'yc', 'rc']) {
                    if(data.events[t][type]) {
                        for(let e of data.events[t][type]) {
                            if(e.minute && parseInt(e.minute) > 99) {
                                acc.push(parseInt(e.minute))
                            }
                        }
                    }
                }
            }
            return acc
        }, []) : []

        if(overMins.length > 0 && !confirmedOverMins) {
            setAlert({
                type: 'overMins',
                data: overMins.join(', '),
                cancelButtonText: 'Спасибо, исправлю!',
                onCancel: () => setAlert(null),
                confirmButtonText: 'Так надо, не приставай!',
                onConfirm: () => {
                    setAlert(null)
                    setConfirmedOverMins(true)
                }
            })
        } else {
            if(scoreMissmatch.length > 0 && !isDraft) {
                setAlert({
                    type: 'scoreMissmatch',
                    confirmButtonText: 'Спасибо, исправлю!',
                    data: JSON.stringify(scoreMissmatch),
                    onConfirm: () => setAlert(null)
                })
            } else {
                let _undef = []
                let _map = ['home', 'away'].map(t => {
                    const nums = Object.keys(data[t+'Squad']).filter(id => !data[t+'Squad'][id].absent).map(id => data[t+'Squad'][id].num)
                    let tps = ['goal', 'yc', 'rc'].map(type => {
                        if(data.events[t][type]) {
                            data.events[t][type].map(e => {
                                if(nums.indexOf(e.number.toString()) === -1) { _undef.push(e.number) }
                                if((type === 'goal') && e.assist && (e.assist !== '') && nums.indexOf(e.assist.toString()) === -1) { _undef.push(e.assist) }
                            })
                        }
                    })
                })

                if(_undef.length > 0 && !isDraft) {
                    setAlert({
                        type: 'undefinedPlayers',
                        data: JSON.stringify(_undef),
                        confirmButtonText: 'Спасибо, исправлю!',
                        onConfirm: () => setAlert(null)
                    })
                    console.log(_undef)
                } else {
                    const confirmedData = {
                        ...data,
                        homeSquad: transformToNums(data.homeSquad),
                        awaySquad: transformToNums(data.awaySquad),
                        events: sortEvents(data.events),
                        submittedByModerator: true
                    }

                    sendData(confirmedData, isDraft)
                }
            }
        }
    }

    const patchScores = (p, s, v) => setData({
        ...data,
        scores: {
            ...data.scores,
            [p]: {
                ...data.scores[p],
                [s]: v
            }
        }
    })

    const distributeMatch = () => {
        validateMatch(false, true)
        setTimeout(() => {
            rest('post', 'posters', data.matchday.matchId+(data.matchday.dryRun ? '?dryRun=true' : ''))
                .then(posted => {
                    rest('put', 'matches', data.matchday.matchId, {posted: true})
                    rest('put', 'delivery', null, {
                        matchId: data.matchday.matchId,
                        date: moment(data.date, 'DD MMMM YYYY').format('YY-MM-DD'),
                        divisionId: data.matchday.divisionId
                    }).then(ready => {
                        alert('Успешно размещено!')
                    })
                })
        }, 3000)
    }

    const Actions = () => {
        return  <div className='bp3-card bp3-elevation-2 moderate-actions row'>
                    <Button
                        text='Сохранить черновикъ'
                        intent='none'
                        onClick={() => validateMatch(true)}
                        disabled={locked}
                    />

                    <Button
                        text='Сохранить и отправить результат'
                        intent='success'
                        onClick={() => validateMatch()}
                        disabled={locked}
                    />
                </div>
    }

    return  <div className='moderate'>
                <Tabs onChange={t => setActive(t)} selectedTabId={active} large={true}>
                    <Tab id='squads' title='Составы команд' panel={
                        <Squads
                            actions={<Actions />}
                            batchMute={(arr, t) => setData({
                                ...data,
                                [t+'Squad']: Object.keys(data[t+'Squad']).reduce((acc, id) => {
                                    acc[id].absent = (arr.indexOf(id) > -1) || acc[id].absent
                                    return acc
                                }, Object.assign({}, data[t+'Squad']))
                            })}
                            zoomImg={() => setLightBox('body')}
                            matchId={data.matchday.matchId}
                            unshiftSquad={(arr, t) => {
                                const _sq = Object.assign({}, data[t+'Squad'])
                                for(let {_id, ...rest} of arr) {
                                    _sq[_id] = rest
                                }
                                setData({...data, [t+'Squad']: _sq})
                            }}
                            patchPlayer={(t, _id, body) => {
                                const patchedSquad = Object.keys(data[t+'Squad']).reduce((acc, id, idx) => {
                                    const findDuplicatedNum = n => {
                                        return Object.keys(acc).slice(0, idx).findIndex(i => acc[i].num === n) > -1
                                    }

                                    let origin = Object.assign({}, data[t+'Squad'][id])
                                    if(_id === id) {
                                        for(let key in body) { origin[key] = body[key] }
                                    }

                                    acc[id] = {...origin, duplicated: origin.num ? findDuplicatedNum(origin.num) : false}
                                    return acc
                                }, {})

                                const patchedEvents = data.events[t] ? body.num ? Object.keys(data.events[t]).reduce((acc, type) => {
                                    acc[type] = data.events[t][type].map(e => {
                                        const targetPersonId = e.number ? Object.keys(patchedSquad).find(k => patchedSquad[k].num && e.number.toString() === patchedSquad[k].num.toString()) : null
                                        const targetAssistId = e.assist ? Object.keys(patchedSquad).find(k => patchedSquad[k].num && e.assist.toString() === patchedSquad[k].num.toString()) : null

                                        return {
                                            ...e,
                                            player: targetPersonId ? patchedSquad[targetPersonId] : null,
                                            assistant: targetAssistId ? patchedSquad[targetAssistId] : null
                                        }
                                    })

                                    return acc
                                }, {}) : Object.assign({}, data.events[t]) : {goal: [], yc: [], rc: []}

                                setData({
                                    ...data,
                                    [t+'Squad']: patchedSquad,
                                    events: {
                                        ...data.events,
                                        [t]: patchedEvents
                                    }
                                })
                            }}
                            scores={scores}
                            patchScores={patchScores}
                            squads={{
                                home: homeSquad,
                                away: awaySquad
                            }}
                        />
                    } />
                    <Tab id='events' title='События матча' panel={
                        <Events
                            toaster={toaster}
                            actions={<Actions />}
                            zoomImg={() => setLightBox('events')}
                            matchId={data.matchday.matchId}
                            setEvents={(t, action, type, idx, body) => {
                                let evs = data.events[t][type] ? Object.assign([], data.events[t][type]) : []
                                switch(action) {
                                    case 'add':
                                        evs.push({minute: 1, number: null,  assist: null})
                                        break
                                    case 'del':
                                        evs = evs.filter((e, _idx) => _idx !== idx)
                                        break
                                    case 'put':
                                        if(evs[idx]) {
                                            for(let k in body) {
                                                evs[idx][k] = body[k]
                                                const targetPersonKey = k === 'assist' ? 'assistant' : k === 'number' ? 'player' : null
                                                const _squad = t === 'home' ? homeSquad : awaySquad
                                                const person = targetPersonKey ? Object.keys(_squad).find(id => _squad[id].num === body[k].toString()) : null
                                                if(person) {
                                                    evs[idx][targetPersonKey] = _squad[person] ? {..._squad[person], _id: person} : null
                                                }
                                            }
                                        }
                                        break
                                }

                                setData({
                                    ...data,
                                    events: {
                                        ...data.events,
                                        [t]: {
                                            ...data.events[t],
                                            [type]: evs
                                        }
                                    }
                                })
                            }}
                            scores={scores}
                            patchScores={patchScores}
                            events={events}
                            mvp={mvp}
                            setMvp={(k, v) => setData({...data, mvp: {...data.mvp, [k]: v}})}
                            squads={{
                                home: homeSquad,
                                away: awaySquad
                            }}
                        />
                    } />
                </Tabs>

                <Alert
                    {...alert}
                    isOpen={alert}
                    intent='danger'
                >
                    {alert ? <div>{alerts[alert.type].text}</div> : null}
                    {alert ? <div>{alert.data || null}</div> : null}
                </Alert>

                <Overlay
                    isOpen={lightBox}
                    onClose={() => setLightBox(null)}
                    canOutsideClickClose={true}
                    usePortal={false}
                >
                    {lightBox ? <div style={{display: 'flex', alignItems: 'center', left: 0, top: 0, bottom: 0, right: 0, justifyContent: 'center'}}><img src={`${spaces}${data.matchday.matchId}_${lightBox}.jpg`} /></div> : null}
                </Overlay>
            </div>
}

export default ModerateItem
