const mod = {
    transferMode: (data, stored) => {
        const mapped = data.map(item => {
            return {
                ...item.squads[0].clubData,
                squads: item.squads.map(({players, ...rest}) => {
                    return {
                        players: players,
                        _id: rest._id,
                        openDate: rest.openDate || null,
                        closeDate: rest.closeDate || null,
                        season: {
                            name: rest.seasonData[0].name,
                            _id: rest.seasonData[0]._id
                        }
                    }
                })
            }
        }).map(({squads, ...rest}) => ({...rest, squad: squads[squads.length - 1]}))

        return {
            list: mapped.filter(c => c._id !== stored.clubId),
            self: mapped.find(c => c._id === stored.clubId)
        }
    }
}

export default mod
