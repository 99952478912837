import React, { useState, useEffect, useContext } from 'react'
import ModerContext from './moderContext'
import { Button } from '@blueprintjs/core'
import { diff } from 'deep-object-diff';

const keys = [['gk','ГК'], ['defs','ЗЩ'], ['mdls','ПЗ'], ['frws','НП']];
//const full = 8;

const getAllNums = lines => {
        const nums = [];
        for (let key in lines) {
            for (let k =0; k<lines[key].length; k++) {
                if (lines[key][k]['num'] && !lines[key][k]['absent']) {
                    nums.push(lines[key][k]['num'])
                }
            }
        }
        return nums.sort((a,b) => a.num > b.num ? -1 : 1);
}
const SquadLines = ({path, squad, toaster, schemaQty}) => {
    const ctx = useContext(ModerContext)
    const [schema, setSchema] = useState(null);
    const [curSchema, setCurSchema] = useState(null);
    const [squadNums, setNums] = useState([]);
    const [valid, setValid] = useState(false);
    const [allSquadNums, setAllSquadNums] = useState(null);

    useEffect(() => {
        if (path.includes('homeSquad')) {
            const homeSchema = copy(ctx['homeSchema'])
            setSchema(homeSchema);
        } else {
            const awaySchema = copy(ctx['awaySchema']);
            setSchema(awaySchema)
        }
    }, [path, ctx[path.slice(0,4)+'Schema']])

    useEffect(() => {
        if (schema) {
            const allnums = getAllNums(schema);
            const squadnums = squad.map(item => item.num)
            setAllSquadNums(squadnums)
            setNums(allnums);
            const validated = validate(schema, allnums, squadnums)
            if (allnums.length === schemaQty && !validated.danger && !validated.dupl) {
                setCurSchema(schema);
            } else {
                setCurSchema(validated.updLines);
            }
        }
    }, [schema, squad])

    useEffect(() => {
        if (curSchema) {
            setValid(false);
            const allnums = getAllNums(curSchema);
            setNums(allnums);
            const validated = validate(curSchema, allnums, allSquadNums);
            if (allnums.length === schemaQty && !validated.danger && !validated.dupl) {
                setValid(true);
            } else {
                const difcur = diff(curSchema, validated.updLines)
                setValid(false);
                checkSchema(curSchema);
                if (Object.keys(difcur).length) {
                    setCurSchema(validated.updLines);
                }
            }
        }
    }, [curSchema, allSquadNums])

    useEffect(() => {
        if (valid) {
            const validSchema = updateLines(curSchema);

            const dif = diff(schema, validSchema);
            if (Object.keys(dif).length) {
                if (path.includes('homeSquad')) {
                    ctx.setCtx({homeSchema: validSchema, homeSchemaValid: true});
                } else if (path.includes('awaySquad')) {
                    ctx.setCtx({awaySchema: validSchema, awaySchemaValid: true})
                }
            } else {
                if (path.includes('homeSquad')) {
                    ctx.setCtx('homeSchemaValid', true);
                } else if (path.includes('awaySquad')) {
                    ctx.setCtx('awaySchemaValid', true)
                }
            }
        } else {
            if (path.includes('homeSquad')) {
                ctx.setCtx('homeSchemaValid', false);
            } else if (path.includes('awaySquad')) {
                ctx.setCtx('awaySchemaValid', false)
            }
        }
    },[valid])

    const copy = obj => {
        return JSON.parse(JSON.stringify(obj))

    }
    const onHandle = ({target}) => {
        const {value} = target;
        const evt = target.name.split('_');
        const key = evt[0];
        const index = +evt[1];
        const changedSchema = {
            ...curSchema,
            [key]: curSchema[key].map((item, idx) => {
                if (index === idx) {
                    if (value) {
                        return {...item, num: value}
                    } else {
                        return {num: '', remoteId: ''}
                    }
                } else {
                    return item
                }
            })
        };
        const squadNumbs = getAllNums(changedSchema);
        const validated = validate(changedSchema, squadNumbs, allSquadNums);
        setCurSchema(validated.updLines);
    }

    const updateLines = (curSchema) => {
        const updLines = {};
        for (let key in curSchema) {
            const updLine = curSchema[key].map(line => {
                if (line.num) {
                    const ind = squad.findIndex(pl => line.num && +pl.num === +line.num);
                    if (ind > -1) {
                        return {num: squad[ind]['num'], remoteId: squad[ind]['remote']['id']}
                    } else {
                        return {num: '', remoteId: ''}
                    }
                } else {
                    return {num: line.num || '', remoteId: line.remoteId || ''}
                }});
            updLines[key] = updLine
        }
        return updLines;
    }

    const fillLines = (curSchema) => {
        const updLines = {};
        const max = 4;
        let cur = 0;
        for (let key in curSchema) {
            const updLine = [];
            if (key === 'gk') {
                updLine.push({num: squad[cur]['num'], remoteId: squad[cur]['remote']['id']});
                cur++;
                updLines[key] = updLine
                continue;
            } else {
                for ( let i = 0; i < max; i++) {
                    if (i < max-1 && cur < schemaQty) {
                        updLine.push({num: squad[cur]['num'], remoteId: squad[cur]['remote']['id']})
                        cur++;
                    } else {
                        updLine.push({num: '', remoteId: ''})
                    }
                }
                updLines[key] = updLine
            }
        }
        setCurSchema(updLines);
        return updLines;
    }

    const validate = (schema, squadnums, allsquadnums) => {
        const updLines = {};
        const dupls = [];
        const notdupls = [];
        const overflow = [];
        let danger = [];
        squadnums.forEach((item, i) => {
            if (notdupls.includes(item.toString())) {
                dupls.push(item.toString());
            } else {
                notdupls.push(item.toString());
            }
            if (i >= schemaQty) {
                overflow.push(item)
            }
        });
        for (let key in schema) {
            const updLine = schema[key].map((line, ind) => {
                if (line.num) {
                    let updLine = {...line};
                    if (!allsquadnums.includes(line.num.toString())) {
                        updLine.danger = true;
                    } else if (line.danger) {
                        updLine.danger = false;
                    }
                    if (dupls.includes(line.num.toString())) {
                        updLine.duplicate = true
                    } else if (line.duplicate){
                        updLine.duplicate = false
                    }
                    if (overflow.includes(line.num.toString())) {
                        updLine.overflow = true
                    } else if (line.overflow){
                        updLine.overflow = false
                    }
                    return updLine;
                } else {
                    return {num: '', remoteId: ''}
                }
            });
            danger.push(updLine.findIndex(item => item.danger) > -1 ? 1 : 0);
            updLines[key] = updLine
        }

        return {
            updLines: updLines,
            dupl: dupls.length > 0 ? true : false,
            danger: danger.includes(1),
            overflow: overflow.length > 0 ? true : false,
        };
    }

    const checkSchema = (schema) => {
        const squadnums = getAllNums(schema);
        const validated = validate(schema, squadnums, allSquadNums);

        if (squadnums.length === schemaQty && !validated.danger && !validated.dupl) {
            toaster.current.show({intent: 'success', message: 'Схема заполнена корректно'})
        } else {
            // let mes = squadnums.length !== schemaQty ? `добавлено не ${schemaQty} игроков\n`: '';
            // if (validated.danger) {
            //     mes += 'Добавлены номера, которых нет в заявке\n';
            // }
            // if (validated.dupl) {
            //     mes += 'Добавлены дубли номеров';
            // }
            // toaster.current.show({intent: 'warning', message:`Проверьте схему:\n${mes}`})
            console.log('Invalid scheme replacement...')
        }
    }

    return curSchema ? <div className='squad-lines'>
        {keys.map(([key, val]) => {
            if (curSchema[key]) {
                return (
                    <div key={key}>
                        <b style={{marginRight: 5}}>{val}</b>
                        {curSchema[key].map((line, idx) => {
                            const dupl = line.duplicate ? 'warning' : '';
                            const dang = line.danger ? 'danger' : '';
                            const overflow = line.overflow ? 'overflow' : '';

                            return <div key={key +'_'+ idx} className={`squad-lines_${key}`}>
                                <div>
                                    <input
                                        type='number'
                                        min={1}
                                        max={99}
                                        value={line.num ? line.num : ''}
                                        onChange={onHandle}
                                        className={`${dupl} ${dang} ${overflow}`}
                                        name={key +'_'+ idx}
                                    />
                                </div>
                            </div>})
                        }
                    </div>
                )
            } else {
                return null
            }
        })}
        {<Button
            icon='refresh'
            large={true}
            text='проверить схему'
            minimal={true}
            onClick={() => checkSchema(curSchema)}
            outline
            intent='primary'
        />}
        <Button
            icon='archive'
            large={true}
            text='Заполнить случайно'
            minimal={true}
            onClick={() => fillLines(curSchema)}
            outline
            intent='success'
        />
    </div> :  null
}

export default SquadLines;
