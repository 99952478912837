import React, { useState, useEffect } from 'react'
import { NonIdealState, Button, Tabs, Tab, Spinner } from '@blueprintjs/core'
import axios from 'axios'

const btns = [
    {
        label: 'РЖД. Высший Дивизион',
        subject: '61af4743d0994c069e3d2d56',
        group: true,
        useRender: 'winter'
    },
    {
        label: 'РЖД. Первый Дивизион',
        subject: '61af474dd0994c069e3d2d57',
        group: true,
        useRender: 'winter'
    },
    {
        label: 'Коломяги. Высший Дивизион',
        subject: '61aa26bd81b83f27207da2ec',
        group: true,
        useRender: 'winter'
    },
    {
        label: 'Коломяги. Первый Дивизион',
        subject: '61aa26c881b83f27207da2ed',
        group: true,
        useRender: 'winter'
    },
    {
        label: 'Звезда',
        subject: '622b374441bcab4aca51469c',
        useRender: 'winter',
        group: true
    }
    // {
    //     label: 'Северо-Запад. Высший дивизион',
    //     subject: '6114ed205084ef4dd8dab91c',
    //     group: true
    // },
    // {
    //     label: 'Северо-Запад. Первый дивизион',
    //     subject: '6114ed9f5084ef4dd8dab91d',
    //     group: true
    // },
    // {
    //     label: 'Северо-Восток. Высший дивизион',
    //     subject: '611501b95084ef4dd8dab959',
    //     group: true
    // },
    // {
    //     label: 'Северо-Восток. Первый дивизион',
    //     subject: '611501c15084ef4dd8dab95a',
    //     group: true
    // },
    // {
    //     label: 'Юг. Высший дивизион',
    //     subject: '6114eef75084ef4dd8dab921',
    //     group: true
    // },
    // {
    //     label: 'Юг. Первый дивизион',
    //     subject: '6114eefe5084ef4dd8dab922',
    //     group: true
    // },
    // {
    //     label: 'Юго-Восток. Высший дивизион',
    //     subject: '6114ff855084ef4dd8dab954',
    //     group: true,
    //     lockSchedule: true
    // },
    // {
    //     label: 'Юго-Восток. Первый дивизион',
    //     subject: '6114ff8d5084ef4dd8dab955',
    //     group: true,
    //     lockSchedule: true
    // }
]

const previewBtns = [
    {
        label: 'Обычные посты',
        subject: '615b156ba6847d71e4e20d8e',
        mode: 'matchPreview'
    },
    {
        label: 'Формат сториз',
        subject: '615b156ba6847d71e4e20d8e',
        mode: 'matchPreviewStories'
    }
]

const previewsAllowed = ['615b156ba6847d71e4e20d8e']
const standingsLocked = ['615b156ba6847d71e4e20d8e']
const allowedLeagues = ['6141d65961a3b4492a7b6251', '606f1b571d4588670727d825', '614add35e8fce74a8ba7289a', '614b3226e8fce74a8ba728a8', '615b156ba6847d71e4e20d8e', '617264ade217f47c5fe5cb3a']

const BtnWrap = ({ type, b, setLock, tid, toaster }) => {
    const [load, setLoad] = useState(false)

    const handle = () => {
        setLoad(true)
        axios.put('https://rest.amateum.com/utils', {
            action: 'renderGraphics',
            target: tid,
            subjectId: b.subject,
            group: b.group,
            mode: b.mode || type,
            useRender: b.useRender || null
        }).then(resp => {
            setLoad(false)
            toaster.current.show({intent: resp.data.error ? 'danger' : 'success', message: resp.data.message, timeout: 3000})
        })
    }

    return [
        <Button loading={load} style={{marginBottom: 13}} fill={true} large={true} text={b.label} onClick={() => handle()} />,
        load ? <div className='btns-lock'></div> : null
    ]
}

const DynamicBtns = ({ leagueId, tid, toaster, setLock, type }) => {
    const [divs, setDivs] = useState(null)

    useEffect(() => {
        axios.get(`https://rest.amateum.com/divisions?filter[leagueId]=${leagueId}`)
            .then(dvs => {
                setDivs(dvs.data)
            })
    }, [])

    return  divs ? (
            <div className='buttons'>
                {divs.map(div => (
                    <BtnWrap b={{label: div.name, group: false, subject: div._id}} setLock={setLock} tid={tid} toaster={toaster} type={type} />
                ))}
            </div>
        ) : (
            <NonIdealState icon={<Spinner />} description='загрузка...' style={{height: '90vh'}} />
        )
}

const Graphic = ({ leagueId, tid, toaster }) => {
    const [lock, setLock] = useState(false)
    const [type, setType] = useState('schedule')

    const _btns = type === 'previews' ? previewBtns : type === 'schedule' ? btns.filter(b => !b.lockSchedule) : Object.assign([], btns)

    const Btns = ({ type }) => {
        return  <div className='buttons'>
                    {_btns.map(b => (
                        <BtnWrap b={b} type={type} setLock={setLock} tid={tid} toaster={toaster} />
                    ))}
                </div>
    }

    return  <div className='screen'>
                {allowedLeagues.includes(leagueId) ? (
                    <Tabs selectedTabId={type} renderActiveTabPanelOnly={true} onChange={t => setType(t)}>
                        <Tab
                            id='schedule'
                            title='Расписание'
                            panel={leagueId === '606f1b571d4588670727d825' ? <Btns type='schedule' /> : <DynamicBtns
                                type='schedule'
                                leagueId={leagueId}
                                tid={tid}
                                setLock={setLock}
                                toaster={toaster}
                            />}
                        />
                        {!standingsLocked.includes(leagueId) ? (
                            <Tab id='standings' title='Турнирная таблица' panel={leagueId === '606f1b571d4588670727d825' ? <Btns type='standings' /> : <DynamicBtns
                                type='standings'
                                leagueId={leagueId}
                                tid={tid}
                                setLock={setLock}
                                toaster={toaster}
                            />}
                        />) : null}
                        {previewsAllowed.includes(leagueId) ? (
                            <Tab id='previews' title='Афиши матчей' panel={<Btns type='previews' />} />
                        ) : null}
                    </Tabs>
                ) : (
                    <NonIdealState description='в разработке' icon='wrench' style={{height: '90vh'}} />
                )}
            </div>
}

export default Graphic
