import React, { useState, useEffect, useRef } from 'react'
import { Card, Icon, Tag } from '@blueprintjs/core'
import moment from 'moment'
import './style.css'

const rest = require('../Workspace/rest')

const icons = {
    success: 'tick-circle',
    neutral: 'double-chevron-right',
    danger: 'delete',
    warning: 'warning-sign'
}

const dateFromObjectId = (objectId) => {
	return moment(new Date(parseInt(objectId.substring(0, 8), 16) * 1000)).format('DD.MM.YY HH:mm:ss')
}

const Item = ({ data }) => {
    return  <Card className='logs-item' elevation={1}>
                <div style={{width: 40}}><Icon icon={icons[data.intent]} intent={data.intent === 'neutral' ? 'none' : data.intent} /></div>
                <div style={{width: 160}}>{dateFromObjectId(data._id)}</div>
                <div style={{width: 180}}><Tag minimal={true} large={true}>{data.area}</Tag></div>
                <div style={{width: 180}}>{data.subject}</div>
                <div style={{width: 200}}>{data.subjectType}</div>
                <div style={{width: 'calc(100% - 580px)', maxHeight: 50, overflow: 'hidden', opacity: .8}}>{data.data}</div>
            </Card>
}

const Logs = props => {
    const [data, setData] = useState([])

    useEffect(() => {
        const int = setInterval(() => {
            rest('get', 'logs')
                .then(resp => {
                    setData(data => {
                        if((data.length !== resp.length) || (data[0] && resp[0] && (data[0]._id !== resp[0]._id))) {
                            console.log('Updating')
                            return resp
                        } else {
                            return data
                        }
                    })
                })
        }, 3000)
        return () => clearInterval(int)
    }, [])

    return  <div className='logs'>
                {data.map(i => (
                    <Item
                        key={i._id}
                        data={i}
                    />
                ))}
            </div>
}

export default Logs
