import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Card, InputGroup, ControlGroup, RadioGroup, Radio, Button } from '@blueprintjs/core'
import Splash from '../Splash'
import { diff } from 'deep-object-diff'
import InputMask from 'react-input-mask'

import rest from '../../../components/Workspace/rest'

const positions = [
    {label: 'Вратарь', value: 'gk'},
    {label: 'Защитник', value: 'df'},
    {label: 'Полузащитник', value: 'mf'},
    {label: 'Нападающий', value: 'fw'}
]

const Player = ({ data, context, actionId }) => {
    const [form, setForm] = useState({})
    const [init, setInit] = useState(null)
    const [splash, setSplash] = useState(null)

    useEffect(() => {
        const {squads, ...rest} = data
        const actualSquad = squads ? squads.find(s => s._id === context.squadId) : {players: []}
        let num
        let idx
        if(actualSquad) {
            idx = actualSquad.players.findIndex(p => p._id.toString() === rest._id.toString())
            if(idx > -1) { num = actualSquad.players[idx].num }
        }

        const initial = {
            ...rest,
            num: num,
            squadIdx: idx
        }

        setInit(initial)
        setForm(initial)
    }, [])

    const handleField = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const closeAction = (status) => {
        setSplash('success')
        if(actionId) {
            rest('put', 'hooks', 'actions/'+actionId)
                .then(hooked => {
                    return
                })
        }
        setTimeout(() => {
            window.close()
        }, 2000)
    }

    const savePlayer = ({num, ...body}) => {
        setSplash('brand')
        if(context.playerId) {
            rest('put', 'players', context.playerId, body)
                .then(upd => {
                    if(num) {
                        rest('put', 'squads', context.squadId, {['players.'+form.squadIdx+'.num']: num})
                            .then(upd => {
                                closeAction('success')
                            })
                        } else {
                            closeAction('success')
                        }
                    })
        } else {
            rest('post', 'players', null, {...form, squadId: context.squadId, seasonId: context.seasonId, clubId: context.clubId})
                .then(created => {
                    closeAction('success')
                })
        }
    }

    const d = diff(init, form)

    return  <div className='screen list column'>
                <Helmet>
                    <title>{context.playerId ? (form.name ? form.name+' | ' : '')+'Редактирование профиля' : 'Новый игрок'}</title>
                </Helmet>
                <Button
                    className='cancel-action'
                    minimal={true}
                    icon='cross'
                    intent='none'
                    onClick={() => {
                        window.close()
                    }}
                />
                <div className='screen-image slidable-up'>
                    <img src={data.image ? data.image.replace(/img_c_\d+x\d+/, 'img_c_250x250') : require('../../../assets/color/player.svg').default} />
                </div>
                <div className='screen-title'>{context.playerId ? 'Редактировать профиль' : 'Новый игрок'}</div>
                <Card elevetion={2} className='content-card' style={{paddingBottom: 0}}>
                    <InputGroup value={form.name} name='name' onChange={handleField} large={true} placeholder='Фамилия Имя' />
                    <InputGroup value={form.surname} name='surname' onChange={handleField} placeholder='Отчество' large={true} />
                    <ControlGroup>
                        <InputMask value={form.birthday} mask='99.99.9999' onChange={handleField}>
                            {(inputProps) => <InputGroup  name='birthday' placeholder='Дата рождения' large={true} />}
                        </InputMask>
                        <InputGroup value={form.num} name='num' onChange={handleField} placeholder='Игровой номер' large={true} />
                    </ControlGroup>
                    <RadioGroup inline={true} name='position' onChange={handleField} selectedValue={form.position} label='Позиция'>
                        {positions.map(p => (
                            <Radio value={p.value} label={p.label} large={true} />
                        ))}
                    </RadioGroup>
                </Card>

                <div className='list-footer xl'>
                    <Button disabled={Object.keys(d).length === 0} intent='default' className='xl' large={true} text={context.playerId ? 'Сохранить изменения' : 'Создать профиль'} fill={true} onClick={() => savePlayer(d)} />
                </div>

                <Splash active={splash} status={splash} />
            </div>
}

export default Player
