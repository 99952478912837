import React, { useState, useEffect } from 'react'

import { Card, Button, Popover, Menu, MenuItem, InputGroup, ControlGroup, Label, TextArea } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'

import { addTypes, sideTypes } from './utils'

const rest = require('../Workspace/rest')

const sideAreas = ['missedNumWithEvent', 'unreadableGoalAuthor', 'unreadableAssist', 'missedPlayer', 'duplicatedNumber']
const numAreas = ['missedNumWithEvent', 'missedPlayer', 'duplicatedNumber']
const minAreas = ['unreadableGoalAuthor', 'unreadableAssist']

const AddContent = ({ form, setFormKey, setForm }) => {
    const type = form ? form.type : null
    const [value, setValue] = useState(null)

    useEffect(() => {
        if(type === 'scoreMissmatch') {
            setValue({
                origin: {home: 0, away: 0},
                fact: {home: 0, away: 0}
            })
        } else {
            setValue(null)
        }
    }, [type])

    if(form) {
        switch(true) {
            case !form.side && sideAreas.includes(form.type):
                return  <Popover
                            position='bottom'
                            content={(
                                <Menu>
                                    {sideTypes.map((i, idx) => (
                                        <MenuItem key={idx+'_sideType'} text={i.label} onClick={() => setFormKey('side', i.side)} />
                                    ))}
                                </Menu>
                            )}
                            isOpen={true}
                        >
                            <Button icon='delete' intent='danger' minimal={true} text='отменить' onClick={() => setForm(null)} />
                        </Popover>
                break
            case !form.num && numAreas.includes(form.type):
                return  <Popover
                            position='bottom'
                            content={(
                                <div style={{padding: 20}}>
                                    <ControlGroup>
                                        <InputGroup value={value} placeholder='номер...' large={true} inputProps={{type: 'number'}} onChange={(e) => setValue(e.target.value)} />
                                        <Button intent='success' icon='confirm' minimal={true} large={true} disabled={!value} onClick={() => {setFormKey('num', value); setValue(null);}} />
                                    </ControlGroup>
                                </div>
                            )}
                            isOpen={true}
                        >
                            <Button icon='delete' intent='danger' minimal={true} text='отменить' onClick={() => setForm(null)} />
                        </Popover>
                break
            case !form.minute && minAreas.includes(form.type):
                return  <Popover
                            position='bottom'
                            content={(
                                <div style={{padding: 20}}>
                                    <ControlGroup>
                                        <InputGroup value={value} placeholder='минута...' large={true} inputProps={{type: 'number'}} onChange={(e) => setValue(e.target.value)} />
                                        <Button intent='success' icon='confirm' minimal={true} large={true} disabled={!value} onClick={() => {setFormKey('minute', value); setValue(null);}} />
                                    </ControlGroup>
                                </div>
                            )}
                            isOpen={true}
                        >
                            <Button icon='delete' intent='danger' minimal={true} text='отменить' onClick={() => setForm(null)} />
                        </Popover>
                break
            case !form.scores && form.type === 'scoreMissmatch':
                return  <Popover
                            position='bottom'
                            content={(
                                value ? <div style={{padding: 20}}>
                                    <Label>
                                        Счёт в протоколе:
                                        <ControlGroup>
                                            <InputGroup value={value.origin.home} onChange={e => setValue({...value, origin: {...value.origin, home: e.target.value}})} />
                                            <InputGroup value={value.origin.away} onChange={e => setValue({...value, origin: {...value.origin, away: e.target.value}})} />
                                        </ControlGroup>
                                    </Label>
                                    <Label>
                                        Счёт по событиям:
                                        <ControlGroup>
                                            <InputGroup value={value.fact.home} onChange={e => setValue({...value, fact: {...value.fact, home: e.target.value}})} />
                                            <InputGroup value={value.fact.away} onChange={e => setValue({...value, fact: {...value.fact, away: e.target.value}})} />
                                        </ControlGroup>
                                    </Label>
                                    <Button intent='success' minimal={true} text='Добавить' icon='confirm' onClick={() => setFormKey('scores', value)}/>
                                </div> : null
                            )}
                            isOpen={true}
                        >
                            <Button icon='delete' intent='danger' minimal={true} text='отменить' onClick={() => setForm(null)} />
                        </Popover>
                break
            default:
                return  <Popover
                            position='bottom'
                            content={(
                                <div style={{padding: 20}}>
                                    <div style={{marginBottom: 12}}>
                                        <TextArea
                                            value={value}
                                            placeholder='Введите текст'
                                            growVertically={true}
                                            large={true}
                                            onChange={e => setValue(e.target.value)}
                                        />
                                    </div>
                                    <Button intent='success' minimal={true} text='Добавить' icon='confirm' onClick={() => setFormKey('custom', value)}/>
                                </div>
                            )}
                            isOpen={true}
                        >
                            <Button icon='delete' intent='danger' minimal={true} text='отменить' onClick={() => setForm(null)} />
                        </Popover>
        }
    } else {
        return <Select
                    items={addTypes}
                    itemRenderer={i => (
                        <MenuItem text={i.label} onClick={() => setForm({type: i.type})} />
                    )}
                    filterable={false}
                >
                    <Button icon='add' intent='primary' minimal={true} text='Добавить' />
                </Select>
    }
}

const Refinement = ({ matchId, toaster }) => {
    const [items, setItems] = useState([])
    const [form, setForm] = useState(null)
    const [sending, setSending] = useState(null)
    const [sheetSender, setSheetSender] = useState(null)

    useEffect(() => {
        rest('get', 'matches', matchId)
            .then(resp => {
                setSheetSender(resp.sender)
            })
    }, [])

    const postRefinements = () => {
        setSending(true)
        rest('post', 'refinements', null, {
            matchId: matchId,
            items: items.map(({key, ...i}) => i),
            tid: sheetSender
        }).then(resp => {
                setSending(false)
                toaster.current.show({intent: resp.error ? 'danger' : resp.success ? 'success' : 'warning', message: resp.message})
                if(resp.success) {
                    setItems([])
                }
            })
    }

    const setFormKey = (k, v) => {
        switch (true) {
            case k === 'minute' && minAreas.includes(form.type):
            case k === 'num' && numAreas.includes(form.type):
            case k === 'scores' && form.type === 'scoreMissmatch':
            case k === 'custom':
                setItems(items.concat({...form, [k]: v, key: new Date().getTime()}))
                setForm(null)
                break
            default:
                setForm({...form, [k]: v})
        }
    }

    return  <Card elevation={2}>
                <div className='title' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    Уточнения
                    <div style={{display: 'flex'}}>
                        <AddContent form={form} setForm={setForm} setFormKey={setFormKey} />
                        <Button disabled={items.length === 0 || sending || !sheetSender} icon='send-message' loading={sending} onClick={() => postRefinements()} intent='success' minimal={true} text='Отправить' />
                    </div>
                </div>
                <div>
                    {items.map(i => (
                        <div key={i.key} style={{borderBottom: '1px solid #eee', marginTop: 7, paddingBottom: 4}}>
                            <div style={{position: 'relative', height: 40, display: 'flex', alignItems: 'center'}}>{addTypes.find(t => t.type === i.type).label}
                                <Button style={{position: 'absolute', right: 0}} minimal={true} icon='delete' intent='danger' onClick={() => setItems(items.filter(it => it.key !== i.key))} />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                {i.side ? <div>{sideTypes.find(s => s.side === i.side).label}</div> : null}
                                {i.num ? <div>Номер игрока: <b>{i.num}</b></div> : null}
                                {i.minute ? <div>Минута события: <b>{i.minute}</b></div> : null}
                                {i.scores ? <div>Счёт в протоколе: <b>{i.scores.origin.home}:{i.scores.origin.away}</b>, Счёт по событиям: <b>{i.scores.fact.home}:{i.scores.fact.away}</b></div> : null}
                                {i.custom ? <div>{i.custom}</div> : null}
                            </div>
                        </div>
                    ))}
                </div>
            </Card>
}

export default Refinement
