import React, { useState } from 'react'

import { Tabs, Tab, InputGroup, Button, Popover, ControlGroup, Card } from '@blueprintjs/core'
const rest = require('../rest')

const Group = ({ data, clubs, onGroupUpdated, onClubSelected, draftedClubs }) => {
    const draftClubToGroup = id => {
        rest('put', 'groups', data._id, {
            push: ['clubs', id]
        }).then(upd => {
            onGroupUpdated(upd)
        })
    }

    const sendSummary = () => {
        rest('put', 'utils', null, {
            action: 'distributeSummarySharing',
            mode: 'group',
            subjectId: data._id
        }).then(resp => {
            alert(JSON.stringify(resp))
        })
    }

    return  <div>
                <div className='panels-list'>
                    {data.clubs.map(c => {
                        const item = clubs.find(cl => cl._id === c)
                        return <Card interactive={true} elevation={2} onClick={() => onClubSelected(c)}>{item.name} {item.remote ? item.remote.id : ''}</Card>
                    })}
                </div>

                <Popover
                    content={(
                        <Card style={{padding: 6}} elevation={3}>
                            {clubs.filter(c => !draftedClubs.includes(c._id)).map(c => (
                                <Card interactive={true} style={{padding: 6, marginBottom: 6}} elevation={1} onClick={() => draftClubToGroup(c._id)}>
                                    {c.name} {c.remote ? c.remote.id : ''}
                                </Card>
                            ))}
                        </Card>
                    )}
                >
                    <Button intent='default' text='Добавить команду' icon='add' style={{marginTop: 12}} />
                </Popover>
                <Button text='Отправить саммари в соц.сети' style={{marginTop: 10}} onClick={() => sendSummary()}/>
            </div>
}

const NewGroup = ({onGroupCreated, ...props}) => {
    const [name, setName] = useState(null)
    const [progress, setProgress] = useState(null)

    const createGroup = () => {
        setProgress(true)
        rest('post', 'groups', null, {
            ...props,
            name: name
        }).then(gr => {
            if(gr._id) {
                setProgress(false)
                onGroupCreated(gr)
            }
        })
    }

    return  <div>
                <ControlGroup>
                    <InputGroup value={name}  onChange={e => setName(e.target.value)} />
                    <Button text='Сохранить группу' intent='success' progress={progress} disabled={!name || progress} onClick={() => createGroup()}/>
                </ControlGroup>
            </div>
}

const Stage = ({ data, groups, onGroupCreated, clubs, onGroupUpdated, onClubSelected, draftedClubs }) => {
    const [group, setGroup] = useState(groups && groups.length > 0 ? groups[0]._id : 'new')

    return  data.type === 'groups' ? (
                <div>
                    <Tabs selectedTabId={group} onChange={t => setGroup(t)} renderActiveTabPanelOnly={true}>
                        {groups.map(g => (
                            <Tab
                                id={g._id}
                                title={g.name}
                                panel={
                                    <Group
                                        data={g}
                                        clubs={clubs}
                                        draftedClubs={draftedClubs}
                                        onClubSelected={onClubSelected}
                                        onGroupUpdated={onGroupUpdated}
                                    />
                                }
                            />
                        )).concat([
                            <Tab id='new' panel={
                                <NewGroup
                                    stageId={data._id}
                                    seasonId={data.seasonId}
                                    onGroupCreated={group => {
                                        setGroup(group._id)
                                        onGroupCreated(group)
                                    }}
                                />
                            } title='Добавить группу' icon='add' />
                        ])}
                    </Tabs>
                </div>
            ) : 'Round type'
}

export default Stage
