import React, { useState, useEffect } from 'react'
import { Card, Spinner, Tabs, Tab } from '@blueprintjs/core'

import rest from '../../../components/Workspace/rest'

import StaffList from './StaffList'

const renderInvite = () => {
    return (Math.floor(Math.random() * 90000) + 10000).toString()
}

const reduceClubs = arr => {
    return arr.sort((a, b) => {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    }).reduce((acc, c) => {
        const ltr = c.name.charAt(0)
        if(!acc[ltr]) { acc[ltr] = [] }
        acc[ltr].push(c)
        return acc
    }, {})
}

const ClubStaff = ({ clubId, leagueId }) => {
    const [list, setList] = useState(null)
    const [inviteCode, setInviteCode] = useState(null)

    useEffect(() => {
        rest('get', `staffs?filter[roles.clubId]=${clubId}`)
            .then(l => {
                setList(l)
            })
    }, [])

    return  <div>
                {list ? (
                    <StaffList
                        leagueId={leagueId}
                        data={list}
                        clubId={clubId}
                        inviteCode={inviteCode}
                        resetInviteCode={() => setInviteCode(null)}
                        createPerson={person => {
                            rest('post', 'staffs', null, {
                                first_name: person.first_name,
                                last_name: person.last_name,
                                roles: person.roles.map(r => ({clubId: clubId, role: r})),
                                invite: renderInvite()
                            }).then(created => {
                                setList(Object.assign([], list).concat([created]))
                                setInviteCode(created.invite)
                            })
                        }}
                    />
                ) : <Spinner />}
            </div>
}

const Club = ({ data, leagueId }) => {
    const [tab, setTab] = useState('staff')

    return  <div style={{width: '100%'}}>
                <Tabs onChange={t => setTab(t)} selectedTabId={tab} renderActiveTabPanelOnly={true}>
                    <Tab id='staff' title='Представители' panel={<ClubStaff clubId={data._id} leagueId={leagueId} />} />
                    <Tab id='seasons' title='Сезоны' />
                    <Tab id='settings' title='Настройки' />
                </Tabs>
            </div>
}

const Clubs = ({ data, setTitle, leagueId }) => {
    const [clubs, setClubs] = useState(reduceClubs(data))
    const [club, setClub] = useState(null)

    useEffect(() => {
        setTitle({
            string: club ? club.name : 'Управление клубами',
            onBack: club ? () => setClub(null) : 'resetMode'
        })
    }, [club])

    return  <div className={`screen clubs${club ? ' list' : ''}`}>
                {club ? (
                    <Club data={club} leagueId={leagueId} />
                ) : Object.keys(clubs).map(ltr => (
                    <div className='inner-grid-group'>
                        <div className='title'>{ltr}</div>
                        <div className='inner-grid'>{clubs[ltr].map(c => (
                            <div className='panel-icon-wrap'>
                                <Card
                                    className='panel-icon bgd'
                                    elevation={2}
                                    style={{backgroundImage: `url(${c.emblem ? c.emblem.replace('26x26', '120x120') : require('../../../assets/icons/badge.svg').default})`}}
                                    onClick={() => setClub(c)}
                                >
                                </Card>
                                <label>{c.name}</label>
                            </div>
                        ))}</div>
                    </div>
                ))}
            </div>
}

export default Clubs
