import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { Icon, Checkbox, Button, Card, Callout, Toaster } from '@blueprintjs/core'
import CustomScrollbars from 'react-custom-scrollbars-2'
import { ModerProvider } from './moderContext'
import MatchDialog from '../MatchDialog'
import { collectData, splitSquad } from './utils'
import { checkData } from './checker'

import StepContent from './StepContent'
import './v2.css'

const rest = require('../../Workspace/rest')

const steps = [
    {value: 'homeSquad', label: 'Состав хозяев', idx: 0},
    {value: 'homeEvs', label: 'События хозяев', idx: 1},
    {value: 'awaySquad', label: 'Состав гостей', idx: 2},
    {value: 'awayEvs', label: 'События гостей', idx: 3}
]

const schema = {
    gk: [{num:'', remoteId:''}],
    defs: [{num:'', remoteId:''},{num:'', remoteId:''},{num:'', remoteId:''},{num:'', remoteId:''}],
    mdls: [{num:'', remoteId:''},{num:'', remoteId:''},{num:'', remoteId:''},{num:'', remoteId:''}],
    frws: [{num:'', remoteId:''},{num:'', remoteId:''},{num:'', remoteId:''},{num:'', remoteId:''}]
}

const ItemV2 = React.memo(({ draft, meta, matchId, sources, extendedStats }) => {
    const [step, setStep] = useState(steps[0])
    const [post, setPost] = useState(null)
    const [errs, setErrs] = useState(null)
    const [valid, setValid] = useState(true);
    //console.log('v2',draft);

    const [context, setContext] = useState({
        scores: draft.scores || {
            half: {},
            full: {home: 0, away: 0},
            options: {tech: false, shootout: false, extraTime: false},
            shootout: {}
        },
        mvp: draft.mvp || {},
        imFromSources: [],
    })

    const toaster = useRef(null)

    useEffect(() => {
        const checked = checkData({...context}, meta)
        //console.log('useEffect', checked);
        setErrs(checked.errors);
        setPost(checked.post);
        setValid(checked.valid);
    }, [context])

    useEffect(() => {
        if(sources) {
            const ctx = Object.assign({}, context)
            const keys = ['homeSquad', 'awaySquad', 'homeEvs', 'awayEvs'] //, 'homeLines', 'awayLines'
            const ifs = Object.assign([], ctx.imFromSources)
            for(let k of keys) {
                if(sources[k]) {
                    console.log('APPEARED SOURCE', k)
                    ctx[k+'Source'] = sources[k]
                    const draftedResponse = k.includes('Squad') ? draft[k] && Object.keys(draft[k]).length ? splitSquad(draft[k]) : null : draft.events && draft.events[k.replace('Evs', '')] ? draft.events[k.replace('Evs', '')] : null
                    if(draftedResponse) {
                        ctx[k+'Response'] = draftedResponse
                        ifs.push(k)
                    }
                }
            }
            setContext({...ctx, imFromSources: ifs})
        }
    }, [sources])

    useEffect(() => {
        if (extendedStats) {
            const ctx = Object.assign({}, context)
            setContext({...ctx, homeSchema: draft.homeSchema || schema, awaySchema: draft.awaySchema || schema})
        }
    },[extendedStats])


    const postData = () => {
        rest('put', 'moderate', matchId, {
            data: {
                ...post,
                matchday: draft.matchday
            },
            isDraft: false
        }).then(resp => {
            toaster.current.show({intent: resp.error ? 'danger' : 'success', message: resp.error ? 'Ошибка при отправке результата' : 'Результат успешно отправлен', timeout: 2500})
            if(!resp.error) {
                window.location.replace('/moderate')
            }
        })
    }

    const saveDraft = () => {
        const draftData = {
            data: {
                ...collectData(context),
                matchday: draft.matchday
            },
            isDraft: true
        }
console.log('draftData',draftData);
        rest('put', 'moderate', matchId, draftData)
            .then(resp => {
                toaster.current.show({intent: resp.error ? 'danger' : 'primary', message: resp.error ? 'Ошибка при сохранении черновика' : 'Черновик успешно сохранён', timeout: 2500})
            })
    }

    return  <ModerProvider value={{...context, setCtx: (k, v) => typeof(k)!=='object' ? setContext({...context, [k]: v}) : setContext({...context, ...k})}}>
                <Toaster ref={toaster} />

                <div className='moder'>
                <CustomScrollbars autoHeight autoHeightMax='100vh'>
                    <Card className='moder-actions' elevation={2}>
                        <Button intent='primary' disabled={!valid} large={true} text='Сохранить черновик' onClick={() => saveDraft()} icon='archive' />
                        <Button intent='success' disabled={!post} large={true} text='Отправить на утверждение' icon='send-message' style={{marginTop: 14}} onClick={() => postData()} />
                    </Card>

                    {errs ? (
                        <div className='moder-errs'>
                            {errs.map((e, i) => (
                                <Callout key={'callout_'+i} intent={e.type === 'err' ? 'danger' : 'warning'} icon={e.type === 'err' ? 'cross' : 'warning-sign'}>{e.msg}</Callout>
                            ))}
                        </div>
                    ) : null}

                    <div className='moder-head'>
                        <h1 style={{marginBottom: 10}}>{draft.matchday.teams}</h1>
                        <div className='moder-head_info bp3-text-muted'>
                            <div>{draft.matchday.league}</div>
                            <div>{draft.matchday.division}</div>
                            {meta && meta.sender ? <div>Отправитель: {meta.sender.first_name+' '+meta.sender.last_name}</div> : null}
                            {meta && meta.moderator ? <div>Модератор: {meta.moderator.first_name+' '+meta.moderator.last_name}</div> : null}
                        </div>
                        <div className='moder-head_score'>
                            <div>
                                <b>Результат матча</b>
                                <input
                                    value={context.scores.full.home}
                                    onChange={e => setContext({...context, scores: {...context.scores, full: {...context.scores.full, home: e.target.value}}})}
                                    type='number'
                                    onFocus={e => e.target.select()}
                                />
                                <Icon icon='cross' />
                                <input
                                    value={context.scores.full.away}
                                    onChange={e => setContext({...context, scores: {...context.scores, full: {...context.scores.full, away: e.target.value}}})}
                                    type='number'
                                    onFocus={e => e.target.select()}
                                />
                            </div>
                            <div style={{flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 14, width: 280}}>
                                <div>
                                    <Checkbox
                                        label='Техничка'
                                        checked={context.scores && context.scores.options && context.scores.options.tech}
                                        onChange={() => setContext({...context, scores: {...context.scores, options: context.scores.options ? {...context.scores.options, tech: !context.scores.options.tech} : {tech: true}}})}
                                    />
                                </div>
                                <div style={{display: 'flex', marginTop: 2}}>
                                    <Checkbox
                                        label='Овертайм'
                                        checked={context.scores && context.scores.options && context.scores.options.extraTime}
                                        onChange={() => setContext({...context, scores: {...context.scores, options: context.scores.options ? {...context.scores.options, extraTime: !context.scores.options.extraTime} : {extraTime: true}}})}
                                    />
                                    <Checkbox
                                        label='Серия пенальти'
                                        style={{marginLeft: 10}}
                                        checked={context.scores && context.scores.options && context.scores.options.shootout}
                                        onChange={() => setContext({...context, scores: {...context.scores, options: context.scores.options ? {...context.scores.options, shootout: !context.scores.options.shootout} : {shootout: true}}})}
                                    />
                                </div>
                            </div>
                            {context.scores.options && context.scores.options.shootout ? <div style={{width: 230}}>
                                <b>Счёт в серии</b>
                                <input
                                    type='number'
                                    value={context.scores.shootout ? context.scores.shootout.home : null}
                                    onChange={e => setContext({...context, scores: {...context.scores, shootout: context.scores.shootout ? {...context.scores.shootout, home: e.target.value} : {home: e.target.value}}})}
                                    onFocus={e => e.target.select()}
                                />
                                <Icon icon='cross' />
                                <input
                                    type='number'
                                    onFocus={e => e.target.select()}
                                    value={context.scores.shootout ? context.scores.shootout.away : null}
                                    onChange={e => setContext({...context, scores: {...context.scores, shootout: context.scores.shootout ? {...context.scores.shootout, away: e.target.value} : {away: e.target.value}}})}
                                />
                            </div> : null}
                        </div>
                        <div className='stepper'>
                            <div className='stepper-top'>
                                {steps.map((s, i) => (
                                    <div
                                        className={i < step.idx ? 'passed' : i === step.idx ? 'current' : ''}
                                        key={s.value}
                                        onClick={() => setStep(steps[i])}
                                    >{s.label}</div>
                                ))}
                            </div>

                            <div>
                            {steps.map((s, i) => (
                                <div key={s.value+'_wrap_'+draft.matchday.matchId} className={'step-wrap'+ (i === step.idx ? ' shown' : '')}>
                                    <StepContent
                                        key={s.value+'_'+draft.matchday.matchId}
                                        matchId={draft.matchday.matchId}
                                        path={s.value}
                                        incStep={() => setStep(steps[i+1])}
                                        meta={meta}
                                        toaster={toaster}
                                        extendedStats={extendedStats}
                                        schemaQty={meta && meta.season && meta.season.schemaQty ? meta.season.schemaQty : 8}
                                    />
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </CustomScrollbars>
                </div>
                <MatchDialog toaster={toaster} />
            </ModerProvider>
})

export default ItemV2
