import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

import ModerateSheet from './components/ModerateSheet'
import Workspace from './components/Workspace'
import Logs from './components/Logs'
import Matches from './components/Matches'
import Players from './components/Players'
import Panel from './containers/Panel'
import Action from './containers/Panel/Action'
import Chats from './components/Chats'
import Sender from './components/Sender'
import Stats from './containers/Stats'
import Auth from './Auth'

import '../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css'
import '../node_modules/@blueprintjs/core/lib/css/blueprint.css'
import '../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import './style.css'
import './common.css'

const accessId = localStorage.getItem('_am_accessId')
Sentry.init({
  dsn: "https://51615bbf35f04981b3e21f2f267c5b83@o1021987.ingest.sentry.io/5999874",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

function App() {
  return accessId || window.location.href.includes('app.') ? (
    <div className="App">
        <Router>
            <Switch>
                <Route path='/stats'>
                    <Stats />
                </Route>
                <Route path='/sender'>
                    <Sender />
                </Route>
                <Route path='/chats/:chatId'>
                    <Chats />
                </Route>
                <Route path='/chats'>
                    <Chats />
                </Route>
                <Route path='/action/:actionId'>
                    <Action />
                </Route>
                <Route path='/panel/:token'>
                    <Panel />
                </Route>
                <Route path='/players/:playerId'>
                    <Players />
                </Route>
                <Route path='/players'>
                    <Players />
                </Route>
                <Route path='/matches/:matchId'>
                    <Matches />
                </Route>
                <Route path='/matches'>
                    <Matches />
                </Route>
                <Route path='/logs'>
                    <Logs />
                </Route>
                <Route path='/moderate/:matchId'>
                    <ModerateSheet />
                </Route>
                <Route path='/moderate'>
                    <ModerateSheet />
                </Route>
                <Route path='/:collection/:itemId'>
                    <Workspace />
                </Route>
                <Route path='/:collection'>
                    <Workspace />
                </Route>
            </Switch>
        </Router>
    </div>
) : <Auth />;
}

export default App;
