import { React, useState, useEffect } from 'react'

import { ControlGroup, Button, InputGroup, Spinner, Card, Popover, Menu, MenuItem, Checkbox } from '@blueprintjs/core'
import { DatePicker, TimePicker } from '@blueprintjs/datetime'

const moment = require('moment')
const { SHEETS } = require('../../../env')
const rest = require('../rest')

const remotes = {
    ole: 'Ole Sports',
    joinsport: 'Join Sport',
    lfl: 'LFL.RU',
    goalstream: 'Goalstream'
}

const Matchday = ({ activeMatchday, onCreatedMatchday, seasonId, participants, remoteSeasonId, remoteLeagueId, remoteSeasonSource, leagueId }) => {
    const [data, setData] = useState(null)
    const [newDayName, setNewDayName] = useState(null)
    const [newMatch, setNewMatch] = useState(null)
    const [remoteList, setRemoteList] = useState(null)
    const [remoteListProgress, setRemoteListProgress] = useState(false)
    const [updGsSquads, setUpdGSSquads] = useState([])

    const createMatchday = () => {
        rest('post', 'matchdays', null, {name: newDayName, seasonId: seasonId})
            .then(created => {
                if(created._id) {
                    onCreatedMatchday(created)
                }
            })
    }

    const createMatch = () => {
        rest('post', 'matches', null, newMatch)
            .then(created => {
                if(created._id) {
                    setData({
                        ...data,
                        matches: [created].concat(Object.assign([], data.matches))
                    })
                    setNewMatch(null)
                }
            })
    }

    useEffect(() => {
        const fetchDay = () => {
            rest('get', 'matchdays', activeMatchday)
                .then(day => {
                    if(day._id) {
                        setData(day)
                    }
                })
        }

        if(activeMatchday) {
            fetchDay()
        }
    }, [activeMatchday])

    const mapClubs = match => {
        switch(remoteSeasonSource) {
            case 'joinsport':
                return match
                break
            case 'ole':
                const m = {
                    ...match,
                    home: participants.find(c => c.name === match.home),
                    away: participants.find(c => c.name === match.away),
                }
                return {
                    ...m,
                    homeId: m.home ? m.home._id : null,
                    awayId: m.away ? m.away._id : null
                }
                break
            default:
                return match
        }
    }

    const loadRemoteList = () => {
        setRemoteList(false)
        let url
        switch(remoteSeasonSource) {
            case 'ole':
                url = `ole/schedule?remoteSeasonId=${remoteSeasonId}&seasonId=${seasonId}`
                break
            case 'joinsport':
                url = `joinsport/calendar?seasonId=${seasonId}`
                break
            default:
                url = `goalstream/schedule?association=${remoteLeagueId}&season=${remoteSeasonId}`
        }
        rest('put', 'parsers', url)
            .then(resp => setRemoteList(resp.map(i => mapClubs({...i, checked: !(i.imported)}))))
    }

    const getClubName = id => {
        return participants.find(t => t._id === id) ? participants.find(t => t._id === id).name : null
    }

    const TeamMenu = ({ side }) => {
        return  <Popover
                    usePortal={false}
                    content={(
                        <Menu>
                            {participants.map(t => (
                                <MenuItem text={t.name} onClick={() => setNewMatch({...newMatch, [side+'Id']: t._id})} />
                            ))}
                        </Menu>
                    )}
                >
                    <Button text={newMatch[side+'Id'] ? getClubName(newMatch[side+'Id']) : 'Команда '+(side === 'home' ? 'хозяев' : 'гостей')} />
                </Popover>
    }

    const MatchItem = ({ data }) => {
        return  <Card elevation={2} style={{padding: '4px 6px', marginBottom: 8, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>{data.date} {data.time}</div>
                    <div>{data.home ? data.home.name : getClubName(data.homeId)} vs {data.away ? data.away._id : getClubName(data.awayId)}</div>
                    <Button intent='primary' minimal={true} icon='link' text='Открыть протокол' onClick={() => window.open(`${SHEETS}/${data._id}`)} />
                </Card>
    }

    const handleRemoteList = async () => {
        setRemoteListProgress(true)
        // const updateGsRoster = club => {
        //     return new Promise((resolve, reject) => {
        //         const sids = Object.assign([], updGsSquads)
        //         sids.push(club.id)
        //         setUpdGSSquads(sids)
        //         rest('put', 'parsers', `goalstream/club?remoteClubId=${club.url.replace('/club/', '')}&remoteSeasonId=${remoteSeasonId}&seasonId=${seasonId}`)
        //             .then(ok => {
        //                 setUpdGSSquads(updGsSquads.filter(i => i !== club.id))
        //                 resolve(true)
        //             })
        //     })
        //}

        const saveMatch = m => {
            return new Promise((resolve, reject) => {
                const home = m.homeId ? m.homeId : m.home._id ? m.home : participants.find(c => c.remote && c.remote.id === m.home.id)
                const away = m.awayId ? m.awayId : m.away._id ? m.away : participants.find(c => c.remote && c.remote.id === m.away.id)
                if(home && away) {
                    rest('post', 'matches', null, {
                        matchdayId: activeMatchday,
                        leagueId: leagueId,
                        seasonId: seasonId,
                        homeId: m.homeId || home._id,
                        awayId: m.awayId || away._id,
                        meta: m.meta || null,
                        time: m.time,
                        date: m.date,
                        remoteId: m.remoteId || m.href.replace('/match/', '')
                    }).then(created => {
                        resolve(true)
                    })
                } else {
                    resolve(true)
                }
            })
        }

        const upds = []

        Promise.all(upds)
            .then(updated => {
                const ms = remoteList.filter(m => m.checked).map(m => saveMatch(m))
                Promise.all(ms)
                    .then(saved => {
                        setRemoteListProgress(false)
                        setRemoteList(null)
                        setData({
                            ...data,
                            matches: saved.concat(Object.assign([], data.matches))
                        })
                    })
            })
    }

    return  <div style={{padding: 12, paddingTop: 12}}>
                {!activeMatchday ? (
                    <ControlGroup fill={true}>
                        <InputGroup
                            placeholder='Новый мачдэй'
                            large={true}
                            value={newDayName}
                            onChange={({target}) => setNewDayName(target.value)}
                        />
                        <Button large={true} intent='success' text='Создать  мачдэй' onClick={() => createMatchday()} />
                    </ControlGroup>
                ) : data ? remoteList !== null ? remoteList === false ? (
                    <Spinner style={{margin: '150px 0'}} />
                ) : (
                    <div>
                        {remoteList ? remoteList.map(m => (
                            <Card elevation={1} style={{backgroundColor: m.imported ? 'rgba(15, 153, 96, 0.25)' : '#fff', padding: 6, marginBottom: 8}}>
                                <ControlGroup>
                                    <Checkbox checked={m.checked} onChange={() => setRemoteList(remoteList.map(_m => (m.remoteId && _m.remoteId === m.remoteId) || (m.id && _m.id === m.id) ? ({..._m, checked: !m.checked}) : _m))} />
                                    <Button text={m.date} />
                                    <Button text={m.time} />
                                    <Button style={{width: 162}} text={m.home ? m.home.name : getClubName(m.homeId)} />
                                    <Button style={{width: 162}} text={m.away ? m.away.name : getClubName(m.awayId)} />
                                    <Button text={m.remoteId || m.href.replace('/match/', '')} style={{fontSize: 11}} />
                                </ControlGroup>
                            </Card>
                        )) : null}
                        <Button text='Обработать и сохранить матчи' intent='success' fill={true} loading={remoteListProgress} onClick={() => handleRemoteList()} />
                    </div>
                ) : (
                    <div>
                        {newMatch ? (
                            <Card elevation={2} style={{marginBottom: 12, padding: 0}}>
                                <ControlGroup fill={true}>
                                    <Popover
                                        usePortal={false}
                                        content={<DatePicker onChange={v => setNewMatch({...newMatch, date: moment(v).format('YY-MM-DD')})}/>}
                                    >
                                        <Button icon='calendar' text={newMatch.date == '' ? 'Дата' : newMatch.date} />
                                    </Popover>
                                    <Popover
                                        usePortal={false}
                                        content={<TimePicker onChange={v => setNewMatch({...newMatch, time: moment(v).format('HH:mm')})}/>}
                                    >
                                        <Button icon='history' text={newMatch.time == '' ? 'Время' : newMatch.time} />
                                    </Popover>
                                    <TeamMenu side='home' />
                                    <TeamMenu side='away' />
                                    <InputGroup placeholder='Remote ID' value={newMatch.remoteId} onChange={e => setNewMatch({...newMatch, remoteId: e.target.value})} />
                                    <Button intent='success' text='Сохранить матч' onClick={() => createMatch()}/>
                                </ControlGroup>
                            </Card>
                        ) : data.matches.map(m => (
                            <MatchItem data={m} />
                        ))}

                        <Button fill={true} text='Добавить матч' intent='primary' onClick={() => setNewMatch({
                            matchdayId: activeMatchday,
                            homeId: null,
                            awayId: null,
                            date: '',
                            time: ''
                        })} />

                        {remoteList === null ? (
                            <Button style={{marginTop: 10}} intent='success' fill={true} text={`Парсить ближайшие матчи с ${remotes[remoteSeasonSource]}`} onClick={() => loadRemoteList()} />
                        ) : null}
                    </div>
                ) : <Spinner />}
            </div>
}

export default Matchday
