import React, { useState, useEffect, useContext } from 'react'
import { Spinner, NonIdealState, Button, Card, Tag, Checkbox } from '@blueprintjs/core'
import ModerContext from './moderContext'
import Cropper from './Cropper'

const rest = require('../../Workspace/rest')

const etypes = [
    {key: 'goal', label: 'Голы'},
    {key: 'yc', label: 'Предупреждения'},
    {key: 'rc', label: 'Удаления'}
]

const concatSquad = arr => {
    return arr.reduce((acc, col) => {
        acc = acc.concat(col)
        return acc
    }, [])
}

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
  })
}

const attachPlayers = (arr, squad) => {
    return squad ? arr.map(e => ({
        ...e,
        player: e.number && !isNaN(parseInt(e.number)) ? squad.find(p => p.num && p.num.toString() === e.number.toString()) || null : null,
        assistant: e.assist && !isNaN(parseInt(e.assist)) ? squad.find(p => p.num && p.num.toString() === e.assist.toString()) || null : null,
        uuid: e.uuid || uuidv4()
    })) : arr
}

const Section = ({ type, path }) => {
    const ctx = useContext(ModerContext)
    const squadKey = path.includes('home') ? 'homeSquadResponse' : 'awaySquadResponse'

    const [squad, setSquad] = useState(concatSquad(ctx[squadKey] || []))
    const [items, setItems] = useState([])
    const [inited, setInited] = useState(false)

    const addEvt = () => {
        setItems([{minute: null, number: null, assist: null, uuid: uuidv4()}].concat(items.map(e => e)))
    }

    const delEvt = i => {
        setItems(items.filter((e, idx) => idx !== i))
    }

    const patchEvt = (i, k, v) => {
        setItems(attachPlayers(items.map((e, idx) => ({
            ...e,
            [k]: idx === i ? v : e[k]
        })), squad || []))
    }

    useEffect(() => {
        if(!inited && squad.length) {
            setTimeout(() => {
                setItems(attachPlayers((ctx[path+'Response'][type.key] || []), squad))
                setInited(true)
            }, 500)
        }
    }, [ctx[path+'Response']])

    useEffect(() => {
        ctx.setCtx(path+'Response', {...ctx[path+'Response'], [type.key]: items})
    }, [items])

    useEffect(() => {
        const _squad = concatSquad(ctx[squadKey] || [])
        setSquad(_squad)
        setTimeout(() => {
            setItems(attachPlayers((ctx[path+'Response'][type.key] || []), _squad))
        }, 300)
    }, [ctx[squadKey]])

    return  <Card elevation={2} className='events-section'>
                <div className='events-section_title'>
                    <Button icon='add' onClick={() => addEvt()} minimal={true} text='Добавить' intent='primary' style={{marginRight: 14}}/>
                    {type.label}
                    {items.length ? <Tag minimal={true} style={{marginLeft: 7}}>{items.length} событий</Tag> : null}
                </div>
                <div className='events-section_body'>
                    {items.map((e, i) => (
                        <div className='events-section_item' key={e.uuid}>
                            <div>
                                <b>мин.</b>
                                <input
                                    value={e.minute || ''}
                                    type='number'
                                    onChange={e => patchEvt(i, 'minute', parseInt(e.target.value))}
                                    onFocus={e => e.target.select()}
                                    min={1}
                                    max={99}
                                />
                            </div>
                            <div style={{marginLeft: 10}}>
                                <b>игрок</b>
                                <input
                                    value={e.number}
                                    type='number'
                                    onChange={e => patchEvt(i, 'number', e.target.value)}
                                    onFocus={e => e.target.select()}
                                />
                            </div>
                            <div style={{marginLeft: 6, width: 100, display: 'flex', justifyContent: 'center', paddingTop: 12}}>
                                <Tag minimal={true} multiline={true} intent={!e.player ? 'danger' : e.player.absent ? 'warning' : 'success'}>{e.player ? e.player.name : 'Игрок не привязан'}</Tag>
                            </div>
                            {type.key === 'goal' ? (
                                [
                                    <div style={{marginLeft: 10}}>
                                        <b>ассист</b>
                                        <input
                                            value={e.assist}
                                            type='number'
                                            onFocus={e => e.target.select()}
                                            onChange={e => patchEvt(i, 'assist', e.target.value)}
                                        />
                                    </div>,
                                    <div style={{marginLeft: 6, width: 100, display: 'flex', justifyContent: 'center', paddingTop: 12}}>
                                        <Tag minimal={true} multiline={true} intent={!e.assist ? 'none' : !e.assistant ? 'danger' : e.assistant.absent ? 'warning' : 'success'}>{!e.assist ? 'Без ассиста' : e.assistant ? e.assistant.name : 'Игрок не привязан'}</Tag>
                                    </div>,
                                    <div className='boxes'>
                                        <Checkbox label='АГ' checked={e.owngoal} onChange={() => patchEvt(i, 'owngoal', !e.owngoal)} />
                                        <Checkbox label='ПЕН' checked={e.penalty} onChange={() => patchEvt(i, 'penalty', !e.penalty)} />
                                        <Checkbox label='НП' checked={e.missedPenalty} onChange={() => patchEvt(i, 'missedPenalty', !e.missedPenalty)} />
                                    </div>
                                ]
                            ) : null}
                            <div className='del'>
                                <Button icon='delete' onClick={() => delEvt(i)} minimal={true} intent='danger' />
                            </div>
                        </div>
                    ))}
                </div>
            </Card>
}

const Events = React.memo(({ path, matchId, incStep }) => {
    const ctx = useContext(ModerContext)
    const content = ctx[path+'Response']
    const [recognized, setRecognized] = useState(false)
    const [landscape, setLandscape] = useState(true)

    const resetRoi = () => {
        ctx.setCtx(path+'Source', null)
        ctx.setCtx(path+'Response', null)
        ctx.setCtx('imFromSources', ctx.imFromSources.filter(k => k !== path))
        setRecognized(null)
    }

    useEffect(() => {
        if(ctx[path+'Source'] && !recognized && !ctx.imFromSources.includes(path)) {
            console.log('EVS OCR INIT')
            rest('post', 'ocr', `${matchId}/${path}`, {data: ctx[path+'Source']})
                .then(resp => {
                    setRecognized(true)
                    if(!resp.error) {
                        ctx.setCtx(path+'Response', resp)
                    } else {
                        alert(resp.error)
                    }
                })
        }
    }, [ctx && ctx[path+'Source']])

    return  <div key={path+'_'+matchId+'_root'}>
                {!ctx[path+'Source'] ? (
                    <Cropper path={path} matchId={matchId} landscape={landscape} setLandscape={setLandscape} />
                ) : (
                    <div className='step-content'>
                        <div className='step-events'>
                            <div className='img'>
                                <Button
                                    icon='endorsed'
                                    large={true}
                                    intent='success'
                                    text='Следующий шаг'
                                    minimal={true}
                                    onClick={() => {
                                        incStep()
                                    }}
                                    style={{marginBottom: 10, borderRadius: 20, padding: '5px 15px'}}
                                />
                                <img src={ctx[path+'Source']} style={{maxWidth: '100%'}} key={path+'_'+matchId+'_decoded'} />
                                <Button icon='refresh' minimal={true} style={{marginTop: 10, width: 200}} text='Сбросить область' onClick={() => resetRoi()} />
                            </div>
                            <div className='content'>
                                {!content ? (
                                    <NonIdealState
                                        icon={<Spinner intent='primary' />}
                                        description='Распознаю... Не переключай вкладку!'
                                    />
                                ) : (
                                    <div>
                                    {etypes.map(etype => (
                                        <Section
                                            path={path}
                                            key={etype.key}
                                            type={etype}
                                        />
                                    ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
})

export default Events
