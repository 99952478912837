import React, { useState, useEffect } from 'react';
import { Button, Card, Elevation, HTMLSelect, Label } from '@blueprintjs/core';
import { DateInput } from "@blueprintjs//datetime";
import {Scrollbars} from 'react-custom-scrollbars-2';
import StatsForm from './StatsForm.js';

const rest = require('../../../components/Workspace/rest');
const moment = require('moment');

const matchShema = {
    date: moment().format('YY-MM-DD'),
    time: '14:00',
    homeId: '',
    awayId: ''
}

const statShema = {
    _id: '',
    matchId: '',
    broadcasterId: '',
    agentId: '',
    price: 0,
    bounty: 0,
    entity: {
        duration: 0,
        offsides: false,
        isProduction: false,
        live: false,
        periods: 2,
        etDuration: 0,
        pureTime: false,
        passesMode: "accuracy",
        freq: 4
    }
}
const threeDayAgo = new Date() - 3 * 24 * 3600 * 1000;

const List = () => {
    const [statsList, setStatsList] = useState([]);
    const [formMatch, setFormMatch] = useState({...matchShema});
    const [formStat, setFormStat] = useState({...statShema});
    const [homeClubName, setHomeClubNAme] = useState(null);
    const [awayClubName, setAwayClubName] = useState(null);

    const [broadcastersList, setBroadcastersList] = useState([]);
    const [agentsList, setAgentsList] = useState([]);
    const [broadcasterId, setBroadcasterId] = useState('');
    const [agentId, setAgentId] = useState('');
    const [mindate, setMindate] = useState(moment(threeDayAgo).format('YY-MM-DD'));
    const [maxdate, setMaxdate] = useState(moment().format('YY-MM-DD'));

    useEffect(() => {
        rest('get', `agents`)
            .then(items => {
                setAgentsList([{_id:'', name: 'не выбрано'}, ...items]);
            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        rest('get', 'broadcasters')
            .then(items => {
                setBroadcastersList([{_id:'', name: 'не выбрано'}, ...items]);
            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        if (!agentId && !broadcasterId){
            rest('get', `stats?filter[mindate]=${mindate}&filter[maxdate]=${maxdate}`)
                .then(items => {
                    setStatsList(items);
                })
                .catch(err => console.log(err));
        } else if (!agentId) {
            rest('get', `stats?filter[broadcasterId]=${broadcasterId}`)
                .then(items => {
                    setStatsList(items);
                })
                .catch(err => console.log(err));
        } else if (!broadcasterId) {
            rest('get', `stats?filter[mindate]=${mindate}&filter[maxdate]=${maxdate}&filter[agentId]=${agentId}`)
                .then(items => {
                    setStatsList(items);
                })
                .catch(err => console.log(err));
        } else {
            rest('get', `stats?filter[mindate]=${mindate}&filter[maxdate]=${maxdate}&filter[broadcasterId]=${broadcasterId}&filter[agentId]=${agentId}`)
                .then(items => {
                    setStatsList(items);
                })
                .catch(err => console.log(err));
        }
    }, [agentId, broadcasterId, mindate, maxdate]);

    const takeStat = (id) => {
        let stat = {};
        statsList.forEach((item) => {
            if (item._id === id) {
                stat={...item}
            }
        });
        setFormMatch({...formMatch,
            date: stat.item.date,
            time: stat.item.time,
            homeId: stat.homeClub._id,
            awayId: stat.awayClub._id
        });

        setFormStat({
            ...formStat,
            _id: stat._id,
            matchId: stat.matchId,
            broadcasterId: stat.broadcasterId ?? '',
            agentId: stat.agentId ?? '',
            price: stat.price ?? 0,
            bounty: stat.bounty ?? 0,
            entity: {
                duration: stat.entity.duration,
                offsides: stat.entity.offsides,
                isProduction: stat.entity.isProduction,
                live: stat.entity.live,
                periods: 2,
                etDuration: 0,
                pureTime: false,
                passesMode: "accuracy",
                freq: 4
            }
        });
        setHomeClubNAme(stat.homeClub);
        setAwayClubName(stat.awayClub);
    }
    const backToUpdList = () => {
        setFormMatch({...matchShema});
        setFormStat({...statShema});
        setHomeClubNAme(null);
        setAwayClubName(null);
    }

    const isEmptyOrNull = obj => (obj === null || !Object.keys(obj).length);

    const patchStatList = (id, difMatch, difStat, agent, broadcaster) => {
        const patchObj = (elem) => {
            if (!isEmptyOrNull(difMatch)) {
                elem = {
                    ...elem,
                    item: {
                        ...elem.item,
                        ...difMatch
                    }
                }
            }
            if (!isEmptyOrNull(difStat)) {
                elem = {
                    ...elem,
                    ...difStat,
                    entity:{
                        ...elem.entity,
                        ...difStat.entity
                    }
                }
                setFormStat({
                    ...formStat,
                    ...difStat,
                    entity:{
                        ...formStat.entity,
                        ...difStat.entity
                    }
                });
            }
            if (!isEmptyOrNull(agent)) {
                elem.agent = {...agent}
                elem.agents.splice(0, 1, agent);
            } else {
                delete elem.agent;
                delete elem.agentId;
                elem.agents.splice(0, 1);
            }
            if (!isEmptyOrNull(broadcaster)) {
                elem.broadcaster = {...broadcaster}
                elem.broadcasters.splice(0, 1, broadcaster);
            } else {
                delete elem.broadcaster;
                delete elem.broadcasterId;
                elem.broadcasters.splice(0, 1);
            }
            return elem;
        }
        setStatsList(statsList.map(elem => {
            const pathKey = '_id';
            return id !== elem[pathKey] ? elem : patchObj(elem);
        }));
    }
    const handleMinDate = (date) => {
        setMindate(moment(date).format('YY-MM-DD'));
    }
    const handleMaxDate = (date) => {
        setMaxdate(moment(date).format('YY-MM-DD'));
    }
    const handleFilterChange = (evt) => {
        let name = evt.target.name;
        if (name === 'agentId') {
            setAgentId(evt.target.value);
        } else {
            setBroadcasterId(evt.target.value);
        }
    }

    return (
    <div>
        <div style={{display: 'flex', flexWrap:'no-wrap', justifyContent:'space-between'}}>
            <Label style={{width:'30%', marginBottom: 10}}>Агент
                <HTMLSelect
                    name='agentId'
                    value={agentId}
                    options={agentsList.map(item => ({value:item._id, label:item.name}))}
                    onChange={handleFilterChange}
                />
            </Label>
            <Label style={{width:'30%', marginBottom: 10}}>Broadcaster
                <HTMLSelect
                    name='broadcasterId'
                    value={broadcasterId}
                    options={broadcastersList.map(item => ({value:item._id, label:item.name}))}
                    onChange={handleFilterChange}
                />
            </Label>
            <div style={{display: 'flex', width:'30%', justifyContent:'space-between', alignItems:'flex-end', marginBottom: 10}}>
                <Label style={{width:'45%', marginBottom: 10}}>Начало
                <DateInput
                    canClearSelection={true}
                    name='mindate'
                    formatDate={date => moment(date).format('YY-MM-DD')}
                    parseDate={str => new Date(str)}
                    onChange={handleMinDate}
                    value={moment(mindate, 'YY-MM-DD').toDate()}
                    placeholder={"выберите дату"}
                    highlightCurrentDay={true}
                /></Label>
                <Label style={{width:'45%', marginBottom: 10}}>Конец
                <DateInput
                    canClearSelection={true}
                    name='maxdate'
                    formatDate={date => moment(date).format('YY-MM-DD')}
                    parseDate={str => new Date(str)}
                    onChange={handleMaxDate}
                    value={moment(maxdate, 'YY-MM-DD').toDate()}
                    placeholder={"выберите дату"}
                    highlightCurrentDay={true}
                /></Label>
            </div>
        </div>
        <div style={{display: formStat._id ? 'none' : 'block', padding: 12, backgroundColor:'#f7f7f7',borderRadius:10 }}>
            <Scrollbars style={{width: '100%', height: 400, borderRadius:5}}>
                {statsList.map(stat =>
                    <Card key={stat._id} interactive={true} elevation={Elevation.ONE} style={{padding: 15, marginBottom:15}} onClick={() => takeStat(stat._id)}>
                        <div style={{display:'flex',alignItems:'center', width:'100%'}}>
                            <div style={{width:'15%'}}>
                                {stat.item.date}<br/>
                                {stat.item.time}
                            </div>
                            <div style={{width:'60%'}}>
                                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between', width:'100%'}}>
                                    <div style={{display: 'flex', alignItems:'center', justifyContent:'space-around', width:'100%'}}>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <div style={{marginRight:20}}>
                                                {stat.homeClub.name}<br/>
                                                {stat.homeClub.from ? stat.homeClub.from.city: ''}
                                            </div>
                                            <img alt='' src={stat.homeClub.emblem || require('../../../assets/color/badge.svg').default} style={{display:'block',width: 22, height: 22}} />
                                        </div>
                                        <div style={{width:'3%'}}>X</div>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <img alt='' src={stat.awayClub.emblem || require('../../../assets/color/badge.svg').default} style={{display:'block',width: 22, height: 22}} />
                                            <div  style={{marginLeft:20}}>
                                                {stat.awayClub.name}<br/>
                                                {stat.awayClub.from ? stat.awayClub.from.city : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'space-around', width:'100%', marginTop: 20}}>
                                        <div>{stat.entity.offsides ? 'с оффсайдами' : 'без оффсайдов'}</div>
                                        <div>{stat.entity.periods} x {stat.entity.duration}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width:'20%'}}>
                                <span>{stat.broadcaster ? stat.broadcaster.name : null}</span> <br/>
                                <span>{stat.entity.live ? 'live' : 'repeat'}</span><br/>
                                <span>{stat.agent ? stat.agent.name : null}</span>
                            </div>
                        </div>
                    </Card>
                )}
            </Scrollbars>
        </div>
        <div style={{display: formStat._id ? 'block' : 'none'}}>
            <StatsForm initialMatch={formMatch} initialStat={formStat} homeClubFromStat={homeClubName} awayClubFromStat={awayClubName} patchStatList={patchStatList}/>
        </div>
        <Button intent='success' text='К списку' onClick={() => backToUpdList()} style={{display: formStat._id ? 'block' : 'none'}}/>
    </div>)
}

export default List
