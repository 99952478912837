import React, { useState, useEffect } from 'react'

import { Tabs, Tab, Button } from '@blueprintjs/core'

const rest = require('../../../components/Workspace/rest')
const spaces = 'https://amateum.fra1.digitaloceanspaces.com/sheets/'

const subtypes = ['owngoal', 'penalty', 'missedPenalty']

const Squad = ({ data }) => {
    const arr = Object.keys(data).map(n => ({
        ...data[n],
        num: n
    })).filter(n => !n.absent).sort((a, b) => {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    })

    return  <div>{arr.map(n => (
                <div className='squad-row'>
                    <b>{n.num}</b>
                    <span>{n.name}</span>
                    <Button intent='none' icon='delete' text='Ошибка!' small={true} minimal={true} />
                </div>
            ))}
            </div>
}

const Events = ({ data, md, scores }) => {
    return  <div>
                <div style={{marginBottom: 20}}>Финальный счёт: <b>{scores.full.home}:{scores.full.away}</b></div>
                {['home', 'away'].map(s => (
                    <div style={{marginBottom: 10}}>
                        <div style={{marginBottom: 16}}><b>События: {md[s]}</b></div>
                        {['goal', 'yc', 'rc'].map(t => (
                            <div className='evt-group'>
                            {data[s][t] ? data[s][t].map(e => (
                                <div className='evt'>
                                    <div>{"'"+e.minute}</div>
                                    <div className={'icon '+t+' '+(subtypes.find(st => e[st]) || '')}></div>
                                    <div style={{height: 32}}>
                                        <span>{e.player.name}</span>
                                        <i>{e.assistant ? e.assistant.name : e.owngoal ? 'автогол' : e.penalty ? 'с пенальти' ? e.missedPenalty ? 'незабитый пенальти' : '' : '' : ''}</i>
                                        <Button intent='none' icon='delete' text='Ошибка!' small={true} minimal={true} />
                                    </div>
                                </div>
                            )) : null}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
}

const MatchItem = ({ _id }) => {
    const [item, setItem] = useState(null)
    const [mode, setMode] = useState('events')

    useEffect(() => {
        rest('get', 'matches', _id)
            .then(resp => {
                setItem(resp)
            })
    }, [])

    return  !item ? 'Загрузка...' : item.data ? ([
        <div
            className={'sheet-view'+(mode === 'events' ? ' events' : '')}
            style={{backgroundImage: `url(${spaces}${_id}_${mode === 'events' ? 'events' : 'body'}.${item.protocol.slice(-3)})`}}
        >
        </div>,
        <Tabs selectedTabId={mode} onChange={t => setMode(t)} renderActiveTabPanelOnly={true}>
            <Tab id='events' title='События' panel={(<Events scores={item.data.scores} data={item.data.events} md={item.data.matchday} />)} />
            <Tab id='home' title={'Состав '+item.data.matchday.home} panel={(<Squad data={item.data.homeSquad} />)} />
            <Tab id='away' title={'Состав '+item.data.matchday.away} panel={(<Squad data={item.data.awaySquad} />)} />
        </Tabs>
    ]) : 'Нет данных...'
}

export default MatchItem
