import { React, useState, useEffect } from 'react'
import { RadioGroup, Radio } from '@blueprintjs/core'
import Filters from './Filters'
import ListItem from './ListItem'
import ItemBody from './Item'
import DownloadProtocols from './DownloadProtocols'
import { useParams, useHistory } from 'react-router-dom'
import qs from 'qs'
import './style.css'
import moment from 'moment'

const rest = require('../Workspace/rest')

const Matches = props => {
    const [filters, setFilters] = useState({
        leagueId: null,
        date: moment().format('YY-MM-DD')
    })
    const [matches, setMatches] = useState([])
    const [references, setReferences] = useState(null)
    const [checked, setChecked] = useState([])

    const { matchId } = useParams()

    useEffect(() => {
        rest('get', 'references')
            .then(refs => {
                setReferences(refs)
            })
    }, [])

    useEffect(() => {
        const query = Object.keys(filters).reduce((acc, k) => {
            if(filters[k] && filters[k] !== 'unset') { acc.filter[k] = filters[k] }
            return acc
        }, {filter: {}})

        const queryString = decodeURIComponent(qs.stringify(query))

        rest('get', 'matches'+(queryString.length > 0 ? '?'+queryString : ''))
            .then(matches => setMatches(matches))
    }, [filters])

    const toggleCheck = (id) => {
        if (checked.includes(id)) {
            setChecked(checked.filter(check => check !== id ));
        } else {
            setChecked(checked.concat([id]));
        }
    }
    const clearChecks = () => {
        setChecked([]);
    }
    return  <div className='space'>
                <div className='side'>
                    <Filters
                        references={references}
                        setFilters={setFilters}
                        current={filters}
                        checked={checked}
                    />

                    <RadioGroup
                        selectedValue={filters.date}
                        onChange={e => setFilters({...filters, date: e.target.value})}
                    >
                        <Radio label={moment().add(3, 'days').format('DD MMMM')} value={moment().add(3, 'days').format('YY-MM-DD')} />
                        <Radio label='Послезавтра' value={moment().add(2, 'days').format('YY-MM-DD')} />
                        <Radio label='Завтра' value={moment().add(1, 'days').format('YY-MM-DD')} />
                        <Radio label='Только сегодня' value={moment().format('YY-MM-DD')} />
                        <Radio label='Вчера' value={moment().add(-1, 'days').format('YY-MM-DD')} />
                        <Radio label='Позавчера' value={moment().add(-2, 'days').format('YY-MM-DD')} />
                        <Radio label={moment().add(-3, 'days').format('DD MMMM')} value={moment().add(-3, 'days').format('YY-MM-DD')} />
                        <Radio label={moment().add(-4, 'days').format('DD MMMM')} value={moment().add(-4, 'days').format('YY-MM-DD')} />
                        <Radio label='За всё время' value='unset' />
                    </RadioGroup>

                    <DownloadProtocols
                        checked={checked}
                        matches={matches ? matches.filter(m => checked.includes(m._id)) : null}
                        clearChecks={clearChecks}
                    />
                </div>

                <div className='body'>
                    {matches.filter(m => m.homeClub && m.awayClub).map(m => (
                        <ListItem
                            key={m._id}
                            data={m}
                            checked={checked.includes(m._id)}
                            toggleCheck={toggleCheck}
                        />
                    ))}
                </div>

                <div className='item'>
                    <ItemBody
                        data={matches ? matches.find(m => m._id === matchId) : null}
                        onDeletedItem={id => {
                            setMatches(matches.filter(m => m._id !== id))
                        }}
                        onUpdatedItem={(item, isNew=false) => {
                            if(!isNew) {
                                setMatches(matches.map(m => m._id === item._id ? item : m))
                            } else {
                                const ms = Object.assign([], matches)
                                ms.unshift(item)
                                setMatches(ms)
                            }
                        }}
                    />
                </div>
            </div>
}

export default Matches
