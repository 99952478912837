import { React, useState, useEffect, useRef } from 'react';
import { Button, InputGroup, HTMLSelect, Card, Popover, Menu, MenuItem, Label, Toaster, Dialog } from '@blueprintjs/core';
import { diff } from 'deep-object-diff';

const rest = require('../../components/Workspace/rest');
const dot = require('dot-object');
const sources = ['не выбрано','OLE','lfl','joinsport','goalstream'];

const Clubs = () => {

    const [term, updateTerm] = useState('');
    const [idx, updateIdx] = useState('');
    const [filterClubs, setClubs] = useState([]);
    const [checkedClub, setCheckedClub] = useState(null);
    const [cloneClub, setCloneClub] = useState(null);
    const [remoteValue, setRemote] = useState(sources[0]);
    const [sourceId, setSourceId] = useState('');
    const [dif, updateDif] = useState(null);
    const [loading, updateLoading] = useState(false);
    const [visible, updateVisible] = useState(false);
    const toaster = useRef(null);

    useEffect(() => {
        if (term.length > 1) {
            rest('get', 'clubs'+(term.length > 0 ? `?query[name]=${term}` : ''))
                .then(clubs => setClubs(clubs.filter(item => item.name.toLowerCase().includes(term.toLowerCase()))));
        } else {setClubs([])}
    }, [term])

    useEffect(() => {
        updateDif(diff(cloneClub, checkedClub));
    },[checkedClub, cloneClub]);

    const addClub = () => {
        setCheckedClub({});
    }
    const showDialog = () => {
        updateVisible(true);
    }
    const delClub = (id) => {
        updateLoading(true);
        updateVisible(false);
        rest('delete', 'clubs', id, null)
            .then(del => {
                setCheckedClub(null);
                setCloneClub(null);
                updateIdx('');
                setSourceId('');
                toaster.current.show({intent: 'success', message: `${id} Клуб удален! спасибо`});
            })
            .catch(err => {
                console.log(err);
                toaster.current.show({intent: 'danger', message: `при удалении клуба ${id} возникла ошибка! спасибо`});
            })
            .finally(updateLoading(false));
        resetForm();
    }
    const saveClub = (id, body) => {
        updateLoading(true);
        if (body.name && (!body.emblem || body.emblem.slice(0,5) === 'https')) {
            if ((body.remote && body.remote.source === sources[0]) || (!body.remote)) {
                body.remote = {};
            }
            if (!id) {
                rest('post', 'clubs', null, body)
                    .then(created => {
                        setCheckedClub(created);
                        setCloneClub(created);
                        setRemote(created.remote ? (created.remote.source ?? sources[0]) : sources[0]);
                        setSourceId(created.remote ? (created.remote.id ?? '') : '');
                        updateIdx(created._id);
                        toaster.current.show({intent: 'success', message: 'Клуб создан! спасибо'});
                    })
                    .catch(err => {
                        console.log(err);
                        toaster.current.show({intent: 'danger', message: 'Клуб не создан!'});
                    })
                    .finally(updateLoading(false));
            }
            else if (id && Object.keys(dif).length) {
                if (dif.remote && dif.remote.source === sources[0]) {
                    dif.remote = {};
                }
                rest('put', 'clubs', id, dot.dot(dif))
                .then(upd => {
                    setCheckedClub(upd);
                    setCloneClub(upd);
                    setRemote(upd.remote ? (upd.remote.source ?? sources[0]) : sources[0]);
                    setSourceId(upd.remote ? (upd.remote.id ?? '') : '');
                    toaster.current.show({intent: 'primary', message: 'Клуб обновлен! спасибо'});
                })
                .catch(err => {console.log(err); toaster.current.show({intent: 'danger', message: `при обновлении клуба ${id} возникла ошибка! спасибо`})})
                .finally(updateLoading(false));
            }
        }
        else {
            toaster.current.show({intent: 'danger', message: 'название или путь неверные'});
            updateLoading(false);
        }
    }
    const handleField = (evt) => {
        switch (evt.target.name) {
            case 'city':
                setCheckedClub({
                    ...checkedClub,
                    from: {
                        ...checkedClub.from,
                        city: evt.target.value
                    }
                });
                break;
            case 'source':
                setCheckedClub({
                    ...checkedClub,
                    remote: {
                        ...checkedClub.remote,
                        source: evt.target.value
                    }
                });
                setRemote(evt.target.value);
                break;
            case 'sourceID':
                setCheckedClub({
                    ...checkedClub,
                    remote: {
                        ...checkedClub.remote,
                        id: evt.target.value
                    }
                });
                setSourceId(evt.target.value)
                break;
            default:(setCheckedClub({
                ...checkedClub,
                [evt.target.name]: evt.target.value
            }));
        }
    }
    const handleFilterChange = (evt) => {
        updateTerm(evt.target.value)
    }
    const takeFromBaseClub = (club) => {
        setCheckedClub(club);
        setCloneClub(club);
        updateTerm('');
        updateIdx(club._id);
        setRemote(club.remote ? (club.remote.source ?? sources[0]) : sources[0]);
        setSourceId(club.remote ? (club.remote.id ?? '') : '');
    }
    const resetForm = () => {
        updateTerm('');
        updateIdx('');
        setCheckedClub(null);
        setCloneClub(null);
        updateDif(null);
        setRemote(sources[0]);
        setSourceId('');
        updateLoading(false);
    }

    return (
        <div  style={{width: 340, padding: 0, margin:0, boxSizing:'border-box'}}>
            <div style={{marginBottom: 5, alignItems: 'center', justifyContent:'space-between', display: checkedClub ? 'none' : 'flex'}}>
                <div>Клубы </div>
                <Button text='Добавить новый клуб' icon='add' disabled={false} onClick={() => addClub()} intent='success' />
            </div>
            <Card className='card' style={{padding: 5, display: checkedClub ? 'none' : 'block' }}>
                <Popover
                    isOpen={filterClubs.length > 0}
                    position='bottom'
                    style={{width:'100%'}}
                    targetTagName={'div'}
                    content={(
                        <Menu style={{maxHeight:300, overflowY:'scroll'}}>
                        {filterClubs.map(club =>
                                <MenuItem
                                    key={club._id}
                                    text={club.name}
                                    label={club.from ? club.from.city : null}
                                    icon={(<img alt='' src={club.emblem || require('../../assets/color/badge.svg').default} style={{width: 22, height: 22}} />)}
                                    onClick={() => takeFromBaseClub(club)}
                                />
                        )}
                        </Menu>
                    )}
                >
                <InputGroup
                    style={{width:'100%'}}
                    leftIcon='search'
                    onChange={handleFilterChange}
                    placeholder="Введите название клуба"
                    value={term}
                    name='search'
                    intent='primary'
                    small={false}
                    fill={true}
                />
                </Popover>
            </Card>
            {checkedClub ? (
            <div style={{width: '100%', padding: 0, display: 'flex', alignItems: 'center', justifyContent:'space-between', flexDirection: 'column'}}>
                <Label style={{width:'100%'}}>Название
                <InputGroup
                    style={{width:'100%'}}
                    onChange={handleField}
                    placeholder="Введите название"
                    value={checkedClub.name}
                    name='name'
                    intent='primary'
                    small={false}
                    fill={true}
                    autoComplete='off'
                /></Label>
                <Label style={{width:'100%'}}>Город
                <InputGroup
                    style={{width:'100%'}}
                    onChange={handleField}
                    placeholder="Введите город"
                    value={checkedClub.from ? checkedClub.from.city : ''}
                    name='city'
                    intent='primary'
                    small={false}
                    fill={true}
                    autoComplete='off'
                /></Label>
                <Label style={{width:'100%'}}>Путь к эмблеме
                <InputGroup
                    style={{width:'100%'}}
                    onChange={handleField}
                    placeholder="введите путь к эмблеме"
                    value={checkedClub.emblem ? checkedClub.emblem : ''}
                    name='emblem'
                    intent='primary'
                    small={false}
                    fill={true}
                    autoComplete='off'
                /></Label>
                <div style={{width: '100%', marginBottom: 5, marginTop: 5, display: 'flex', justifyContent:'space-between'}}>
                    <Label style={{width: '45%', marginRight: 10, flexGrow: 0}}>Источник
                    <HTMLSelect
                        options={sources}
                        value={remoteValue}
                        onChange={handleField}
                        name='source'
                        fill={true}
                    />
                    </Label>
                    <Label style={{visibility: remoteValue === sources[0] ? 'hidden' : 'visible', width: '45%', marginRight:0, flexGrow: 1}}>ID клуба
                        <InputGroup
                            onChange={handleField}
                            placeholder="введите ID клуба"
                            value={sourceId}
                            name='sourceID'
                            intent='primary'
                            small={false}
                            fill={true}
                            autoComplete='off'
                        />
                    </Label>
                </div>
                <div style={{width: '100%', display: 'flex', marginBottom: 5, marginTop: 5,  alignItems: 'center', justifyContent:'space-between', flexDirection:'column'}}>
                    <Button text={checkedClub._id ? 'Сохранить клуб' : 'Добавить клуб'} icon='send-to' disabled={!Object.values(dif).length || loading}
                    onClick={() => saveClub(idx, checkedClub)} intent='success' style={{width:'50%', marginBottom: 10}} loading={loading}/>
                    <Button text='к поиску клуба' icon='refresh' disabled={loading} onClick={() => resetForm()} intent='primary' style={{width:'50%', marginBottom: 10}}/>
                    <Button text='удалить клуб' icon='trash' disabled={idx ? false : true} onClick={showDialog} intent='danger' style={{width:'50%', marginBottom: 10, visibility: idx ? 'visible' : 'hidden'}} loading={loading}/>
                </div>
                <Dialog
                    icon='Remove'
                    isOpen={visible}
                    title="Удалить этот клуб?"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    onClose={() => {
                        updateVisible(!visible);
                        updateLoading(false);
                    }}
                >
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-around'}}>
                        <Button
                            text='Отменить'
                            intent='primary'
                            onClick={() => {
                                updateVisible(!visible);
                                updateLoading(false);
                            }}
                            style={{width:'45%'}}
                        />
                        <Button
                            text='Удалить'
                            intent='danger'
                            onClick={() => delClub(idx)}
                            style={{width:'45%'}}
                        />
                    </div>
                </Dialog>
            </div>
            ) : null}
            <Toaster position='TOP_RIGHT' ref={toaster}></Toaster>
        </div>
    )
}

export default Clubs
