import { React } from 'react'
import { Card, Button, Popover, Menu, MenuItem } from '@blueprintjs/core'

const source = [
    {
        type: 'dropdown',
        key: 'leagueId',
        label: 'Организатор',
        renderOptions: (refs, setter, current) => {
            return refs.map(league => (
                <MenuItem text={league.name} key={league._id} onClick={() => setter({...current, leagueId: league._id, divisionId: null})} />
            ))
        },
        btnFormatter: (refs, current) => {
            const l = current.leagueId ? refs.find(l => l._id === current.leagueId) : null
            return l ? l.name : 'Не выбран организатор'
        }
    },
    {
        type: 'dropdown',
        key: 'divisionId',
        label: 'Дивизион',
        renderOptions: (refs, setter, current) => {
            const league = refs.find(l => l._id === current.leagueId)
            return league ? league.divisions.map(d => (<MenuItem text={d.name} key={d._id} onClick={() => setter({...current, divisionId: d._id})} />)) : []
        },
        btnFormatter: (refs, current) => {
            const l = current.leagueId ? refs.find(l => l._id === current.leagueId) : null
            const d = l ? l.divisions.find(d => d._id === current.divisionId) : null
            return d ? d.name : 'Не выбран дивизион'
        }
    }
]

const Filters = ({ references, setFilters, current }) => {
    return references ? source.map(item => {
        switch(item.type) {
            case 'dropdown':
                return <div className='filter-box' key={item.key}>
                            <label>{item.label}</label>
                            <Card elevation={1}>
                                <Popover
                                    position='bottom'
                                    content={(
                                        <Menu>{item.renderOptions(references, setFilters, current)}</Menu>
                                    )}
                                >
                                    <Button icon='chevron-down' fill={true} text={item.btnFormatter(references, current)} />
                                </Popover>
                            </Card>
                        </div>
                break
        }
    }) : []
}

export default Filters
