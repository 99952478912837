import { React, useState, useEffect } from 'react'
import { Card, Button, NonIdealState, InputGroup, Switch, Tag } from '@blueprintjs/core'

export const LeagueSelect = ({ data, setLeague }) => {
    return  <div className='screen'>
                {data.map(l => (
                    <div className='panel-icon-wrap'>
                        <Card className='panel-icon' elevation={2} onClick={() => setLeague(l._id)}>
                            <img src={l.emblem} />
                        </Card>
                        <label>{l.name}</label>
                    </div>
                ))}
            </div>
}

export const ModeSelect = ({ data, setMode }) => {
    const menu = [
        {label: 'Матчи', icon: 'score', value: 'matches'},
        {label: 'Персонал', icon: 'referee', value: 'staff'},
        {label: 'Клубы', icon: 'pennant', value: 'clubs'},
        {label: 'Протоколы', icon: 'printer', value: 'printable'},
        {label: 'Графика', icon: 'badge', value: 'graphics'}
    ]

    return  <div className='screen'>
                {menu.map(m => (
                    <div className='panel-icon-wrap'>
                        <Card className='panel-icon' elevation={2} onClick={() => setMode(m.value)}>
                            <img src={require('../../../assets/color/'+m.icon+'.svg').default} />
                        </Card>
                        <label>{m.label}</label>
                    </div>
                ))}
            </div>
}
