import React, { useState, useEffect, useContext } from 'react'
import { NonIdealState, Spinner, Icon, Button } from '@blueprintjs/core'
import ModerContext from './moderContext'
import Cropper from './Cropper'
import SquadLines from './SquadLines';
import axios from 'axios'

const rest = require('../../Workspace/rest')

const getAllNums = lines => {
        const nums = [];
        for (let key in lines) {
            for (let k =0; k<lines[key].length; k++) {
                if (lines[key][k]['num'] && !lines[key][k]['absent']) {
                    nums.push({num: lines[key][k]['num'], remote: lines[key][k]['remote']})
                }
            }
        }
        console.log('getAllNums', nums)
        return nums.sort((a,b) => a.num > b.num ? 1 : -1);
}

const Squad = React.memo(({ path, matchId, incStep, meta, toaster, extendedStats, updValid, schemaQty }) => {
    const ctx = useContext(ModerContext)
    const content = ctx[path+'Response']
    const [landscape, setLandscape] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [squadNums, setNums] = useState([]);

    useEffect(() => {
        if(ctx[path+'Source'] && !ctx.imFromSources.includes(path)) {
            rest('post', 'ocr', `${matchId}/${path}`, {data: ctx[path+'Source']})
                .then(resp => {
                    if(!resp.error) {
                        ctx.setCtx(path+'Response', resp)
                        setNums(getAllNums(resp))
                    } else {
                        alert(resp.error)
                    }
                })
        }
    }, [ctx && ctx[path+'Source']])

    useEffect(() => {
        if (path.includes('Squad') && content) {
            setNums(getAllNums(content))
        }
    }, [path, content])

    const patchCtxSquad = (k, v, pid, forceInclude=false) => {
        ctx.setCtx(path+'Response', ctx[path+'Response'].map(col => col.map(i => (i._id !== pid ? i : {
            ...i,
            [k]: v,
            absent: k === 'absent' ? v : forceInclude ? false : i.absent
        }))))
    }

    const offUnNumbered = () => {
        ctx.setCtx(path+'Response', ctx[path+'Response'].map(col => col.map(i => {
            return (!i.absent && (!i.num || isNaN(parseInt(i.num)))) ? {...i, absent: true } : i
        })))
    }

    const detectDouble = (n, pid) => {
        if(n && !isNaN(parseInt(n)) && pid) {
            let double
            for(let c of content) { if(c.find(i => i._id !== pid && i.num && parseInt(i.num) === parseInt(n))) { double = true }}
            return double
        } else {
            return false
        }
    }

    const unNumbered = content ? content.reduce((acc, col) => {
        acc += col.filter(i => (!i.num || isNaN(parseInt(i.num))) && !i.absent).length
        return acc
    }, 0) : 0

    const updateRoster = async () => {
        setUpdating(true)
        const isEngineMatch = !(window.location.href.includes(matchId))
        if(isEngineMatch) {
            const info = await axios.get(`https://engine.amateum.com/client/matchinfo/${matchId}`)
            setUpdating(false)
            const added = info.data[path.replace('Squad', '')].squad.filter(row => !ctx[path+'Response'].some(col => col.some(p => p._id && p._id === row._id)))
            if(added.length) {
                ctx.setCtx(path+'Response', ctx[path+'Response'].map((col, i) => (i === 0 ? added.concat(col) : col)))
            }
            toaster.current.show({intent: added.length ? 'success' : 'warning', message: added.length ? 'В начало списка добавлено '+added.length+' игроков' : 'Не найдено новых заявленных игроков'})
        } else {
            const remoteClubId = meta.season.remote.source === 'goalstream' ? meta[path.includes('home') ? 'home' : 'away'].remote.url.replace('/club/', '') : meta[path.includes('home') ? 'home' : 'away'].remote.id
            rest('put', 'parsers', `${meta.season.remote.source}/club?seasonId=${meta.season._id}&remoteClubId=${remoteClubId}&remoteSeasonId=${meta.season.remote.id}`)
                .then(resp => {
                    setUpdating(false)
                    if(resp.roster) {
                        const added = resp.roster.filter(pl => pl.remote && !ctx[path+'Response'].some(col => col.some(p => p.remote && p.remote.id === pl.remote.id)))
                        if(added.length) {
                            ctx.setCtx(path+'Response', ctx[path+'Response'].map((col, i) => (i === 0 ? added.concat(col) : col)))
                        }
                        toaster.current.show({intent: added.length ? 'success' : 'warning', message: added.length ? 'В начало списка добавлено '+added.length+' игроков' : 'Не найдено новых заявленных игроков'})
                    } else {
                        toaster.current.show({intent: 'danger', message: 'Не удалось получить ростер из внешнего источника'})
                    }
                })
        }
    }

    return  <div key={path+'_'+matchId+'_root'}>
                {!ctx[path+'Source'] ? (
                    <Cropper path={path} matchId={matchId} landscape={landscape} setLandscape={setLandscape} />
                ) : (
                    <div className='step-content'>
                        <img src={ctx[path+'Source']} style={{maxWidth: 800}} key={path+'_'+matchId+'_decoded'} />
                        {!content ? (
                            <NonIdealState
                                icon={<Spinner intent='primary' />}
                                description='Распознаю... Не переключай вкладку!'
                            />
                        ) : (
                            <div className='step-squad'>
                                <div className='step-squad_actions'>
                                    <div>
                                        <b style={{marginRight: 5}}>MVP:</b>
                                        <input
                                            type='number'
                                            min={1}
                                            max={99}
                                            value={ctx.mvp[path.replace('Squad', '')] || ''}
                                            onChange={e => ctx.setCtx('mvp', {...ctx.mvp, [path.replace('Squad', '')]: e.target.value})}
                                            onFocus={e => e.target.select()}
                                        />
                                    </div>
                                    <Button
                                        icon='refresh'
                                        large={true}
                                        text='Обн. ростер'
                                        minimal={true}
                                        onClick={() => updateRoster()}
                                        //disabled={!meta || !meta.season || !meta.season.remote}
                                        loading={updating}
                                    />
                                    <Button
                                        icon='cut'
                                        large={true}
                                        text={'Искл. игроков без номеров'+(unNumbered > 0 ? ' ('+unNumbered+')' : '')}
                                        minimal={true}
                                        disabled={unNumbered === 0}
                                        onClick={() => offUnNumbered()}
                                    />

                                    <Button
                                        icon='endorsed'
                                        large={true}
                                        intent='success'
                                        text='Следующий шаг'
                                        minimal={true}
                                        onClick={() => {
                                            offUnNumbered()
                                            setTimeout(() => {
                                                incStep()
                                            }, 500)
                                        }}
                                    />
                                </div>

                                {content.map((col, i) => (
                                    <div key={'step-squad_col'+i} className='step-squad_col'>
                                        {col.map((row, idx) => {
                                            const isDoubled = detectDouble(row.num, row._id)
                                            return  <div className={'step-squad_item'+(row.absent ? ' absent' : '')} key={row._id}>
                                                        <input
                                                            className={isDoubled ? 'danger' : (row.num && parseInt(row.num)) > 99 ? 'warning' : ''}
                                                            value={row.num}
                                                            min={1}
                                                            max={99}
                                                            type='number'
                                                            onChange={e => patchCtxSquad('num', e.target.value, row._id, (!row.num || (row.num && isNaN(row.num))) && row.absent)}
                                                            onFocus={e => e.target.select()}
                                                        />
                                                        <div>
                                                            {row.name}
                                                            <span
                                                                className={'toggler '+(row.absent ? 'on' : 'off')}
                                                                onClick={() => patchCtxSquad('absent', !row.absent, row._id)}
                                                            >
                                                                <Icon icon={row.absent ? 'confirm' : 'cross'} />
                                                                {row.absent ? 'Включить' : 'Исключить'}
                                                            </span>
                                                        </div>
                                                    </div>
                                        })}
                                    </div>
                                ))}
                                {extendedStats ? <SquadLines schemaQty={schemaQty} squad={squadNums} path={path} toaster={toaster}/> : null}
                            </div>
                        )}
                    </div>
                )}
            </div>
})

export default Squad
