import React, { useState, useEffect } from 'react'
import { Menu, MenuItem, Button, Popover } from '@blueprintjs/core'
import { useParams } from 'react-router-dom'

const rest = require('../Workspace/rest')

const MatchCloneMenu = ({ clonedSeason, value, onValueSelected }) => {
    const [options, setOptions] = useState([])
    const { matchId } = useParams()

    useEffect(() => {
        rest('get', `cloneoptions?clonedSeason=${clonedSeason}`)
            .then(resp => {
                setOptions(resp)
            })
    }, [matchId])

    return  <div style={{marginBottom: 6}}>
                <Popover
                    usePortal={false}
                    content={(
                        <Menu>
                            {options.map(opt => (
                                <MenuItem
                                    text={opt.date+' '+opt.homeClub.name+' vs '+opt.awayClub.name}
                                    onClick={() => onValueSelected(opt._id)}
                                />
                            ))}
                        </Menu>
                    )}
                >
                    <Button text={!value ? 'Источник клонирования' : 'Клон от '+value} />
                </Popover>
            </div>
}

export default MatchCloneMenu
