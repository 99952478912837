import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Search, ConversationList, Conversation, AvatarGroup, Avatar } from '@chatscope/chat-ui-kit-react'

const List = ({ list }) => {
    const history = useHistory()

    return  [
        <Search />,
        <ConversationList>
            {list.map(p => {
                const person = p.person || p

                return  <Conversation
                            onClick={() => {
                                if(p._id) {
                                    history.push('/chats/'+p._id)
                                }
                            }}
                            name={person.first_name+' '+person.last_name}
                            lastSenderName={p.leagues.concat(p.clubs).map(e => e.name).join(', ')}
                            info={p.invite || p.data[p.data.length-1].content}
                        >
                            <Avatar src={person.avatar} status={p.invite ? 'unavailable' : person.username ? 'dnd' : null} />
                        </Conversation>
            })}
        </ConversationList>
    ]
}

export default List
