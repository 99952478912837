import { React, useState, useEffect } from 'react'
import { Spinner, Card, Icon, Popover, Button, Tag } from '@blueprintjs/core'
import Form from '../Forms'

const rest = require('../rest')

const SeasonSquad = ({ seasonId, clubId }) => {
    const [squad, setSquad] = useState(null)
    const [formType, setFormType] = useState(null)
    const [formData, setFormData] = useState(null)

    useEffect(() => {
        const fetchSquad = () => {
            rest('get', `squads/item?filter[seasonId]=${seasonId}&filter[clubId]=${clubId}`)
                .then(data => {
                    console.log(data)
                    if(data._id) {
                        setSquad({...data, players: data.players.filter(p => p.item).map(p => ({...p, item: {...p.item, unprintable: p.unprintable || false}}))})
                    } else {
                        rest('post', 'squads', null, {seasonId: seasonId, clubId: clubId, players: []})
                            .then(created => {
                                if(created._id) {
                                    setSquad(created)
                                }
                            })
                    }
                })
            }

        fetchSquad()
    }, [])

    const createPlayer = () => {
        rest('post', 'players', null, formData)
            .then(player => {
                if(player._id) {
                    const _squad = [{num: formData.num, _id: player._id, item: player}].concat(squad.players)
                    rest('put', 'squads', squad._id, {players: _squad.map(({player, ...rest}) => rest)})
                    setSquad({...squad, players: _squad})
                    setFormData(null)
                    setFormType(null)
                }
            })
    }

    return  <div style={{padding: 12, paddingTop: 0}}>
                {squad ? (
                    <div className='panels-list'>
                        <div className='panels-list_title'>Заявка команды:</div>
                            {squad.players.map(s => (
                                <Popover
                                    usePortal={false}
                                    content={(
                                        <Form
                                            collection='players'
                                            throwForm={data => setFormData(data)}
                                            initial={s.item}
                                            internalAction={(target) => {
                                                rest('put', 'players', target, {...formData, squadId: squad._id})
                                                    .then(upd => {
                                                        if(upd._id) {
                                                            setSquad({
                                                                ...squad,
                                                                players: squad.players.map(p => {
                                                                    return p.item._id === upd._id ? {...p, item: upd} : p
                                                                })
                                                            })
                                                        }
                                                    })
                                            }}
                                        />
                                    )}
                                >
                                    <Card interactive={true} elevation={2}>
                                        {!s.item.remote || !s.item.remote.id ? <Tag minimal={true} intent='danger'>empty remoteId</Tag> : null}
                                        <Tag minimal={true} intent='primary'>#{s.num}</Tag>
                                        <div style={{width: '100%'}}>{s.item.name}</div>
                                    </Card>
                                </Popover>
                            )).concat([
                                <Popover
                                    usePortal={false}
                                    content={(
                                        <div className='popover-body'>
                                            <Button fill={true} text='Новый игрок' onClick={() => setFormType('new')} />
                                            <Button style={{marginTop: 6}} fill={true} text='Привязать существующего' />
                                        </div>
                                    )}
                                >
                                    <Card interactive={true} elevation={2}><Icon icon='add' /></Card>
                                </Popover>
                            ])}
                            {formType ? (
                                <div style={{width: '100%', paddingTop: 8, marginTop: 8, background: '#f8f8f8'}}>
                                    <Form
                                        collection='squadplayers'
                                        throwForm={data => setFormData(data)}
                                    />
                                    <div className='overlay-form_actions'>
                                        <Button text='Добавить игрока' intent='success' onClick={() => createPlayer()} />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                ) : <Spinner />}
            </div>
}

export default SeasonSquad
