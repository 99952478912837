import { React, useState, useEffect } from 'react'
import { ControlGroup, InputGroup, NumericInput, RadioGroup, Radio, Checkbox, Button } from '@blueprintjs/core'

const source = require('./source')

const renderControl = ({ type, label, key, options }, value, handler) => {
    switch(type) {
        case 'text':
        case 'url':
            return <InputGroup name={key} placeholder={label} large={true} value={value} onChange={({ target }) => handler(key, target.value)} />
            break
        case 'number':
            return <NumericInput name={key} large={true} min={1} value={value} onValueChange={v => handler(key, v)} placeholder={label} />
            break
        case 'checkbox':
            return <Checkbox label={label} checked={value} onChange={e => handler(key, e.target.checked)} />
            break
        case 'radio':
            return  <RadioGroup
                        label={label}
                        selectedValue={value}
                        onChange={e => handler(key, e.target.value)}
                    >
                        {options.map(o => (
                            <Radio label={o.label} value={o.value} />
                        ))}
                    </RadioGroup>
            break
    }
}

const renderInitialState = (col, initial) => {
    if(!source[col]) {
        return {}
    } else {
        const rendered = source[col].reduce((acc, g) => {
            for(let el of g) {
                if(el.key.includes('.')) {
                     const skey = el.key.split('.')
                     acc[el.key] = initial ? initial[skey[0]][skey[1]] || '' : ''
                } else {
                    acc[el.key] = initial ? initial[el.key] || (el.type === 'checkbox' ? false : '')  : (el.type === 'checkbox' ? false : '')
                }
            }
            return acc
        }, {})

        return rendered
    }
}

const Form = ({ collection, throwForm, initial, internalAction }) => {
    const [formData, setFormData] = useState(renderInitialState(collection, initial))

    const updateHandler = (k, v) => {
        setFormData({
            ...formData,
            [k]: v
        })
    }

    useEffect(() => {
        setFormData(renderInitialState(collection, initial))
    }, [initial])

    useEffect(() => {
        throwForm(formData)
    }, [formData])

    return  <div className='form'>
                {source[collection] ? source[collection].map(g => (
                    <ControlGroup fill={true}>{g.map(el => renderControl(el, formData[el.key], updateHandler))}</ControlGroup>
                )).concat(internalAction ? [
                    <Button
                        fill={true}
                        text='Сохранить'
                        onClick={() => internalAction(initial._id)}
                    />
                ] : []) : 'Undefined source group'}
            </div>
}

export default Form
