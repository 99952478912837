import { React, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Suggest } from '@blueprintjs/select'

const rest = require('../rest')

const AssignScopes = ({ addScopedUser }) => {
    const [list, setList] = useState(null)
    const { itemId } = useParams()

    useEffect(() => {
        const fetchList = async () => {
            const list = await rest('get', 'users?scopes['+itemId+']=false')
            setList(list)
        }

        fetchList()
    }, [])

    const setInitialScopes = user => {
        rest('put', 'users', user, {['scopes.'+itemId]: 'initial'})
            .then(upd => {
                if(upd._id) {
                    addScopedUser(upd)
                }
            })
    }

    return  <div className='dialog'>
                <div className='dialog-body'>
                    {list ? <Suggest
                        fill={true}
                        items={list}
                        itemRenderer={i => {
                            return <div onClick={() => setInitialScopes(i._id)} className='dialog-popover_item'>{i.first_name+' '+i.last_name}</div>
                        }}
                        popoverProps={{minimal: true}}
                    /> : 'Загрузка...'}
                </div>
            </div>
}

export default AssignScopes
