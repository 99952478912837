import { React, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Card, Tag, Icon, Checkbox } from '@blueprintjs/core'
import './listItem.css'
const moment = require('moment')
moment.locale('ru')

const { extractMatchStatus } = require('./utils')

const ListItem = ({ data, checked, toggleCheck }) => {
    const history = useHistory()
    const transformedDate = dateTransform(data.date)
    const status = extractMatchStatus(data)

    const SeasonGroup = () => {
        if (data.seasonGroups.length) {
            let name;
            data.seasonGroups.forEach((item, i) => {
                if (item.clubs.includes(data.homeClub._id)) {
                    name = item.name;
                }
            });
            return (
                <Tag intent='info'>
                    {name}
                </Tag>
            )
        }
        return null;
    }

    return <Card elevation={1} interactive={true} className='list-item' onClick={() => history.push('/matches/'+data._id)}>
        <div style={{width: 100, marginRight: 5, display: 'flex', alignItems: 'center'}}>
            <span onClick={(evt) => evt.stopPropagation()}>
                <Checkbox
                    checked={checked}
                    onChange={(evt) => toggleCheck(data._id)}
                />
            </span>
            <div style={{width: 74}}>
                <div className='match-time'>{data.time}</div>
                <div><Tag minimal={true} intent={transformedDate.intent}>{transformedDate.text}</Tag></div>
            </div>
        </div>
        <div style={{width: 370, display: 'flex', alignItems: 'center'}}>
            <div style={{textAlign: 'right', width: 160, paddingLeft: 15, boxSizing: 'border-box'}}>{data.homeClub.name}</div>
            <div style={{width: 80, textAlign: 'center'}}>{data.data && data.data.scores && data.data.scores.full ? `${data.data.scores.full.home}:${data.data.scores.full.away}` : <Icon icon='cross' />}</div>
            <div style={{width: 160, paddingRight: 15, boxSizing: 'border-box'}}>{data.awayClub.name}</div>
        </div>
        <div>
            <div style={{position: 'absolute', right: 10, bottom: 10, width: 220, textAlign: 'right', display: 'flex', alignItems: 'center', flexDirection:'column'}}>
                <Tag minimal={true} intent={status.intent}>{status.text}</Tag>
                <div style={{marginTop: 3}}>
                    {data.division ? <Tag intent='primary' style={{marginRight: 3}}>{data.division.name+' '+data.season.name}</Tag> : null}
                    {data.seasonGroups ? <SeasonGroup/> : null}
                </div>
            </div>
        </div>
        {data.dryRun ? <Tag intent='danger' style={{position: 'absolute', top: 10, right: 10}}>DRY RUN</Tag> : null}
    </Card>
}

const dateTransform = input => {
    const t = moment().startOf('day')
    const mt = moment(input, 'YY-MM-DD')
    const diff = t.diff(mt, 'days')
    switch(true) {
        case (diff === 0):
            return {text: 'сегодня', intent: 'success'}
            break
        case (diff === -1):
            return {text: 'завтра', intent: 'success'}
            break
        case (diff === -2):
            return {text: 'послезавтра', intent: 'success'}
            break
        case (diff < -2):
            return {text: mt.format('DD MMM YY'), intent: 'primary'}
            break
        case (diff === 1):
            return {text: 'вчера', intent: 'warning'}
            break
        case (diff === 1):
            return {text: 'позавчера', intent: 'warning'}
            break
        default:
            return {text: mt.format('DD MMM YY'), intent: 'none'}
    }
}

export default ListItem
